import { ConfigProvider } from 'antd';
import { enableMapSet } from 'immer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr';
import UserContext from './admin/views/UserContext';
import { swrConfig } from './lib/swr';
import { themeConfig } from './lib/theme';
import { MainRouter } from './router/main.router';

// makes the use of Map and Set in immer.js possible
enableMapSet();

export default function App() {
  return (
    <ConfigProvider theme={themeConfig}>
      <ToastContainer />
      <SWRConfig value={swrConfig}>
        <UserContext>
          <DndProvider backend={HTML5Backend}>
            <MainRouter />
          </DndProvider>
        </UserContext>
      </SWRConfig>
    </ConfigProvider>
  );
}
