import { StripeProducts } from '@racemap/sdk/schema/billing';
import Stripe from 'stripe';
import { getProductPriceFactory } from './getProductPriceFactory';

export const getTotalPrice = (
  productCounts: Partial<Record<StripeProducts, number>>,
  priceList: Record<StripeProducts, Stripe.Price> | undefined,
): number => {
  const getProductPrice = getProductPriceFactory(priceList);
  if (getProductPrice == null) return 0;

  return Object.entries(productCounts).reduce(
    (total, [product, quantity]) =>
      total + getProductPrice(product as StripeProducts, quantity || 0),
    0,
  );
};
