import { ObjectId, ObjectIdSchema } from '@racemap/sdk/schema/base';
import {
  FUNCTION_KEY_MODE,
  LastGeo,
  TRACKER_MESSAGE_HISTORY_RECORD_STATE,
  TRACKER_MESSAGE_TRANSPORT,
  TRACKER_TYPES,
  TrackerMessage,
  TrackerMessageHistoryRecordSchema,
  TrackerState,
} from '@racemap/sdk/schema/trackers';
import { Document } from 'mongoose';
import { z } from 'zod';
import { LogisticsState, TrackerActivationType, USER_ROLE } from '../consts/trackers';
import { UserDocument } from './users';
import { ID } from './utils';

export type Selection = Set<string>;

export type TrackerSetting = {
  reportMode?: string;
  moveCheckInterval?: number;
  moveSendInterval?: number;
  nmdReportMode?: string;
  enterMovementByCmd?: boolean;
  nonMoveBegin?: number;
  nonMoveCheckInterval?: number;
  nonMoveSendInterval?: number;
  estimatedTrackInterval?: number;
  gpsMode?: string;
  corner?: number;
  reportBeginTime?: string;
  reportEndTime?: string;
  reportDistance?: number;
  reportMileage?: number;
  serverAckEnabled?: boolean | null;
  functionKeyMode?: FUNCTION_KEY_MODE;
};

export interface TrackerNetworkObject {
  name: string;
  country: string;
  countryCode: string;
  mcc: string;
  mnc: string;
  type?: 'GSM' | '4G' | '2G/3G' | '5G';
}

export interface TrackerCellObject {
  cellId: number;
  locationAreaCode: number;
  accuracy: number | null;
  lat: number | null;
  lng: number | null;
}

export interface SatelliteInfoObject {
  satelliteId: number;
  satellitePower: number;
}

export interface TrackerSatelliteObject {
  satelliteNumber: number;
  satelliteList: Array<SatelliteInfoObject>;
}

export interface BasicTracker {
  id: string;
  state: TrackerState;
  setting: TrackerSetting;
  lastGeo: Partial<LastGeo>;
  meta: TrackerMeta;
  satellites: TrackerSatelliteObject;
  rfidUid: string | null;
  network?: TrackerNetworkObject;
  cell?: TrackerCellObject;
  logistics: LogisticsState;
  messages: Array<TrackerMessageObject>;
  eventIds: Array<string>;
  trackerType: TRACKER_TYPES;
  updatedAt?: string;
  createdAt?: string;
  trackerId: string;
  trackerName: string;
  owner: string;
  editors: Array<string>;
  borrowers: Array<Borrower>;

  activation: {
    type: TrackerActivationType;
    paidUntil?: Date | null;
  };
}

export type TrackerLegacyPrototype = Partial<TrackerLegacy> & {
  trackerId: string;
  trackerName: string;
  trackerType: string;
};

import { Tracker } from '@racemap/sdk/schema/trackers';
import { HydratedDocument } from 'mongoose';
export type TrackerObject = Tracker;

export type TrackerLegacy = BasicTracker;

export interface ArchivedTracker extends TrackerObject {
  activation: {
    type: TrackerActivationType.ARCHIVED;
    paidUntil?: Date | null;
  };
}

export type DeactivatedTracker = Omit<
  TrackerObject,
  'state' | 'lastGeo' | 'setting' | 'meta' | 'rfiduid' | 'network' | 'cell'
>;

export interface TrackerDocumentMethods {
  isOwner(user?: UserDocument | null): boolean;
  isEditor(user?: UserDocument | null): boolean;
  isBorrower(user?: UserDocument | null): boolean;
  canEdit(user: UserDocument): Promise<boolean>;
  canRead(user: UserDocument): Promise<boolean>;
}

export type TrackerDocument = HydratedDocument<Tracker, TrackerDocumentMethods>;

export const TrackerMessageHistoryRecordDocumentSchema = TrackerMessageHistoryRecordSchema.extend({
  updatedAt: z.date(),
  createdAt: z.date(),
  createdByTest: z.boolean().optional(),
  _id: ObjectIdSchema,
});

export type TrackerMessageHistoryRecordDocument = z.infer<
  typeof TrackerMessageHistoryRecordDocumentSchema
> &
  Document<ObjectId>;

export interface TrackerMessageDocumentMethods {
  canEdit(user: UserDocument | null): Promise<boolean>;
  canRead(user: UserDocument | null): Promise<boolean>;
  createMessageHistoryRecord(
    user: UserDocument | null,
    statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE,
    statusMessage?: string | null,
    isTest?: boolean,
  ): Promise<TrackerMessageHistoryRecordDocument>;
}
export type TrackerMessageDocument = HydratedDocument<
  TrackerMessage,
  TrackerMessageDocumentMethods
>;

export interface TrackerWithStarterRefsDocument extends Tracker {
  starterIds: Array<ObjectId>;
  eventIds: Array<ObjectId>;
}

export enum ResponseStatusCode {
  OK = 'OK',
  ERROR = 'ERROR',
  TIMEOUT = 'TIMEOUT',
  FAILED = 'FAILED',
  DELIVERED = 'DELIVERED',
}

export type TrackerMessagePrototype = Partial<
  Omit<TrackerMessageObject, 'payload' | 'trackerId' | 'plannedAt'>
> &
  Required<{
    payload: TrackerMessageObject['payload'];
    trackerId: TrackerMessageObject['trackerId'];
    plannedAt: TrackerMessageObject['plannedAt'];
  }>;

export interface TrackerMessageSendAttemptObject {
  sentAt: string;
}

export interface TrackerMessageObject {
  id: string;
  payload?: { type: 'Buffer'; data: Array<number> };
  label: string;
  createdAt: string;
  updatedAt: string;
  maxNumOfAttempts: number;
  plannedAt: string;
  closeAfter: string;
  trackerId: string;
  creatorId: string;
  responseId: string | null;
  transport: TRACKER_MESSAGE_TRANSPORT;
  historyRecords: Array<TrackerMessageHistoryRecordObject> | null;
}

export interface TrackerMessageHistoryRecordObject {
  id: ObjectId;
  statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE;
  creatorId: ObjectId | null;
  messageId: ObjectId;
  statusMessage: string | null;
  updatedAt?: Date;
  createdAt?: Date;
}

export type Borrower = {
  id: ID;
  startTime: string;
  endTime: string;
};

export type StarterInfo = {
  name?: string;
  startNumber?: string;
};

export const TrackerUserSchema = z.object({
  role: z.nativeEnum(USER_ROLE),
  id: ObjectIdSchema,
  startTime: z.date().optional(),
  endTime: z.date().optional(),
});

export type TrackerUser = z.infer<typeof TrackerUserSchema>;

export enum READER_STATE {
  UNKNOWN = 'UNKNOWN',
  INITIATE = 'INITIATE',
  READY = 'READY',
  WAITING = 'WAITING',
  ERROR = 'ERROR',
  READING = 'READING',
}

export interface TrackerMeta {
  firmware?: {
    tag?: string;
    hash?: string;
    branch?: string;
    buildDate?: Date;
    version?: string;
  };
  hardware?: {
    version?: string;
    modem?: string;
    modemSW?: string;
  };
  simId?: string;
  iccid?: string;
}

export interface TrackerActivation {
  type: TrackerActivationType;
  paidUntil: Date;
}

export interface ReaderConnection {
  state: 'CONNECTED' | 'UNKNOWN' | 'DISCONNECTED';
  readerId: string;
  readerName?: string;
  type: 'App';
  connectedAt: Date;
}
export type ReaderConnections = Map<string, ReaderConnection>;

export enum SCAN_SESSION_MESSAGE_TYPES {
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
  ACCEPT_CONNECTION = 'ACCEPT_CONNECTION',
  ACCEPT_DISCONNECTION = 'ACCEPT_DISCONNECTION',
  READ = 'READ',
  ACCEPT_READ = 'ACCEPT_READ',
  FAIL_READ = 'FAIL_READ',
}

export interface ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES;
}
export interface ScanSessionConnectMessage extends ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES.CONNECT;
  readerId: string;
  readerName?: string;
}
export interface ScanSessionAcceptConnectionMessage extends ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES.ACCEPT_CONNECTION;
}
export interface ScanSessionDisconnectMessage extends ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES.CONNECT;
  readerId: string;
}
export interface ScanSessionAcceptDisconnectionMessage extends ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES.ACCEPT_DISCONNECTION;
}
export interface ScanSessionReadMessage extends ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES.READ;
  readerId: string;
  trackerId: string;
}
export interface ScanSessionAcceptReadMessage extends ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES.ACCEPT_READ;
  trackerId: string;
}
export interface ScanSessionFailReadMessage extends ScanSessionMessage {
  type: SCAN_SESSION_MESSAGE_TYPES.FAIL_READ;
  trackerId: string;
  err: string;
}
