import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import React, { FC } from 'react';
import { Badge as BadgeRaw } from 'react-bootstrap';

export const EnabledBagde: FC = () => {
  return <Badge>Enabled</Badge>;
};

const Badge = styled(BadgeRaw)`
  background-color: ${RacemapColors.PaleBlue};
  color: white;
`;
