import { md5 } from './md5';

/**
 * Calculates the MD5 hash of a string and returns a short hash.
 * @param str - The string to calculate the hash for.
 * @returns The short MD5 hash of the input string with a lenght of 32 characters.
 */
export function md5ShortHash(str: string): string {
  return md5(str).substring(0, 32);
}
