import { LastGeo } from '@racemap/sdk/schema/trackers';

export enum MESSAGE_TYPES {
  STATE = 0x00,
  COMMAND = 0x01,
  RECLAIM = 0x02,
  CONNECT = 0x03,
  LOCATION = 0x04,
  FIRMWARE = 0x05,
  DISCONNECT = 0x06,
}

export type Location = Omit<LastGeo, 'lastTime' | 'SOS' | 'gpsAccurancy' | 'reportType'> & {
  time: number;
  accurancy?: number;
  speed?: number;
};

export type Battery = {
  SoC: number;
  charging?: boolean;
  voltage?: number;
};

export interface LocationMessage {
  tId: Buffer;
  type: MESSAGE_TYPES.LOCATION;
  location: Location;
}

export interface CommandMessage {
  type: MESSAGE_TYPES.COMMAND;
  cmd: 'reclaim';
  cId?: Buffer;
}

export interface ReclaimMessage {
  type: MESSAGE_TYPES.RECLAIM;
  tId: Buffer;
  cId: Buffer;
}

export interface StateMessage {
  tId: Buffer;
  type: MESSAGE_TYPES.STATE;
  count?: number;
  battery?: Battery;
  location?: Location;
}

export interface FirmwareMessage {
  tId: Buffer;
  type: MESSAGE_TYPES.FIRMWARE;
  tag: string;
  hash: string;
  branch: string;
  buildDate: string;
}

export interface DisconnectMessage {
  tId: Buffer;
  type: MESSAGE_TYPES.DISCONNECT;
  will?: boolean;
}

export interface ConnectMessage {
  type: MESSAGE_TYPES.CONNECT;
  tId: Buffer;
}
