import { TRACKER_MESSAGE_HISTORY_RECORD_STATE } from '@racemap/sdk/schema/trackers';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Immutable } from 'immer';
import { ManagedTrackerMessages } from '../../../store/trackers/trackers_reducers';
import {
  IconCalendar,
  IconCalendarXMark,
  IconErase,
  IconFail,
  IconOk,
  IconRefresh,
  IconSent,
  IconTimeout,
  IconWaiting,
} from '../../Icon';
type TrackerMessages = Array<Immutable<ManagedTrackerMessages>>;

export enum TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND {
  PENDING = 'PENDING',
  PLANNED = 'PLANNED',
  CLOSE = 'CLOSE',
  TIMEOUT = 'TIMEOUT',
}

export type TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED =
  | TRACKER_MESSAGE_HISTORY_RECORD_STATE
  | TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND;

// historyRecords: LIFO -> elment 0 is the most recent
export function getCurrentMessageStatusCode(
  message: Immutable<ManagedTrackerMessages>,
): TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED {
  return (
    message.historyRecords?.[0]?.statusCode || TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PENDING
  );
}
export function getMostRecentMessageChangeDate(message: Immutable<ManagedTrackerMessages>): Date {
  return message.historyRecords?.[0]?.createdAt || message.createdAt;
}
export const hasStatusCodeMessages = (
  messages: TrackerMessages,
  statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED,
) => {
  return (
    messages.filter((message) => getCurrentMessageStatusCode(message) === statusCode).length !== 0
  );
};

export const generateMessageSteps = (message: Immutable<ManagedTrackerMessages>) => {
  const steps: { date: Date; title: string; description: string | JSX.Element }[] = [];

  if (message.historyRecords) {
    for (const record of message.historyRecords) {
      steps.push({
        date: record.createdAt,
        title: record.statusCode,
        description: record.statusMessage || '',
      });
    }
  }

  steps.push({ date: message.createdAt, title: 'CREATED', description: 'Message created' });

  if (!isMessageComplete(message)) {
    if (isMessageTimedOut(message)) {
      // show timeout only if message is not complete and is timed out
      steps.push({
        date: message.closeAfter,
        title: 'TIMEOUT',
        description: 'Message timed out',
      });
    } else {
      // show close only if message is not complete and is not timed out
      steps.push({
        date: message.closeAfter,
        title: 'CLOSE',
        description: 'Message will be closed',
      });
    }

    // show planned only when the planned date is in the future
    if (message.plannedAt > new Date()) {
      steps.push({ date: message.plannedAt, title: 'PLANNED', description: 'Message planned' });
    }
  }

  steps.sort((a, b) => {
    return b.date.valueOf() - a.date.valueOf();
  });

  return steps;
};

export const getMessageHistoryRecordInfo = (
  statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED,
) => {
  switch (statusCode) {
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.ERROR:
      return {
        icon: <IconFail style={{ color: RacemapColors.DarkRed }} fixedWidth />,
        text: 'ERROR',
        color: RacemapColors.DarkRed,
      };

    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.DELIVERED:
      return {
        icon: <IconSent style={{ color: RacemapColors.DarkYellow }} fixedWidth />,
        text: 'DELIVERED',
        color: RacemapColors.DarkYellow,
      };

    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.COMPLETE:
      return {
        icon: <IconOk style={{ color: RacemapColors.BaseGreen }} fixedWidth />,
        text: 'COMPLETE',
        color: RacemapColors.BaseGreen,
      };

    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.SENT:
      return {
        icon: <IconSent style={{ color: RacemapColors.DarkYellow }} fixedWidth />,
        text: 'SENT',
        color: RacemapColors.DarkYellow,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.REVOKED:
      return {
        icon: <IconRefresh style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        text: 'REVOKED',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.CLEARED:
      return {
        icon: <IconErase style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        text: 'CLEARED',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.TIMEOUT:
      return {
        icon: <IconTimeout style={{ color: RacemapColors.DarkRed }} fixedWidth />,
        text: 'TIMEOUT',
        color: RacemapColors.DarkRed,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PENDING:
      return {
        icon: <IconWaiting style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        text: 'PENDING',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PLANNED:
      return {
        icon: <IconCalendar style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        text: 'PLANNED',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.CLOSE:
      return {
        icon: <IconCalendarXMark style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        text: 'CLOSE',
        color: RacemapColors.DarkGray,
      };
  }
};

export const isMessageComplete = (message: Immutable<ManagedTrackerMessages>): boolean => {
  const messageStatusCode = getCurrentMessageStatusCode(message);
  return (
    messageStatusCode === 'COMPLETE' ||
    messageStatusCode === 'DELIVERED' ||
    messageStatusCode === 'ERROR' ||
    messageStatusCode === 'REVOKED' ||
    messageStatusCode === 'CLEARED'
  );
};

export const isMessageTimedOut = (message: Immutable<ManagedTrackerMessages>): boolean => {
  return message.closeAfter.valueOf() < Date.now() && !isMessageComplete(message);
};
