import parser from 'fast-xml-parser';
import fetch from '../fetch';

const jsonHeaders = { 'Content-Type': 'application/json; charset=utf-8' };

export function getJSON<R>(url: string, options?: { headers: Record<string, string> }): Promise<R> {
  return fetch(url, {
    method: 'GET',
    headers: Object.assign(jsonHeaders, options?.headers ? options.headers : null),
  }).then((res) => res.json());
}

export async function extractJsonOrXml(res: Response): Promise<unknown> {
  if (200 <= res.status && res.status < 300) {
    const contentType = res.headers.get('content-type');
    if (contentType?.includes('application/xml')) {
      const textToParse = await res.text();
      return parser.parse(textToParse);
    }
    if (contentType?.includes('application/json')) {
      return res.json();
    }
    return Promise.reject(new Error('extractJsonOrXml.Error: No known content type to process.'));
  }
  return Promise.reject(new Error(`The resource rejected the request. HTTP Status ${res.status}`));
}

export function getJsonOrXml<R>(url: string): Promise<R> {
  return fetch(url).then(async (res) => {
    return extractJsonOrXml(res) as R;
  });
}

export function patchJSON<U>(url: string, body: U): Promise<Response> {
  return fetch(url, {
    method: 'PATCH',
    headers: jsonHeaders,
    body: JSON.stringify(body),
  });
}

export function postJSON<U>(url: string, body: U, headers: object): Promise<Response> {
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...jsonHeaders,
      ...headers,
    },
    body: JSON.stringify(body),
  });
}

export async function postProtobuf(url: string, data: ArrayBuffer): Promise<Response> {
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-protobuf' },
    body: data,
  });
  if (200 <= res.status && res.status < 300) {
    return res;
  }
  return Promise.reject(
    new Error(`Non-200 Status: ${res.status} POST ${url}\n${await res.text()}`),
  );
}
