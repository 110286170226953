import { TRACKER_PARTICIPANT_PAIRING_OPTION } from '@racemap/sdk/schema/trackers';
import { Radio } from 'antd';
import { FC } from 'react';

interface Props {
  value: TRACKER_PARTICIPANT_PAIRING_OPTION;
  onChange: (value: TRACKER_PARTICIPANT_PAIRING_OPTION) => void;
}

export const PairingOptionSelector: FC<Props> = ({ value, onChange }) => {
  return (
    <Radio.Group
      value={value}
      onChange={(e: {
        target: { value: TRACKER_PARTICIPANT_PAIRING_OPTION };
      }) => onChange(e.target.value)}
    >
      <Radio.Button value={TRACKER_PARTICIPANT_PAIRING_OPTION.GENERATE_NEW}>Add new</Radio.Button>
      <Radio.Button value={TRACKER_PARTICIPANT_PAIRING_OPTION.PAIR_AND_GENERATE}>
        Pair & Add
      </Radio.Button>
      <Radio.Button value={TRACKER_PARTICIPANT_PAIRING_OPTION.PAIR_EXISTING}>
        Pair Only
      </Radio.Button>
    </Radio.Group>
  );
};
