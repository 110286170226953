import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { RelativeTime } from '../TimeDuration/RelativeTime';

interface Props {
  time: Date;
}

export const Timestamp: React.FC<Props> = ({ time }) => {
  return (
    <StyledTimestamp className="rm-timestamp">
      <RelativeTime time={time} size="short" />
    </StyledTimestamp>
  );
};

const StyledTimestamp = styled.div`
    margin-right: 6px;
    font-size: smaller;
    color: ${RacemapColors.DarkGray};
  `;
