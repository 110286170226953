import { makeApi, makeEndpoint, prefixApi } from '@zodios/core';
import { z } from 'zod';
import { ISODateStringSchema, ObjectIdHexStringSchema, ObjectIdSchema } from '../schema/base';
import {
  LastGeoSchema,
  PublicTracker,
  TrackerMessageSchema,
  TrackerMessageTemplateBasedPrototypeSchema,
  TrackerMetaSchema,
  TrackerNetworkSchema,
  TrackerOverviewSchema,
  TrackerSatellitesSchema,
  TrackerSchema,
  TrackerSettingSchema,
  TrackerStateSchema,
} from '../schema/trackers';

export const prefix = '/api/v1/trackers';

const listTrackers = makeEndpoint({
  method: 'get',
  path: '/',
  alias: 'listTrackers',
  description: 'List trackers',
  parameters: [
    {
      name: 'findByPartOfName',
      type: 'Query',
      description: 'Find by part of name',
      required: false,
      schema: z.string().optional(),
    },
    {
      name: 'show',
      type: 'Query',
      description: 'Show special trackers',
      required: false,
      schema: z.enum(['archived']).optional(),
    },
    {
      name: 'filter',
      type: 'Query',
      description: 'Filter',
      required: false,
      schema: z.array(z.enum(['can-edit'])).optional(),
    },
    {
      name: 'limit',
      type: 'Query',
      description: 'Limit',
      required: false,
      schema: z.number().optional(),
    },
    {
      name: 'offset',
      type: 'Query',
      description: 'Offset',
      required: false,
      schema: z.number().optional(),
    },
    {
      name: 'updatedSince',
      type: 'Query',
      description: 'Updated since',
      required: false,
      schema: ISODateStringSchema.optional(),
    },
    {
      name: 'tags',
      type: 'Query',
      description: 'Tags',
      required: false,
      schema: z.array(z.union([ObjectIdSchema, z.string()])).optional(),
    },
    {
      name: 'userIds',
      type: 'Query',
      description: 'User IDs',
      required: false,
      schema: z.array(z.string()).optional(),
    },
  ],
  response: z.array(TrackerOverviewSchema),
});

const getTracker = makeEndpoint({
  method: 'get',
  path: '/:trackerId',
  alias: 'getTracker',
  description: 'Get a tracker',
  response: TrackerOverviewSchema.extend({ messages: z.array(TrackerMessageSchema) }),
  parameters: [
    { name: 'trackerId', type: 'Path', description: 'Tracker ID', schema: ObjectIdHexStringSchema },
    {
      name: 'searchBy',
      type: 'Query',
      description: 'Search by',
      required: false,
      schema: z.enum(['tracker-id', 'rfid-uid']).optional(),
    },
  ],
});

const getTrackerPublicInfo = makeEndpoint({
  method: 'get',
  path: '/:trackerId/public',
  parameters: [
    { name: 'trackerId', type: 'Path', description: 'Tracker ID', schema: ObjectIdHexStringSchema },
  ],
  response: PublicTracker,
  alias: 'getTrackerPublicInfo',
  description: 'Get public information about a tracker',
  tag: 'trackers',
});

const updateTracker = makeEndpoint({
  method: 'patch',
  path: '/:trackerId',
  parameters: [
    { name: 'trackerId', type: 'Path', description: 'Tracker ID', schema: ObjectIdHexStringSchema },
    { name: 'tracker', type: 'Body', description: 'Tracker', schema: TrackerSchema.partial() },
    {
      name: 'searchBy',
      type: 'Query',
      description: 'Search by',
      schema: z.enum(['tracker-id']).optional(),
    },
  ],
  response: TrackerSchema,
  alias: 'updateTracker',
  description: 'Update a tracker',
  tag: 'trackers',
});

const updateSetting = makeEndpoint({
  method: 'patch',
  path: '/:trackerId/setting',
  parameters: [
    {
      name: 'trackerId',
      type: 'Path',
      description: 'Tracker ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'setting',
      type: 'Body',
      description: 'Tracker Setting',
      schema: TrackerSettingSchema.partial(),
    },
  ],
  response: TrackerSchema,
  alias: 'updateTrackerSetting',
  description: 'Update tracker setting',
  tag: 'trackers',
});

const updateLastGeo = makeEndpoint({
  method: 'patch',
  path: '/:trackerId/lastGeo',
  parameters: [
    {
      name: 'trackerId',
      type: 'Path',
      description: 'Tracker ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'lastGeo',
      type: 'Body',
      description: 'Tracker Last Geo',
      schema: LastGeoSchema.partial(),
    },
  ],
  response: TrackerSchema,
  alias: 'updateTrackerLastGeo',
  description: 'Update tracker last geo',
  tag: 'trackers',
});

const updateState = makeEndpoint({
  method: 'patch',
  path: '/:trackerId/state',
  parameters: [
    {
      name: 'trackerId',
      type: 'Path',
      description: 'Tracker ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'state',
      type: 'Body',
      description: 'Tracker State',
      schema: TrackerStateSchema.partial(),
    },
  ],
  response: TrackerSchema,
  alias: 'updateTrackerState',
  description: 'Update tracker state',
  tag: 'trackers',
});

const updateMeta = makeEndpoint({
  method: 'patch',
  path: '/:trackerId/meta',
  parameters: [
    {
      name: 'trackerId',
      type: 'Path',
      description: 'Tracker ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'meta',
      type: 'Body',
      description: 'Tracker Meta',
      schema: TrackerMetaSchema.partial(),
    },
  ],
  response: TrackerSchema,
  alias: 'updateTrackerMeta',
  description: 'Update tracker meta',
  tag: 'trackers',
});

const updateSatellites = makeEndpoint({
  method: 'patch',
  path: '/:trackerId/satellites',
  parameters: [
    {
      name: 'trackerId',
      type: 'Path',
      description: 'Tracker ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'satellites',
      type: 'Body',
      description: 'Tracker Satellites',
      schema: TrackerSatellitesSchema.partial(),
    },
  ],
  response: TrackerSchema,
  alias: 'updateTrackerSatellites',
  description: 'Update tracker satellites',
  tag: 'trackers',
});

const updateNetwork = makeEndpoint({
  method: 'patch',
  path: '/:trackerId/network',
  parameters: [
    {
      name: 'trackerId',
      type: 'Path',
      description: 'Tracker ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'network',
      type: 'Body',
      description: 'Tracker Network',
      schema: TrackerNetworkSchema.partial(),
    },
  ],
  response: TrackerSchema,
  alias: 'updateTrackerNetwork',
  description: 'Update tracker network',
  tag: 'trackers',
});

const addTrackerMessages = makeEndpoint({
  method: 'post',
  path: '/messages',
  parameters: [
    {
      name: 'message',
      type: 'Body',
      description: 'Tracker Message',
      schema: TrackerMessageTemplateBasedPrototypeSchema.extend({
        trackerIds: z.array(ObjectIdSchema),
      }),
    },
  ],
  response: z.array(TrackerMessageSchema),
  alias: 'addTrackerMessages',
  description: 'Add message to tracker',
  tag: 'trackers',
});

export const endpoints = makeApi([
  listTrackers,
  getTrackerPublicInfo,
  updateTracker,
  getTracker,
  updateSetting,
  updateState,
  updateLastGeo,
  updateSatellites,
  updateMeta,
  updateNetwork,
  addTrackerMessages,
]);
export const trackersApi = prefixApi(prefix, endpoints);
