import styled from '@emotion/styled';
import { Avatar, Badge, Flex, Space } from 'antd';
import { FC, useState } from 'react';
import { useCurrentUser } from '../../../lib/customHooks';
import { AnnounceKitReadGetter } from '../../AnnounceKitWrapperProps';
import { IconUser } from '../../Icon';
import { UserRoleBadges } from '../../MainHeader/UserRoleBadges';

interface Props {
  showNoBadge?: boolean;
}

export const AccountInfo: FC<Props> = ({ showNoBadge = false }) => {
  const currentUser = useCurrentUser();
  const [unread, setUnread] = useState<number>();

  if (!currentUser) return null;

  return (
    <AnnounceKitReadGetter onUnreadChange={setUnread}>
      <Container>
        <Flex vertical align="end" style={{ minWidth: 90 }}>
          <Name>{currentUser.name}</Name>
          <UserRoleBadges user={currentUser} />
        </Flex>
        <Badge count={showNoBadge ? 0 : unread} size="small" color="blue">
          <Avatar shape="square" icon={<IconUser />} />
        </Badge>
      </Container>
    </AnnounceKitReadGetter>
  );
};

const Container = styled(Space)`
    .user-role-badges {
        direction: rtl;
        justify-content: flex-start;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;
