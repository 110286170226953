// The API of Raceresult returns records as an array.
// This is a mapping of key names to array indices.
// e.g. record[RACERESULT_KEYS.Firstname] accesses the firstname of the record
export const RACERESULT_KEYS = {
  Id: 0,
  RaceNr: 1,
  Transponder1: 2,
  Transponder2: 3,
  Firstname: 4,
  Lastname: 5,
  Age: 6,
  Sex: 7,
  Club: 8,
  Nationality: 9,
  Contest: 10,
  Visibility: 11,
  Status: 12,
  Start: 13,
  Finish: 14,
  Custom: 15,
};

export const FILTERED_PLACEHOLDER = ['n/a', '-', '', '/'];
export const RACERESULT_API_HOST = 'https://api.raceresult.com';
