import styled from '@emotion/styled';
import { ObjectId } from '@racemap/sdk/schema/base';
import { TRACKER_PARTICIPANT_PAIRING_OPTION } from '@racemap/sdk/schema/trackers';
import { HTTPFetchError } from '@racemap/utilities/api-client';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { Immutable } from 'immer';
import React, { useState } from 'react';
import { Alert, AlertProps, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/reducers';
import SearchEvent from '../../SearchEvent';
import { PairingOptionSelector } from './PairingOptionSelector';
import { PairingOptionsTooltip } from './PairingOptionsTooltip';

enum PROCESS_STATE {
  INITED = 0,
  FETCHING = 1,
  FINISHED = 2,
  FAILURE = 3,
}

type Props = {
  trackerIds: Array<ObjectId>;
  event?: Immutable<RacemapEvent> | null;
  onAddToEvent?: () => void;
};

export function AddTrackerToEvent({ trackerIds, event = null, onAddToEvent = () => {} }: Props) {
  const [processState, setProcessState] = useState<PROCESS_STATE>(PROCESS_STATE.INITED);
  const [message, setMessage] = useState<React.ReactNode | null>(null);
  const [messageType, setMessageType] = useState<AlertProps['variant']>('success');
  const [eventId, setEventId] = useState<string | null>(event?.id || null);
  const { addTrackersToEvent } = useStore((s) => s.trackers.actions);
  const [pairingOption, setPairingOption] = useState<TRACKER_PARTICIPANT_PAIRING_OPTION>(
    TRACKER_PARTICIPANT_PAIRING_OPTION.PAIR_AND_GENERATE,
  );

  if (trackerIds == null) return <></>;

  const handleAddTrackerToEvent = async () => {
    if (eventId != null && trackerIds.length > 0) {
      try {
        setProcessState(PROCESS_STATE.FETCHING);
        const { success, count } = await addTrackersToEvent(trackerIds, eventId, pairingOption);

        if (!success) throw new Error('Failed to add trackers!');
        if (count === 0) {
          setProcessState(PROCESS_STATE.FINISHED);
          setMessageType('warning');
          setMessage(
            <div>
              {
                'We added no trackers to the event. Maybe all starters already connected and you disabled to create new starter or all device tracker are already connected in that event.'
              }
              <div className="mb-1" />
              <Link to={`/admin/events/${eventId}/participants`}>Switch here to the event</Link>
            </div>,
          );
        } else {
          setProcessState(PROCESS_STATE.FINISHED);
          setMessage(
            <div>
              {`Success, added ${count} trackers to the event!`}
              <br />
              <Link to={`/admin/events/${eventId}/participants`}>Switch here to the event</Link>
            </div>,
          );
        }
      } catch (err) {
        setProcessState(PROCESS_STATE.FAILURE);
        if (err instanceof HTTPFetchError) {
          setMessage(`Failed to connect the trackers with the starter. ${err.serverError}`);
        } else {
          setMessage('Failed to connect the trackers with the starter.');
        }
        setMessageType('danger');
      }
    }
  };

  const handleClose = () => {
    setMessage(null);
    setMessageType('success');
    onAddToEvent();
    setProcessState(PROCESS_STATE.INITED);
  };

  return (
    <div>
      Add {trackerIds.length} selected tracker to your event.
      {event != null ? (
        <h4 className="d-flex justify-content-center align-items-center p-3">{event.name}</h4>
      ) : (
        <div className="d-flex justify-content-center align-items-center p-3">
          <SearchEvent
            filter="can-edit"
            limit={5}
            onSelect={(event) => setEventId(event?.id || null)}
            inputStyle={{ width: 300 }}
          />
        </div>
      )}
      <PairingOptions>
        <PairingOptionSelector value={pairingOption} onChange={setPairingOption} />
        <PairingOptionsTooltip />
      </PairingOptions>
      {message && <Alert variant={messageType}>{message}</Alert>}
      <div className="p-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleClose} style={{ marginRight: '7px' }}>
          Close
        </Button>

        {processState !== PROCESS_STATE.FINISHED && (
          <Button
            onClick={handleAddTrackerToEvent}
            variant="primary"
            disabled={eventId == null || processState === PROCESS_STATE.FETCHING}
          >
            Add
          </Button>
        )}
      </div>
    </div>
  );
}

const PairingOptions = styled.div`  
  display: flex;
  justify-content: center;
  margin: 15px 0;

  & > :last-child {
    padding-left: 10px;
  }
`;
