import { range } from 'lodash';
import { DateTime } from 'luxon';

export const calculateDisabledTime = (current: DateTime | null, minDate: DateTime) => {
  if (current != null && current.startOf('day') < minDate) {
    const arrayOfDisabledHours = Array.from(range(0, 24)).splice(0, minDate.hour);
    let arrayOfDisabledMinutes = Array.from(range(0, 60)).splice(0, minDate.minute);
    let arrayOfDisabledSeconds = Array.from(range(0, 60)).splice(0, minDate.second);

    // if current hour is one greater than minDate, we don't want to disable minutes
    if (arrayOfDisabledHours.length < current.hour) {
      arrayOfDisabledMinutes = [];
    }
    // if current minute is one greater than minDate, we don't want to disable seconds
    if (arrayOfDisabledMinutes.length < current.minute) {
      arrayOfDisabledSeconds = [];
    }
    return {
      disabledHours: () => arrayOfDisabledHours,
      disabledMinutes: () => arrayOfDisabledMinutes,
      disabledSeconds: () => arrayOfDisabledSeconds,
    };
  }
  return {};
};
