import { encode } from '@msgpack/msgpack';
import mqtt from 'mqtt';
import MQTTClient, { Options as MQTTClientProps } from './service-utils/mqtt-client';
import {
  SCAN_SESSION_MESSAGE_TYPES,
  ScanSessionAcceptConnectionMessage,
  ScanSessionAcceptDisconnectionMessage,
  ScanSessionAcceptReadMessage,
  ScanSessionFailReadMessage,
} from './types/trackers';
import {
  CommandMessage,
  ConnectMessage,
  DisconnectMessage,
  LocationMessage,
  MESSAGE_TYPES,
  ReclaimMessage,
  StateMessage,
} from './types/trackers-v2';
import { Point } from './types/types';

function buildGeoObject({ lat, lng, elv, time }: Point) {
  return {
    lat: Math.round(lat * 2 ** 23),
    lng: Math.round(lng * 2 ** 23),
    elv: elv != null ? Math.round(elv * 2 ** 17) : 0,
    time: Math.round(time.valueOf() / 1000),
  };
}

function buildBasicTopic(trackerId: string | null): string {
  if (trackerId == null) return 'trackers/+/+/+/';
  const shortId = trackerId.slice(trackerId.length - 6, trackerId.length).toUpperCase();
  return `trackers/${shortId[0]}/${shortId[1]}/${shortId.slice(2, shortId.length)}/`;
}

function buildLocationTopic(trackerId: string | null = null): string {
  return `${buildBasicTopic(trackerId)}location`;
}

function buildCommandTopic(trackerId: string | null = null): string {
  return `${buildBasicTopic(trackerId)}command`;
}

function buildReclaimTopic(trackerId: string | null = null): string {
  return `${buildBasicTopic(trackerId)}reclaim`;
}

function buildStateTopic(trackerId: string | null = null): string {
  return `${buildBasicTopic(trackerId)}state`;
}

function getTrackerIdBuffer(trackerId: string): Buffer {
  return Buffer.from(trackerId, 'hex');
}

export interface ClientProps extends MQTTClientProps {
  subscriptionTopics?: Array<string>;
}

export default class RacemapMQTTClient {
  _host: string;
  _props: ClientProps;
  _client: MQTTClient;
  _subTopics: Array<string>;

  constructor(host: string, props: ClientProps = {}) {
    this._host = host;
    this._props = {
      ...props,
      host,
      onConnect: this._handleBrokerConnect,
    };
    this._client = new MQTTClient(this._props);
    this._subTopics = this._props.subscriptionTopics || [];
  }

  static async asAdmin(host: string, password: string | null = null) {
    const adminPassword = process.env.MQTT_ADMIN_PASSWORD || password;
    if (adminPassword == null || adminPassword === '') {
      throw new Error('Admin Password is invalid');
    }

    return await new RacemapMQTTClient(host, {
      username: 'admin',
      password: adminPassword,
    }).connect();
  }

  static async fromProps(host: string, props: ClientProps) {
    return await new RacemapMQTTClient(host, {
      ...props,
    }).connect();
  }

  static async fromEnvVars(props: ClientProps = {}) {
    const host = process.env.MQTT_HOST || process.env.HOST;
    if (host == null || host === '') {
      throw new Error('MQTT Host is invalid or missing');
    }
    const password = process.env.MQTT_PASSWORD;
    if (password == null || password === '') {
      throw new Error('MQTT Password is invalid or missing');
    }
    const username = process.env.MQTT_USERNAME;
    if (username == null || username === '') {
      throw new Error('MQTT Username is invalid or missing');
    }

    return await new RacemapMQTTClient(host, {
      ...props,
      username,
      password,
    }).connect();
  }

  static async fromWindow(props: ClientProps = {}) {
    const host = window.location.origin;
    const isSSL = host.startsWith('https://');

    return await new RacemapMQTTClient(host, {
      path: '/mqtt',
      protocol: isSSL ? 'wss' : 'ws',
      ...props,
    }).connect();
  }

  connect = async () => {
    await this._client.connect();
    return this;
  };

  disconnect = () => {
    this._client.end();
  };

  subscribe = async (topic: string, options: mqtt.IClientSubscribeOptions = { qos: 0 }) => {
    return this._client.subscribe(topic, options);
  };

  _publish = async (
    topic: string,
    message: Buffer | string,
    options: mqtt.IClientPublishOptions = { qos: 0 },
  ) => {
    return this._client.publish(topic, message, options);
  };

  trackerSubscriptions = async (trackerId: string) => {
    return this.subscribe(buildCommandTopic(trackerId));
  };

  reclaimSubscription = async () => {
    return this.subscribe(buildReclaimTopic());
  };

  stateSubscription = async () => {
    return this.subscribe(buildStateTopic());
  };

  locationSubsription = async () => {
    return this.subscribe(buildLocationTopic());
  };

  sendConnectMessage = async (trackerId: string) => {
    const newState: ConnectMessage = {
      tId: getTrackerIdBuffer(trackerId),
      type: MESSAGE_TYPES.CONNECT,
    };

    const message = Buffer.from(encode(newState));
    await this._publish(buildStateTopic(trackerId), message, { qos: 1 });
  };

  sendDissconnectMessage = async (trackerId: string) => {
    const newState: DisconnectMessage = {
      tId: getTrackerIdBuffer(trackerId),
      type: MESSAGE_TYPES.DISCONNECT,
    };

    const message = Buffer.from(encode(newState));
    await this._publish(buildStateTopic(trackerId), message, { qos: 1 });
  };

  sendLocationMessage = async (trackerId: string, point: Point) => {
    const newLocation: LocationMessage = {
      tId: getTrackerIdBuffer(trackerId),
      type: MESSAGE_TYPES.LOCATION,
      location: buildGeoObject(point),
    };

    const message = Buffer.from(encode(newLocation));
    await this._publish(buildLocationTopic(trackerId), message);
  };

  sendStateMessage = async (trackerId: string, point: Point | null = null) => {
    const newState: StateMessage = {
      tId: getTrackerIdBuffer(trackerId),
      type: MESSAGE_TYPES.STATE,
      battery: { SoC: Math.random() * 100 },
      location: point != null ? buildGeoObject(point) : undefined,
    };

    const message = Buffer.from(encode(newState));
    await this._publish(buildStateTopic(trackerId), message);
  };

  sendReclaimMessage = async (trackerId: string, customerId: Buffer) => {
    const newReclaim: ReclaimMessage = {
      type: MESSAGE_TYPES.RECLAIM,
      cId: customerId,
      tId: getTrackerIdBuffer(trackerId),
    };
    const reclaimMessage = Buffer.from(encode(newReclaim));
    await this._publish(buildReclaimTopic(trackerId), reclaimMessage);
  };

  sendCommandMessage = async (trackerId: string, command: CommandMessage) => {
    const commandMessage = Buffer.from(encode(command));
    await this._publish(buildCommandTopic(trackerId), commandMessage);
  };

  addPoint = async (trackerId: string, point: Point) => {
    if (!this.connected) {
      console.warn('Cant send position per MQTT. Client is not connected.');
      return;
    }

    await this.sendLocationMessage(trackerId, point);
  };

  startReclaimingProcess = async (trackerId: string, customerId: string) => {
    const commandMessage: CommandMessage = {
      type: MESSAGE_TYPES.COMMAND,
      cmd: 'reclaim',
      cId: Buffer.from(customerId, 'hex'),
    };

    return this.sendCommandMessage(trackerId, commandMessage);
  };

  sendScanSessionAcceptConnectMessage = async (readerId: string) => {
    const acceptConnectionMessage: ScanSessionAcceptConnectionMessage = {
      type: SCAN_SESSION_MESSAGE_TYPES.ACCEPT_CONNECTION,
    };

    return this._publish(`scan/${readerId}/reader`, JSON.stringify(acceptConnectionMessage));
  };

  sendScanSessionAcceptReadMessage = async (readerId: string, trackerId: string) => {
    const acceptReadMessage: ScanSessionAcceptReadMessage = {
      type: SCAN_SESSION_MESSAGE_TYPES.ACCEPT_READ,
      trackerId,
    };
    return this._publish(`scan/${readerId}/reader`, JSON.stringify(acceptReadMessage));
  };

  sendScanSessionReadFailMessage = async (readerId: string, trackerId: string, err = '') => {
    const failReadMessage: ScanSessionFailReadMessage = {
      type: SCAN_SESSION_MESSAGE_TYPES.FAIL_READ,
      trackerId,
      err,
    };
    return this._publish(`scan/${readerId}/reader`, JSON.stringify(failReadMessage));
  };

  sendScanSessionAcceptDisconnectMessage = async (readerId: string) => {
    const acceptDisconnectMessage: ScanSessionAcceptDisconnectionMessage = {
      type: SCAN_SESSION_MESSAGE_TYPES.ACCEPT_DISCONNECTION,
    };

    return this._publish(`scan/${readerId}/reader`, JSON.stringify(acceptDisconnectMessage));
  };

  _subscribeToTopics = async (subscriptionOptions: mqtt.IClientSubscribeOptions = { qos: 0 }) => {
    return Promise.all(this._subTopics.map((topic) => this.subscribe(topic, subscriptionOptions)));
  };

  _handleBrokerConnect = async () => {
    await this._subscribeToTopics();
  };

  get connected(): boolean {
    return this._client?.connected;
  }
}
