import { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { Header } from '../components/Headers/Header';
import LoadingSpinner from '../components/LoadingSpinner';
import { useCurrentUser } from '../lib/customHooks';
import { lazily } from '../lib/lazily';
import LoginView from '../views/LoginView';

const { MonitorView } = lazily(() => import('../views/MonitorView'));
const { LogoutView } = lazily(() => import('../views/LogoutView'));
const { RegisterView } = lazily(() => import('../views/RegisterView'));
const { ResetPasswordView } = lazily(() => import('../views/ResetPasswordView'));
const { ForgotPasswordView } = lazily(() => import('../views/ForgotPasswordView'));
const { ConfirmEmailView } = lazily(() => import('../views/ConfirmEmailView'));
const { TrackerLandingView } = lazily(() => import('../admin/views/TrackerLandingView'));
const { UploadLandingView } = lazily(() => import('../admin/views/UploadLandingView'));
const { CostCalculatorLandingView } = lazily(
  () => import('../admin/views/CostCalculatorLandingView'),
);
const { CustomAppLandingView } = lazily(() => import('../views/CustomAppLandingView'));
const { RedeemKeyLandingView } = lazily(() => import('../views/RedeemKeyLandingView'));
const { LeaderboardView } = lazily(() => import('../views/LeaderboardView'));
const { AdminRouter } = lazily(() => import('./admin.router'));

export const MainRouter = () => {
  const currentUser = useCurrentUser();
  const isAuthenticated = currentUser != null;

  return (
    <Router>
      <Header />

      <Switch>
        <Suspense fallback={<LoadingSpinner />}>
          <Route
            path="/auth/login"
            render={(props: RouteProps) => {
              if (isAuthenticated) return <Redirect to="/admin/" />;
              return <LoginView {...props} />;
            }}
          />
          <Route path="/auth/logout" component={LogoutView} />
          <Route path="/auth/register" component={RegisterView} />
          <Route path="/auth/reset-password" component={ResetPasswordView} />
          <Route path="/auth/forgot-password" component={ForgotPasswordView} />
          <Route path="/auth/confirm-email" component={ConfirmEmailView} />

          <Route path="/tracker/:trackerId" component={TrackerLandingView} />
          <Route path="/upload" component={UploadLandingView} exact />
          <Route path="/cost-calculator/:product" component={CostCalculatorLandingView} />
          <Route path="/app" component={CustomAppLandingView} exact />
          <Route path="/app/redeem_key" component={RedeemKeyLandingView} />
          <Route path="/app/:appIdentifier" component={CustomAppLandingView} exact />
          <Route path="/app-:appIdentifier" component={CustomAppLandingView} exact />
          <Route path="/app/:appIdentifier/redeem_key" component={RedeemKeyLandingView} />
          <Route path="/app-:appIdentifier/redeem_key" component={RedeemKeyLandingView} />
          <Route path="/timing/:eventSlug" component={LeaderboardView} />
          <Route path="/monitor/:eventSlug" component={MonitorView} />
          <Route path="/admin" component={AdminRouter} />
        </Suspense>
      </Switch>
    </Router>
  );
};
