import _ from 'lodash';

export const DEFAULT_KEY_LENGTH = 6;

type CharSetKeys = 'upperCase' | 'lowerCase' | 'full';
const charSetMapping: Record<CharSetKeys, string> = {
  upperCase: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  lowerCase: 'abcdefghijklmnopqrstuvwxyz0123456789',
  full: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
};

type generateKeyParams = {
  length?: number;
  characterSet?: CharSetKeys;
};

export function generateKey({
  length = DEFAULT_KEY_LENGTH,
  characterSet = 'upperCase',
}: generateKeyParams = {}): string {
  const chars: string = charSetMapping[characterSet];
  let key = '';

  for (let i = 0; i < length; i++) {
    key += _.sample(chars);
  }

  return key;
}

export function generateHexColorCode() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}
