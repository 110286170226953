import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, Geometry, LineString, Point, Polygon } from 'geojson';
import mongoose, { Types } from 'mongoose';
import { SportTypes } from '../consts/eventTypes';
import { SymbolTypes } from '../consts/map';

type FeatureDocument<G extends Geometry | null, P> = mongoose.Document<Types.ObjectId> &
  Feature<G, P>;

export interface DrawModes extends MapboxDraw.DrawModes {
  POINT_ON_LINE_STRING: 'point_on_line_string';
  EXPAND_LINE_STRING: 'expand_line_string';
}
export type DrawMode = DrawModes[keyof DrawModes];

export enum EditModeTypes {
  AddTrack = 'addTrack',
  AddPoi = 'addPoi',
  AddPoiOnTrack = 'addPoiOnTrack',
  AddSplit = 'addSplit',
  SimpleSelect = 'simpleSelect',
  DirectSelect = 'directSelect',
  ExpandTrack = 'expandTrack',
  ModifySplit = 'modifySplit',
}

export interface EditMode {
  type: EditModeTypes;
  drawMode: DrawMode;
  targetElementType?: RacemapGeoTypes;
  targetGeoType?: GeoElementTypes;
}

export enum RacemapGeoTypes {
  POI = 'poi',
  SPLIT = 'split',
  TRACK = 'track',
  EXTERNAL_LAYER = 'externalLayer',
  AREA = 'area',
}

export enum GeoElementTypes {
  LineString = 'LineString',
  Point = 'Point',
  Layer = 'Layer',
  Polygon = 'Polygon',
}

export enum LayerType {
  WMS = 'wms',
}

export const LayerOptions: Array<{ value: LayerType; label: string }> = [
  {
    value: LayerType.WMS,
    label: 'WMS',
  },
];

export interface GeoDocumentProperties {
  eventId: Types.ObjectId;
  creatorId: Types.ObjectId;
  updaterId: Types.ObjectId;
  createdAt: Date;
  updatedAt: Date;
  racemapType: RacemapGeoTypes;
  oldId?: string;
}

// Basic Geo Documents

export type LineStringProperties<GP = GeoDocumentProperties> = GP & {
  racemapType: RacemapGeoTypes.TRACK;
  name: string;
  color: string;
  length: number;
  pointCount: number;
  hidden: boolean;
};

export type PointProperties<GP = GeoDocumentProperties, ID = Types.ObjectId> = GP & {
  racemapType: RacemapGeoTypes.POI | RacemapGeoTypes.SPLIT;
  name: string;
  shortName: string;
  color: string;
  hidden: boolean;
  icon?: SymbolTypes | null;
  timekeeping?: boolean;
  readerIds?: Array<string>;
  coordinateIndex?: number;
  lineStringId?: ID;
};

export type LayerProperties<GP = GeoDocumentProperties> = GP & {
  racemapType: RacemapGeoTypes.EXTERNAL_LAYER;
  name: string;
  layerType: LayerType;
};

// only in fronted usable
export type PolygonProperties<GP = GeoDocumentProperties> = GP & {
  name: string;
  color: string;
  pointCount: number;
  racemapType: RacemapGeoTypes.AREA;
};

// Special Intermediate Properties

export type TimekeepingLocationProperties<GP = GeoDocumentProperties> = GP & {
  timekeeping: boolean;
  readerIds?: Array<string>;
};

export type PointOnLineStringDocumentProperties = PointProperties<GeoDocumentProperties> & {
  lineStringId: Types.ObjectId;
  coordinateIndex: number;
  offset: number;
};

// Final Geo Properties

export type EventTrackProperties<GP = GeoDocumentProperties> = LineStringProperties<GP>;

export type SplitDocumentProperties = TimekeepingLocationProperties<GeoDocumentProperties> &
  PointOnLineStringDocumentProperties & {
    racemapType: RacemapGeoTypes.SPLIT;
    newSport: SportTypes | '';
    resetSpeed: number | null;
    minSpeed: number;
    maxSpeed: number;
    icon: SymbolTypes | null;
  };

export type POIProperties<GP = GeoDocumentProperties> = PointProperties<GP> & {
  racemapType: RacemapGeoTypes.POI;
  description: string;
  icon: SymbolTypes | null;
};

export type WMSLayerProperties<GP = GeoDocumentProperties> = LayerProperties<GP> & {
  layerType: LayerType.WMS;
  tiles: Array<string>;
  tileSize: number;
};

// Document Types (Backend Site)

export type GeoElementDocument = FeatureDocument<
  LineString | Point | Polygon | null,
  GeoDocumentProperties
>;

export type LineStringDocument = FeatureDocument<
  LineString,
  LineStringProperties<GeoDocumentProperties>
>;
export type PointDocument = FeatureDocument<Point, PointProperties<GeoDocumentProperties>>;
export type LayerDocument = FeatureDocument<null, LayerProperties<GeoDocumentProperties>>;

export type PointOnLineStringDocument = FeatureDocument<Point, PointOnLineStringDocumentProperties>;
export type TimekeepingLocationDocument = FeatureDocument<
  Point,
  TimekeepingLocationProperties<GeoDocumentProperties>
>;

export type EventTrackDocument = FeatureDocument<
  LineString,
  EventTrackProperties<GeoDocumentProperties>
>;
export type SplitDocument = FeatureDocument<Point, SplitDocumentProperties>;
export type SplitPrototypeDocument = Omit<SplitDocument, 'id'>;
export type POIDocument = FeatureDocument<Point, POIProperties<GeoDocumentProperties>>;
export type WMSLayerDocument = FeatureDocument<null, WMSLayerProperties<GeoDocumentProperties>>;
export type RacemapGeoDocument =
  | GeoElementDocument
  | LineStringDocument
  | PointDocument
  | LayerDocument
  | PointOnLineStringDocument
  | TimekeepingLocationDocument
  | EventTrackDocument
  | SplitDocument
  | POIDocument
  | WMSLayerDocument;

export type RacemapFeatureDocumentCollection = {
  type: 'FeatureCollection';
  features: Array<RacemapGeoDocument>;
};

export type RacemapShadowtrackFeatureDocumentCollection = {
  type: 'FeatureCollection';
  features: [EventTrackDocument] | [];
};

export type GeoDocuments = {
  id: string;
  eventId: string;
  geojson?: RacemapFeatureDocumentCollection;
  shadowgeojson?: RacemapShadowtrackFeatureDocumentCollection;
  shadowtrackId?: string;
};

// Object Types (Frontend Site)

export interface GeoObjectProperties<ID = string> {
  eventId: ID;
  creatorId?: ID;
  updaterId?: ID;
  createdAt?: string;
  updatedAt?: string;
  racemapType: RacemapGeoTypes;
  oldId?: string;
  /**
   * @deprecated: That flag is belongs to a legacy structur. Only used for export and import.
   */
  shadowtrack?: boolean;
  newSport?: SportTypes | '';
  resetSpeed?: number | null;
  minSpeed?: number;
  maxSpeed?: number;
}

export type LineStringObjectProperties<ID = string> = LineStringProperties<GeoObjectProperties<ID>>;
export type PointObjectProperties<ID = string> = PointProperties<GeoObjectProperties<ID>, ID>;

export type PointOnLineStringObjectProperties = PointObjectProperties<string> & {
  lineStringId: string;
  coordinateIndex: number;
  offset?: number;
};

export type SplitObjectProperties = TimekeepingLocationProperties<GeoObjectProperties<string>> &
  PointOnLineStringObjectProperties & {
    racemapType: RacemapGeoTypes.SPLIT;
    newSport: SportTypes | '';
    resetSpeed: number | null;
    minSpeed: number;
    maxSpeed: number;
    icon: string | null;
  };

export type GeoElementObject = Feature<LineString | Point | Polygon | null, GeoObjectProperties> & {
  id: string;
};

export type LineStringObject = Feature<LineString, LineStringProperties<GeoObjectProperties>> & {
  id: string;
};
export type LineStringPropotypeObject = Omit<LineStringObject, 'id'>;
export type PointObject = Feature<Point, PointObjectProperties<string>> & { id: string };
export type PointPrototypeObject = Omit<PointObject, 'id'>;
export type LayerObject = Feature<null, LayerProperties<GeoObjectProperties>> & { id: string };
export type LayerPrototypeObject = Omit<LayerObject, 'id'>;
export type PolygonObject = Feature<Polygon, PolygonProperties<GeoObjectProperties>> & {
  id: string;
};

export type PointOnLineStringObject = Feature<Point, PointOnLineStringObjectProperties> & {
  id: string;
};
export type PointOnLineStringPrototypeObject = Omit<PointOnLineStringObject, 'id'>;
export type TimekeepingLocationObject = Feature<
  Point,
  TimekeepingLocationProperties<GeoObjectProperties>
> & { id: string };

export type EventTrackObject = Feature<
  LineString,
  EventTrackProperties<EventTrackProperties<GeoObjectProperties>>
> & {
  id: string;
};
export type EventTrackPrototypeObject = Omit<EventTrackObject, 'id'>;
export type SplitObject<ID = string> = Feature<Point, SplitObjectProperties> & { id: ID };
export type SplitPrototypeObject<ID = string> = Omit<SplitObject<ID>, 'id'>;

export type POIObjectProperties = POIProperties<GeoObjectProperties> & { lineStringId?: string };

export type POIObject = Feature<Point, POIObjectProperties> & { id: string };
export type POIPrototypeObject = Omit<POIObject, 'id'>;
export type WMSLayerObject = Feature<null, WMSLayerProperties<GeoObjectProperties>> & {
  id: string;
};
export type WMSLayerPrototypeObject = Omit<WMSLayerObject, 'id'>;

export type RacemapGeoObject =
  | GeoElementObject
  | LineStringObject
  | PointObject
  | LayerObject
  | PointOnLineStringObject
  | TimekeepingLocationObject
  | EventTrackObject
  | SplitObject
  | POIObject
  | WMSLayerObject;

export type EditableGeoObject =
  | PolygonObject
  | LineStringObject
  | PointObject
  | TimekeepingLocationObject
  | EventTrackObject
  | SplitObject
  | POIObject;

export type RacemapFeatureCollection = {
  type: 'FeatureCollection';
  features: Array<RacemapGeoObject>;
};

export type EditableFeatureCollection = {
  type: 'FeatureCollection';
  features: Array<EditableGeoObject>;
};

export type RacemapShadowtrackFeatureCollection = {
  type: 'FeatureCollection';
  features: [EventTrackObject] | [];
};

export type ExternalLayerCollection = {
  type: 'FeatureCollection';
  features: Array<LayerObject>;
};

export type GeoObjects = Array<RacemapGeoObject>;

/**
 * @deprecated Old geoformat
 */
export type Geos = {
  id: string;
  eventId: string;
  geojson?: RacemapFeatureCollection;
  shadowgeojson?: RacemapShadowtrackFeatureCollection;
  shadowtrackId?: string;
};
