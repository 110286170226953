import React from 'react';
import { IconSpinner } from './Icon';

const LoadingSpinner = () => {
  const style = {
    justifyContent: 'center',
    fontSize: '52px',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
  };

  const styleIcon = {
    marginTop: '100px',
  };

  return (
    <div style={style}>
      <IconSpinner style={styleIcon} />
    </div>
  );
};

export default LoadingSpinner;
