import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { FC, Key, useEffect } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface Props {
  title: React.ReactNode;
  keyNode: Key;
  count: number;
}

export const CustomDataNodeDraggable: FC<Props> = ({ title, keyNode, count }: Props) => {
  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: 'filter-tree',
    item: { key: keyNode, count: count },
    canDrag: () => true,
    isDragging: (monitor: DragSourceMonitor) => (monitor.getItem() as { key: Key }).key === keyNode,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  return (
    <Container ref={drag} isDragging={isDragging} canDrag={true} isInteractable={true}>
      <span>{title}</span>
      <CountField>{count}</CountField>
    </Container>
  );
};

const Container = styled.div<{ canDrag?: boolean; isDragging?: boolean; isInteractable: boolean }>`
    display: flex;
    justify-content: space-between;
    ${({ isDragging }) => (isDragging ? `color: ${RacemapColors.PaleBlue};` : '')}
    
    ${({ canDrag, isInteractable }) =>
      canDrag ? 'cursor: grab;' : isInteractable ? 'cursor: pointer;' : ''}
  `;

const CountField = styled.div`
    color: ${RacemapColors.DarkGray};
    margin-left: 4px;
  `;
