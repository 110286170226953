import {
  ZodiosEndpointDefinition,
  ZodiosEndpointDefinitions,
  ZodiosError,
  ZodiosPlugin,
  zodValidationPlugin,
} from '@zodios/core';

type Options = {
  validate?: boolean;
  transform?: boolean;
  sendDefaults?: boolean;
};

function shouldResponse(option: string | boolean) {
  return [true, 'response', 'all'].includes(option);
}

function shouldRequest(option: string | boolean) {
  return [true, 'request', 'all'].includes(option);
}

export function findEndpoint(
  api: ZodiosEndpointDefinitions,
  method: string,
  path: string,
): (ZodiosEndpointDefinition<unknown> & { validate?: boolean; transform?: boolean }) | undefined {
  return api.find((e) => e.method === method && e.path === path);
}

export function customValidationPlugin({
  validate = true,
  transform = true,
  sendDefaults = false,
}: Options = {}): ZodiosPlugin {
  return {
    name: zodValidationPlugin({ validate: true, transform: true, sendDefaults: true }).name,
    request: shouldRequest(validate)
      ? async (api, config) => {
          const endpoint = findEndpoint(api, config.method, config.url);
          if (!endpoint) {
            throw new Error(`No endpoint found for ${config.method} ${config.url}`);
          }
          if (
            (endpoint.validate != null && !endpoint.validate) ||
            // @ts-expect-error - custom implementation
            (config.validate != null && !config.validate)
          )
            return config;

          const { parameters } = endpoint;
          if (!parameters) {
            return config;
          }
          const conf = {
            ...config,
            queries: {
              ...config.queries,
            },
            headers: {
              ...config.headers,
            },
            params: {
              ...config.params,
            },
          };
          const paramsOf = {
            Query: (name: string) => conf.queries?.[name],
            Body: (_: string) => conf.data,
            Header: (name: string) => conf.headers?.[name],
            Path: (name: string) => conf.params?.[name],
          };
          const setParamsOf = {
            Query: (name: string, value: unknown) => {
              if (conf.queries != null) conf.queries[name] = value;
            },
            Body: (_: string, value: unknown) => {
              conf.data = value;
            },
            Header: (name: string, value: string) => {
              if (conf.headers != null) conf.headers[name] = value;
            },
            Path: (name: string, value: unknown) => {
              if (conf.params != null) conf.params[name] = value;
            },
          };
          const transformRequest = shouldRequest(transform);
          for (const parameter of parameters) {
            const { name, schema, type } = parameter;
            const value = paramsOf[type](name);
            if (sendDefaults || value !== undefined) {
              const parsed = await schema.safeParseAsync(value);
              if (!parsed.success) {
                throw new ZodiosError(
                  `Zodios: Invalid ${type} parameter '${name}'`,
                  config,
                  value,
                  parsed.error,
                );
              }
              if (transformRequest && (endpoint.transform || true)) {
                setParamsOf[type](name, parsed.data);
              }
            }
          }
          return conf;
        }
      : undefined,
    response: shouldResponse(validate)
      ? async (api, config, response) => {
          const endpoint = findEndpoint(api, config.method, config.url);
          if (!endpoint) {
            throw new Error(`No endpoint found for ${config.method} ${config.url}`);
          }
          if (
            (endpoint.validate != null && !endpoint.validate) ||
            // @ts-expect-error - custom implementation
            (config.validate != null && !config.validate)
          )
            return response;

          if (
            response.headers?.['content-type']?.includes('application/json') ||
            response.headers?.['content-type']?.includes('application/vnd.api+json')
          ) {
            const parsed = await endpoint.response.safeParseAsync(response.data);
            if (!parsed.success) {
              throw new ZodiosError(
                `Zodios: Invalid response from endpoint '${endpoint.method} ${
                  endpoint.path
                }'\nstatus: ${response.status} ${response.statusText}\ncause:\n${
                  parsed.error.message
                }\nreceived:\n${JSON.stringify(response.data, null, 2)}`,
                config,
                response.data,
                parsed.error,
              );
            }
            if (shouldResponse(transform) && (endpoint.transform || true)) {
              response.data = parsed.data;
            }
          }
          return response;
        }
      : undefined,
  };
}
