/**
 * Computes a simple hash number from a given string.
 * The hash is computed by summing the character codes of each character in the string.
 *
 * @param str - The string to hash.
 * @returns The computed hash number.
 */
export function simpleHashNumber(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }
  return hash;
}
