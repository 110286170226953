import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import {
  getEventCyclesOfTimeRange,
  getPricingRulesOfEvent,
} from '@racemap/utilities/functions/billing';
import { Card, Space } from 'antd';
import { DateTime } from 'luxon';
import { useCurrentEvent } from '../../../lib/customHooks';
import { getBillableEventsForEvent } from '../../../store/events/events_reducer_helper';
import { HStack } from '../../BasicComponents/MetaComponent';
import { Bold } from '../../BasicComponents/MetaComponent/MetaComponent';
import { PopoverHint } from '../../BasicComponents/PopoverHint';
import { EventCycleHint } from './EventCycleHint';
import { getHumanReadableDuration } from './utils';

export const DurationAndCyclesInfo = () => {
  const event = useCurrentEvent();
  if (event == null) return null;

  const startTime = event?.startTime && DateTime.fromISO(event.startTime).toUTC().startOf('day');
  const endTime = event?.endTime && DateTime.fromISO(event.endTime).toUTC().endOf('day');
  const possibleEventDayBillableItems = getBillableEventsForEvent(event);
  const pricing = getPricingRulesOfEvent(event);
  const eventCycles = getEventCyclesOfTimeRange(
    possibleEventDayBillableItems,
    pricing.numDaysEventCycle,
  );
  const duration = endTime && startTime ? endTime.diff(startTime) : null;

  if (duration == null) return null;

  return (
    <Container>
      <HStack justify="space-around">
        <Space size="large">
          <Bold>Duration:</Bold>
          <div>{getHumanReadableDuration(duration)}</div>
        </Space>
        <Space>
          <Space size="large">
            <Bold>Event Cycles:</Bold>
            <Space size={3}>
              <div>{eventCycles.totalCycles}x</div>
              <Hint />
            </Space>
          </Space>
        </Space>
      </HStack>
    </Container>
  );
};

const Container = styled(Card)`
  width: 100%;
  border-color: ${RacemapColors.PaleBlue};
  max-width: 1000px;
  margin: auto;
  background-color: ${RacemapColors.LightBlue};

  div {
    row-gap: 5px;
  }
`;

const Hint = () => {
  return (
    <PopoverHint variant="description-light">
      <EventCycleHint />
    </PopoverHint>
  );
};
