/**
 * Checks if a given input string satisfies the Luhn algorithm.
 * The Luhn algorithm is used to validate a variety of identification numbers, such as credit card numbers.
 * For more information, refer to: https://en.wikipedia.org/wiki/Luhn_algorithm
 *
 * @param input - The input string to be checked.
 * @returns A boolean value indicating whether the input string satisfies the Luhn algorithm.
 */
export function checkLuhnAlgorithm(input: string): boolean {
  const digits = input.split('').reverse().map(Number);
  if (digits.length === 0) return false;

  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    if (i % 2 === 1) {
      let doubled = digits[i] * 2;
      if (doubled > 9) {
        doubled -= 9;
      }
      sum += doubled;
    } else {
      sum += digits[i];
    }
  }
  return sum % 10 === 0;
}
