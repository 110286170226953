import TestSelectors from '@racemap/utilities/consts/test_selectors';
import { Button, Flex, Input, Space } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Message } from '../views/LoginView';
import { Devider } from './BasicComponents/Devider/Devider';
import { StateMessage } from './BasicComponents/StateMessage';
import { Tristate } from './ButtonWithSpinner';
import { IconArrowDoubleRight } from './Icon';

export type Props = {
  onLogin: (email: string, password: string) => void | Promise<void>;
  isLoggingIn: Tristate;
  message: Message | null;
  onForgetPasswordClick: () => void;
  onRegisterClick: () => void;
};

export function LoginForm({
  onLogin,
  isLoggingIn,
  message,
  onForgetPasswordClick,
  onRegisterClick,
}: Props) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    onLogin(email, password);
  };

  return (
    <form onSubmit={handleSubmit} className="test-login">
      <Flex vertical gap={10} align="start">
        <StateMessage message={message} />
        <Input
          id={TestSelectors.login.form.email.toString()}
          placeholder="Email"
          required={true}
          type="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          autoFocus={true}
          className={TestSelectors.admin.auth.inputs.email.toString()}
        />
        <Input
          id={TestSelectors.login.form.password.toString()}
          placeholder="Password"
          required={true}
          type="password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          className={TestSelectors.admin.auth.inputs.password.toString()}
        />
        <Flex justify="end" style={{ width: '100%' }}>
          <Button
            id={TestSelectors.login.button.toString()}
            htmlType="submit"
            loading={isLoggingIn === Tristate.SPINNING}
            className={classNames('btn-primary', TestSelectors.admin.auth.buttons.login.toString())}
          >
            Login
          </Button>
        </Flex>

        <Devider />
        <Button type="link" size="small" onClick={onForgetPasswordClick} style={{ paddingLeft: 0 }}>
          I forgot my password <IconArrowDoubleRight />
        </Button>
        <Space>
          Not registered on Racemap yet?
          <Button
            type="link"
            id="register_link"
            onClick={onRegisterClick}
            style={{ paddingLeft: 0 }}
          >
            Start here.
            <IconArrowDoubleRight />
          </Button>
        </Space>
      </Flex>
    </form>
  );
}
