import styled from '@emotion/styled';

export const EventIsLockedModal = () => {
  return (
    <Body>
      Your event was created and activated with the old pricing. You can still activate and
      deactivate your account. But you can't change the event times so much, that further event
      cycles are the result. If you wan't to reuse the event, we recommend you to duplicate that
      event. You can find the duplicate function in the Advanced Settings.
    </Body>
  );
};

const Body = styled.div`
    padding: 5px;
    section {
        margin-bottom: 1rem;
    }

    .ant-card:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;
