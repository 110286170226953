import styled from '@emotion/styled';
import AnnounceKit from 'announcekit-react';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { UPDATES_WIDGET_URL } from './AnnounceKit';

interface AnnounceKitWrapperProps extends PropsWithChildren {
  widget?: string;
  onUnreadChange: (unread: number) => void;
}

export const AnnounceKitReadGetter: FC<AnnounceKitWrapperProps> = ({
  onUnreadChange,
  widget = UPDATES_WIDGET_URL,
  children,
}) => {
  const ref = useRef<{ unread: () => Promise<number> }>();

  useEffect(() => {
    if (ref.current == null) return;

    ref.current.unread().then((unread) => {
      onUnreadChange(unread);
    });
  }, []);

  return (
    <Container>
      <AnnounceKit
        ref={ref}
        widget={widget}
        onWidgetUnread={({ unread }: { unread: number }) => onUnreadChange(unread)}
      />
      {children}
    </Container>
  );
};

const Container = styled.div`
  .announcekit-widget-badge {
    display: none !important;
  } 
`;
