import { checkLuhnAlgorithm } from './checkLuhnAlgorithm';

/**
 * Checks if the given IMEI (International Mobile Equipment Identity) is valid.
 *   imei has 3 parts
 * TAC (RBI + random) + SNR + Check digit
 * TAC: Type Allocation Code | 2 + 6 digits
 * SNR: Serial Number | 6 digits
 * Check digit: Luhn algorithm | 1 digit
 * example 86-059900-204310-7
 *
 * @param imei - The IMEI to validate.
 * @returns A boolean indicating whether the IMEI is valid or not.
 * @see {@link https://en.wikipedia.org/wiki/International_Mobile_Equipment_Identity}
 */

export function isValidIMEI(imei: string): boolean {
  // imei has only numbers
  // length is 15
  if (!/^[0-9]{15}$/.test(imei)) return false;

  // check luhn algorithm
  return checkLuhnAlgorithm(imei);
}
