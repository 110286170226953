import { useCallback } from 'react';
import { useLocation } from 'react-router';

export function useIsNavLinkActive(): {
  isActive: (pathname: string, exact?: boolean) => boolean;
} {
  const location = useLocation();
  const isActive = useCallback(
    (pathname: string, exact = false) => {
      if (exact) {
        return location.pathname === pathname;
      }
      return location.pathname.startsWith(pathname);
    },
    [location],
  );

  return { isActive };
}
