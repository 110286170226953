import { User_Legacy } from '@racemap/utilities/types/users';
import { Flex } from 'antd';
import { Immutable } from 'immer';
import { FC } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCurrentUser, useIsChildAccount } from '../../lib/customHooks';
import { UpdateStream } from '../AnnounceKit';
import { AccountInfo } from '../BasicComponents/AccountInfo/AccountInfo';
import { CostCalculator } from '../CostCalculator';
import { UserRoleBadges } from '../MainHeader/UserRoleBadges';
import { ButtonModal } from '../Modal';
import { LinkEntry } from './LinkEntry';
import { SectionHeader } from './SectionHeader';
import { useIsNavLinkActive } from './utils';

export const UserLinks: FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
  const currentUser = useCurrentUser();
  const isChild = useIsChildAccount();
  const { isActive } = useIsNavLinkActive();

  if (currentUser == null) {
    return null;
  }

  const content = (
    <>
      <LinkEntry
        as={Link}
        to={`/admin/account/${currentUser.id}`}
        id={'nav_link_profile'}
        active={isActive(`/admin/account/${currentUser.id}`, true)}
      >
        Account
      </LinkEntry>

      <CostCalculatorEntry isCollapsed={isCollapsed} isChild={isChild} />
      <BillingEntry isChild={isChild} currentUser={currentUser} />
      <LinkEntry as={Link} to="/admin/accounts/updates" title="Updates" id="nav_link_updates">
        <UpdateStream>Updates</UpdateStream>
      </LinkEntry>
      <LinkEntry as={Link} to="/auth/logout" title="Logout" id="nav_link_logout">
        Logout
      </LinkEntry>
    </>
  );

  if (isCollapsed) {
    return (
      <Nav navbarScroll>
        <SectionHeader>
          <Flex justify="space-between">
            User
            <UserRoleBadges />
          </Flex>
        </SectionHeader>
        {content}
      </Nav>
    );
  }

  return (
    <Nav>
      <NavDropdown id="user" title={<AccountInfo />} className="nav-dropdown-user">
        {content}
      </NavDropdown>
    </Nav>
  );
};

const BillingEntry = ({
  isChild,
  currentUser,
}: { isChild: boolean; currentUser: Immutable<User_Legacy> }) => {
  const { isActive } = useIsNavLinkActive();
  if (isChild) return null;

  return (
    <LinkEntry
      as={Link}
      to={`/admin/account/${currentUser.id}/billing`}
      id="nav_link_billing"
      active={isActive(`/admin/account/${currentUser.id}/billing`, true)}
    >
      Billing
    </LinkEntry>
  );
};

const CostCalculatorEntry = ({
  isCollapsed,
  isChild,
}: { isCollapsed: boolean; isChild: boolean }) => {
  if (isChild) return null;

  if (isCollapsed)
    return (
      <LinkEntry>
        <ButtonModal
          title="Estimate costs for geolocating"
          buttonTitle="Cost Calculator"
          buttonStyle={{
            all: 'unset',
            cursor: 'pointer',
          }}
        >
          <CostCalculator />
        </ButtonModal>
      </LinkEntry>
    );

  return (
    <ButtonModal
      title="Estimate costs for geolocating"
      buttonClass="dropdown-item"
      buttonTitle="Cost Calculator"
      size="lg"
    >
      <CostCalculator />
    </ButtonModal>
  );
};
