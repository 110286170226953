import { isPrimitive } from '@racemap/utilities/functions/validation';
import { PathInto, TypeOfPath } from '@racemap/utilities/types/utils';
import { produce } from 'immer';
import set from 'lodash/set';

function keySplitter(key: string): Array<string> {
  return key.split('.');
}

function updateValueNested<T extends Record<string, unknown>, P extends PathInto<T>>(
  root: T | undefined | null,
  key: P,
  value: TypeOfPath<T, P>,
): T | undefined {
  if (root == null) return undefined;

  return produce(root, (obj: object) => {
    set(obj, key, value);
  });
}

/**
 * Create a partial patch object based on the provided updates, which can be used
 * to apply changes to an existing object. The function supports nested fields and
 * can handle primitive and non-primitive values. If the key in the update matches
 * a nested field, the function will apply the update at the appropriate level.
 *
 * @param updates Array of update objects, each containing a `key` (string) and a `newValue` (any).
 *                The `key` can be a nested path (e.g., 'user.name.first').
 * @param baseObject Optional base object from which the current values are taken.
 *                   If not provided, the patch will be created based on the `updates` alone.
 *
 * @return A partial object containing the updates, suitable for patching an existing object.
 */
export function createPatchObj<RootObject extends Record<string, unknown>>(
  updates: { key: string; newValue: any }[],
  baseObject?: RootObject,
  protectedFields?: string[],
): Partial<RootObject> {
  const patchObj: Partial<RootObject> = {};

  for (const update of updates) {
    const fieldKeys = keySplitter(update.key);
    if (fieldKeys.length === 0) continue;

    if (
      protectedFields?.some((field) => {
        const protectedFieldSplitted = keySplitter(field);
        return protectedFieldSplitted.every((part, index) => part === fieldKeys[index]);
      })
    ) {
      throw new Error(`The ${update.key} field is not editable`);
    }

    const firstKey = fieldKeys[0] as keyof RootObject;
    if (!firstKey) continue;
    const currentValue = patchObj[firstKey] || baseObject?.[firstKey];

    if (fieldKeys.length === 1) {
      patchObj[firstKey] = update.newValue as RootObject[keyof RootObject];
    } else if (!isPrimitive(currentValue)) {
      patchObj[firstKey] = updateValueNested(
        currentValue as Record<string, unknown>,
        fieldKeys.slice(1).join('.'),
        update.newValue,
      ) as RootObject[keyof RootObject];
    }
  }

  return patchObj;
}
