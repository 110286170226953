import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { Brand } from '@racemap/utilities/types/brand';
import { Immutable, produce } from 'immer';
import { StoreApi } from 'zustand';
import { DraftState, State } from './reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();

export interface BrandsState {
  brands: {
    items: Map<string, Brand>;

    actions: {
      loadBrands: () => Promise<void>;
    };
  };
}

export const createBrandsStore = (set: StoreApi<State>['setState']): Immutable<BrandsState> => ({
  brands: {
    items: new Map(),

    actions: {
      loadBrands: async () => {
        const brands = await apiClient.listBrands();
        set(
          produce((s: DraftState) => {
            s.brands.items = new Map(brands.map((b) => [b.id, b]));
          }),
        );
      },
    },
  },
});
