import DatePicker from 'antd/es/date-picker';
import { PickerProps } from 'antd/es/date-picker/generatePicker';
import { DateTime } from 'luxon';
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';
import React from 'react';

const CustomDatePicker = DatePicker.generatePicker<DateTime>(luxonGenerateConfig);

const CustomMonthPicker: React.FC<PickerProps<DateTime>> = (props) => (
  <CustomDatePicker picker="month" {...props} />
);

export default CustomMonthPicker;
