import { GenericImportCheckResult, GenericImportStarterRecord } from '../../types/events';
import { validateStarterImportRecord } from './validateStarterImportRecord';

export const validateGenericImport = (
  data: Array<GenericImportStarterRecord> | null,
  keys: Set<string>,
  errors: Array<string> = [],
): GenericImportCheckResult => {
  const result = {
    errors: [...errors],
    failures: [],
    validCount: 0,
  } as GenericImportCheckResult;

  if (data == null) {
    result.errors.push('No starters has been found in imported source file.');
    return result;
  }

  if (data.length === 0) {
    result.errors.push('No starters have been found in imported source file. Only a empty array.');
    return result;
  }

  for (const starter of data) {
    const failed = validateStarterImportRecord(starter, keys);
    if (failed != null) {
      result.failures.push(failed);
    } else {
      result.validCount += 1;
    }
  }

  return result;
};
