import {
  AllPingsQuery,
  DBSortOrder,
  DBTimestampType,
  HTTPFetchError,
  RacemapAPIClient,
} from '@racemap/utilities/api-client';
import { OneDayInMillis } from '@racemap/utilities/consts/time';
import { Shadowtrack } from '@racemap/utilities/types/events';
import { EventTrackObject } from '@racemap/utilities/types/geos';
import { PreparedRead } from '@racemap/utilities/types/prediction';
import { Probe } from '@racemap/utilities/types/tpom-probe';
import { Ping } from '@racemap/utilities/types/types';
import { produce } from 'immer';
import moment from 'moment';
import { StoreApi } from 'zustand';
import { DraftState, State } from './reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();

export type ReadsFilter = Omit<AllPingsQuery, 'startTime' | 'endTime'> & {
  startTime: Date;
  endTime: Date;
};

export interface PredictionState {
  prediction: {
    probes: {
      items: Map<string, Array<Probe>>;
    };
    readsFilter: ReadsFilter;
    chunkedShadowTrack: EventTrackObject | null;
    // maybe load the simple shadowtrack to make the rendering in the map simpler
    actions: {
      loadProbes: (eventId: string) => Promise<void>;
      loadLastProbes: (eventId: string) => Promise<void>;
      loadShadowtrack: (eventId: string) => Promise<void>;
      setReadsFilter: (filter: Partial<ReadsFilter>) => void;
    };
    getter: {
      lastProbe: (eventId: string) => Probe | null;
    };
  };
}

export const createPredictionStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): PredictionState => ({
  prediction: {
    probes: {
      items: new Map(),
    },
    chunkedShadowTrack: null,
    filter: {},
    readsFilter: {
      eventId: '',
      startTime: new Date(new Date().valueOf() - 5 * OneDayInMillis),
      endTime: new Date(),
      timestampType: DBTimestampType.ReceivedAt,
      order: DBSortOrder.Descending,
      limit: 100,
      offset: 0,
      dummy: false,
    },
    actions: {
      loadProbes: async (eventId) => {
        const probes = await apiClient.getTPOMProbes({
          eventId: eventId,
          limit: 20,
        });

        set(
          produce((s: DraftState) => {
            s.prediction.probes.items.set(eventId, probes);
          }),
        );
      },
      loadLastProbes: async (eventId) => {
        const probes = await apiClient.getTPOMProbes({
          eventId: eventId,
          since: moment().subtract(5, 'minutes').toDate(),
        });

        set(
          produce((s: DraftState) => {
            s.prediction.probes.items.set(eventId, probes);
          }),
        );
      },
      loadShadowtrack: async (eventId) => {
        try {
          // TODO: load here later directly the splits!
          // const event = get().events.getter.currentEvent();
          // const simpleShadowtrack = get().events.getter.shadowtrack();
          // const splits = simpleShadowtrack?.properties.splits || [];
          const shadowtrackSimple = (await apiClient.getEventShadowGeo(eventId)).features[0];
          const shadowtrackCoordinatesChunked = await apiClient
            .asAnonymous()
            .getEventChunkedShadowGeo(eventId);
          if (shadowtrackSimple == null || shadowtrackCoordinatesChunked == null) return;

          const shadowtrack: Shadowtrack = {
            type: 'Feature',
            id: shadowtrackSimple.id,
            geometry: {
              type: 'LineString',
              coordinates: shadowtrackCoordinatesChunked,
            },
            properties: {
              ...shadowtrackSimple.properties,
              name: 'Chunked Shadowtrack',
            },
          };

          set(
            produce((s: DraftState) => {
              s.prediction.chunkedShadowTrack = shadowtrack;
            }),
          );
        } catch (err) {
          if (err instanceof HTTPFetchError) return;
          console.error(err);
          // throw err
        }
      },
      setReadsFilter: (filterPatch: Partial<ReadsFilter>) => {
        set(
          produce((s: DraftState) => {
            s.prediction.readsFilter = { ...s.prediction.readsFilter, ...filterPatch };
          }),
        );
      },
    },
    getter: {
      lastProbe: (eventId) => {
        const probes = get().prediction.probes.items.get(eventId);
        if (probes == null || probes.length === 0) return null;

        const sortedProbes = [...probes].sort(
          (p1, p2) => new Date(p1.createdAt).getTime() - new Date(p2.createdAt).getTime(),
        );

        return sortedProbes[0];
      },
    },
  },
});

export const prepareRead = (read: Ping): PreparedRead => {
  return {
    readerId: read.boxId,
    userId: read.userId,
    customerId: read.customerId,
    pingType: read.pingType,
    count: read.hits,
    dropped: [],
    rssi: read.rssi || -300,
    transponderId: read.transponderId || '',
    receivedAt: new Date(read.receivedAt),
    timestamp: new Date(read.timestamp),
    wasUserDefined: read.wasUserDefined,
    timingName: read.timingName,
    location:
      read.lat != null && read.lng != null
        ? {
            state: read.pos_state,
            lat: read.lat,
            lng: read.lng,
            elv: read.elv,
          }
        : undefined,
  };
};
