export const OneSecondInMillis = 1000;

export const OneMinuteInMillis = 60 * OneSecondInMillis;

export const OneHourInMillis = 60 * OneMinuteInMillis;

export const OneDayInMillis = 24 * OneHourInMillis;

export const OneWeekInMillis = 7 * OneDayInMillis;

export const OneMonthInMillis = 30 * OneDayInMillis;

export const OneYearInMillis = 365 * OneDayInMillis;
