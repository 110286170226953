import { RacemapV2APIClient } from '@racemap/sdk';
import {
  BillableItem,
  BillableItemPrototype,
  EventDayBasedBillableItem,
} from '@racemap/sdk/schema/billing';
import { BillableItemTypes } from '@racemap/sdk/schema/user';
import { EventsQuery, HTTPFetchError, RacemapAPIClient } from '@racemap/utilities/api-client';
import { SportTypes, eventTypesByType } from '@racemap/utilities/consts/eventTypes';
import { AddOns, DeviceClasses, EventTypes } from '@racemap/utilities/consts/events';
import {
  formatNestedObject,
  formatTimeDuration,
  parseNestedObject,
  parseTagObjectLegacy,
  timeDurationToMilliseconds,
} from '@racemap/utilities/formatting';
import { EventCycle } from '@racemap/utilities/functions/billing';
import { createPatchObj } from '@racemap/utilities/functions/createPatchObj';
import { getSplitsOfTrack } from '@racemap/utilities/functions/event';
import { isGroupEvent } from '@racemap/utilities/functions/utils';
import { validateStarterAppId } from '@racemap/utilities/functions/validateStarterAppId';
import { isSamePointOnLineString } from '@racemap/utilities/functions/validation';
import { moveArrayEntry } from '@racemap/utilities/math';
import { Brand } from '@racemap/utilities/types/brand';
import { GroupEvent, RacemapEvent, RacemapStarter } from '@racemap/utilities/types/events';
import {
  EventTrackObject,
  GeoElementObject,
  GeoObjects,
  LayerObject,
  POIObject,
  RacemapGeoTypes,
  SplitObject,
} from '@racemap/utilities/types/geos';
import { User, User_Legacy } from '@racemap/utilities/types/users';
import { ID, PathInto, TypeOfPath } from '@racemap/utilities/types/utils';
import { Immutable, produce } from 'immer';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { StoreApi } from 'zustand';
import { Tristate } from '../../components/ButtonWithSpinner';
import { DraftState, State } from '../reducers';
import {
  eventIsLocked,
  needsAConfirmation,
  prepareFrontendBillableItems,
  presentConfirmationDialog,
  presentEventLockedDialog,
} from './events_reducer_helper';

const apiClient = RacemapAPIClient.fromWindowLocation();
const apiClientV2 = RacemapV2APIClient.fromWindowLocation();

export interface CurrentEvent extends Omit<RacemapEvent, 'editors' | 'creator'> {
  childEvents: Array<RacemapEvent>;
  creator: User_Legacy;
  editors: Map<string, User_Legacy>;
  billableItems: Array<Immutable<FrontendBillableItem>>;
}

export enum FrontendBillableItemTypes {
  EVENT_CREATED = 'event_created',
}

export interface FrontendBillableItem extends Omit<BillableItem, 'type'> {
  startNewCycle?: boolean;
  cycle?: EventCycle;
  type: BillableItemTypes | FrontendBillableItemTypes;
}
export interface FrontendEventDayBasedBillableItem extends EventDayBasedBillableItem {
  activatedAddons: Array<AddOns>;
  startNewCycle: boolean;
  cycle: EventCycle;
}

export interface EventsState {
  events: {
    items: Map<string, RacemapEvent | CurrentEvent>;
    currentEventId: null | string;
    savingState: Tristate;
    isLoading: boolean;
    isReady: boolean;
    showPaymentModal: boolean;
    getter: {
      currentEvent: () => Immutable<CurrentEvent> | null;
      childEvents: (parentId?: ID) => Array<Immutable<RacemapEvent>>;
      parentEvent: (parentId?: ID) => Immutable<GroupEvent> | null;
      connectedBrands: (eventId?: ID) => Array<Immutable<Brand>>;
      rrEventID: (eventId?: ID) => string | null;
    };
    actions: {
      loadEvents: (options: EventsQuery) => Promise<void>;
      loadEvent: (eventId: ID) => Promise<RacemapEvent>;
      loadChildEvents: (parentId: ID, options?: EventsQuery) => Promise<void>;
      loadEventsPaged: (
        pageSize: number,
        searchValue: string,
        parentsOnly?: boolean,
        lastKey?: Date,
      ) => Promise<Date>;
      addEvent: (newEvent: Partial<RacemapEvent>) => Promise<RacemapEvent | undefined>;
      loadCurrentEvent: (eventId: string) => Promise<RacemapEvent>;
      loadCurrentStarters: (eventId: string) => Promise<void>;
      clearCurrentEvent: () => void;
      updateEvent: <P extends PathInto<RacemapEvent | CurrentEvent>>(
        eventId: string,
        update: Array<{ key: P; newValue: TypeOfPath<RacemapEvent | Immutable<RacemapEvent>, P> }>,
      ) => Promise<void>;
      deleteEvent: (eventId: string) => Promise<void>;
      addStarters: (eventId: string, newStarter: Array<Partial<RacemapStarter>>) => Promise<void>;
      importStartersCSVFile: (eventId: string, file: File) => Promise<void>;
      changeStarterPosition: (
        eventId: string,
        currentPosition: number,
        newPosition: number,
      ) => Promise<void>;
      updateStarter: (newStarter: RacemapStarter, eventId: ID | null) => Promise<void>;
      deleteStarters: (eventId: string, starterIds: Array<string>) => Promise<void>;
      updateGeoElements: (newElements: Immutable<GeoObjects>) => Promise<void>;
      updateGeoElement: (newElement: Partial<GeoElementObject>) => Promise<void>;
      createGeoElement: (newElement: Partial<GeoElementObject>) => Promise<void>;
      deleteGeoElement: (element: GeoElementObject) => Promise<void>;
      generateStarterKeys: (eventId: string, count: number) => Promise<void>;
      changeShadowtrack: (eventId: string, shadowtrackId: string | null) => Promise<void>;
      createBillableItem: (eventId: string, item: BillableItemPrototype) => Promise<void>;
      removeBillableItem: (eventId: string, itemId: string) => Promise<void>;

      openPaymentModal: () => void;
      closePaymentModal: () => void;
    };
  };
}

export const createEventStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): EventsState => ({
  events: {
    items: new Map(),
    isReady: false,
    isLoading: false,
    currentEventId: null,
    isSaving: Tristate.NORMAL,
    showPaymentModal: false,
    getter: {
      currentEvent: () => {
        const s = get();
        const eventId = s.events.currentEventId;
        if (eventId == null) return null;
        return (s.events.items.get(eventId) as CurrentEvent) || null;
      },
      childEvents: (parentId) => {
        const s = get();
        const compId = parentId != null ? parentId : s.events.currentEventId;

        return Array.from(s.events.items.values()).filter(
          (e) => e.parent != null && e.parent === compId,
        );
      },
      parentEvent: (parentId) => {
        const s = get();
        let compId: string | undefined = parentId;
        if (parentId == null) {
          const currentEvent = s.events.getter.currentEvent();
          compId = currentEvent?.parent || undefined;
        }

        if (compId == null) return null;
        const parentEvent = s.events.items.get(compId) || null;
        if (parentEvent == null)
          throw new Error(`Found no parent event for the setted parent id: ${compId}!`);
        if (!isGroupEvent(parentEvent))
          throw new Error(
            `The event ${compId} should be an group event, but has the type ${parentEvent.type}!`,
          );

        return parentEvent;
      },
      connectedBrands: (eventId) => {
        if (eventId == null) return [];
        const s = get();
        const event = s.events.items.get(eventId);
        if (!event) return [];

        return Array.from(s.brands.items.values()).filter((b) =>
          event.modules.brandedAppIds.includes(b.id),
        );
      },
      rrEventID: (eventId) => {
        let event: Immutable<RacemapEvent | CurrentEvent> | null = null;
        if (eventId == null) {
          event = get().events.getter.currentEvent();
        } else {
          event = get().events.items.get(eventId) || null;
        }

        if (event == null) return null;
        if (event.type === EventTypes.STAGE && event.parent != null) {
          // stages use the race result event id of the group
          const parentEvent = get().events.getter.parentEvent(event.parent);
          return parentEvent?.integrations.raceresult.eventId || null;
        }

        return event.integrations.raceresult.eventId;
      },
    },
    actions: {
      loadEvents: async ({ filter = 'can-edit', show = ['hidden'], ...options }) => {
        const events = await apiClient.getEvents({
          filter,
          show,
          ...options,
        });

        set(
          produce((s) => {
            s.events.isLoading = true;

            for (const event of events) {
              s.events.items.set(event.id, event);
            }

            s.events.isLoading = false;
            s.events.isReady = true;
          }),
        );
      },
      loadEvent: async (eventId) => {
        const event = await apiClient.getEvent(eventId);

        set(
          produce((s) => {
            s.events.items.set(eventId, event);
          }),
        );
        return event;
      },
      loadChildEvents: async (parentId, { filter = 'can-edit', show = ['hidden'] } = {}) => {
        const childEvents = await apiClient.getEvents({
          filter,
          show,
          findByParentId: parentId,
        });

        set(
          produce((s) => {
            for (const event of childEvents) {
              s.events.items.set(event.id, event);
            }
          }),
        );
      },
      loadEventsPaged: async (pageSize, searchValue, parentsOnly, lastKey) => {
        const { data: events, lastKey: newLastKey } = await apiClient.getEventsPaged(
          {
            filter: 'can-edit',
            show: parentsOnly ? ['hidden', 'parentsonly'] : ['hidden'],
            findByPartOfNameOrLocation:
              searchValue != null && searchValue !== '' ? searchValue : undefined,
          },
          pageSize,
          lastKey,
        );

        set(
          produce((s) => {
            s.events.isLoading = true;
            for (const event of events) {
              s.events.items.set(event.id, event);
            }
            s.events.isLoading = false;
            s.events.isReady = true;
          }),
        );

        return newLastKey;
      },
      loadCurrentEvent: async (eventId) => {
        const [event, starters, geos, keys, billableItems] = await Promise.all([
          apiClient.getEvent(eventId),
          apiClient.getEventStarters(eventId, { noFilter: true }),
          apiClient.getEventGeo(eventId),
          apiClient.getEventStarterKeys(eventId),
          apiClient.getBillableItems(eventId),
        ]);

        let childEvents: Array<RacemapEvent> = [];
        if (event.type === EventTypes.STAGE_GROUP) {
          childEvents = await apiClient.getStageGroupStages(event.id);
        } else if (event.type === EventTypes.CONTEST_GROUP) {
          childEvents = await apiClient.getContestGroupContests(event.id);
        }

        if (
          (event.type === EventTypes.CONTEST || event.type === EventTypes.STAGE) &&
          event.parent != null
        ) {
          await get().events.actions.loadEvent(event.parent);
        }

        // append billing cycle infos to billable items
        const preparedBillableItems = prepareFrontendBillableItems(billableItems, event);
        const currentEvent: CurrentEvent = {
          ...event,
          keys,
          starters: new Map(starters.map((starter) => [starter.id, starter])),
          geo: {
            ...event.geo,
            features: geos.features,
            shadowtrackId: event.geo?.shadowtrackId || null,
          },
          editors: new Map(event.editors.map((editor) => [editor.id, editor])),
          billableItems: preparedBillableItems,
          childEvents,
        };

        set(
          produce((s) => {
            s.events.currentEventId = event.id;
            s.events.items.set(event.id, currentEvent);
            for (const child of childEvents) {
              s.events.items.set(child.id, child);
            }
          }),
        );
        return event;
      },
      loadCurrentStarters: async (eventId) => {
        const [starters, keys] = await Promise.all([
          apiClient.getEventStarters(eventId, { noFilter: true }),
          apiClient.getEventStarterKeys(eventId),
        ]);

        let currentEventState = get().events.items.get(eventId);
        if (currentEventState == null) {
          currentEventState = await get().events.actions.loadEvent(eventId);
        }

        if (currentEventState == null) {
          throw new Error(`Fail Event Update. Event with eventId ${eventId} is unknown.`);
        }

        set(
          produce((s) => {
            s.events.currentEventId = currentEventState.id;
            s.events.items.set(currentEventState.id, {
              ...currentEventState,
              starters: new Map(starters.map((starter) => [starter.id, starter])),
              keys,
            });
          }),
        );
      },
      clearCurrentEvent: () => {
        set(
          produce((s) => {
            s.events.currentEventId = null;
            s.events.savingState = Tristate.NORMAL;
          }),
        );
      },
      addEvent: async (event) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          const createdEvent = await apiClient.createEvent(event);
          set(
            produce((s) => {
              s.events.items.set(createdEvent.id, createdEvent);
              s.events.savingState = Tristate.NORMAL;
            }),
          );

          return createdEvent;
        } catch (error) {
          set(
            produce((s) => {
              s.events.isSaving = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to add a event: ${error}`);
          toast.error('The attempt to add the event failed.');
        }
      },
      updateEvent: async (eventId, updates) => {
        let currentEventState = get().events.items.get(eventId);
        try {
          if (currentEventState == null) {
            currentEventState = await get().events.actions.loadEvent(eventId);
          }

          if (currentEventState == null) {
            throw new Error(`Fail Event Update. Event with eventId ${eventId} is unknown.`);
          }

          const eventIdOfCurrentEvent = get().events.currentEventId;
          const isCurrent = eventId === eventIdOfCurrentEvent;
          const isChildOfCurrent = currentEventState.parent === eventIdOfCurrentEvent;

          // create the patch object, that only consist with the updates
          const protectedFields = [
            'id',
            'createdAt',
            'updatedAt',
            'editors',
            'keys',
            'creator',
            'billableItems',
            'childEvents',
          ];
          const patchObj = createPatchObj(
            updates,
            currentEventState as RacemapEvent,
            protectedFields,
          );

          if (patchObj.eventType != null) {
            patchObj.maxSpeed = eventTypesByType[patchObj.eventType].maxSpeed;
          }

          // Optimistic update
          set(
            produce((s: DraftState) => {
              s.events.savingState = Tristate.SPINNING;
              const currentEvent = s.events.items.get(currentEventState?.id || '');

              if (currentEvent != null && (isCurrent || isChildOfCurrent)) {
                s.events.items.set(currentEvent.id, {
                  ...currentEvent,
                  ...(patchObj as RacemapEvent),
                });
              }
            }),
          );

          if (isCurrent && eventIsLocked(patchObj, currentEventState)) {
            await presentEventLockedDialog(currentEventState);
            return;
          }

          if (isCurrent && needsAConfirmation(patchObj, currentEventState)) {
            await presentConfirmationDialog(patchObj, currentEventState);
          }

          await apiClient.updateEvent(eventId, patchObj);

          // if the sport of the event change and we have 2 splits (start and finish)
          // and the first split has no sport then we update the first split with the
          // new sport and min/max speed
          if (patchObj.eventType != null) {
            const splits = getSplitsOfTrack(currentEventState);

            if (
              splits != null &&
              splits.length === 2 &&
              splits[0].properties.minSpeed === 0.1 &&
              splits[0].properties.maxSpeed === 360
            ) {
              const sport = eventTypesByType[patchObj.eventType as SportTypes];
              if (sport != null) {
                await apiClient.updateGeoPoint(splits[0].id, {
                  ...splits[0],
                  properties: {
                    ...splits[0].properties,
                    newSport: patchObj.eventType,
                    minSpeed: sport.minSpeed,
                    maxSpeed: sport.maxSpeed,
                  },
                });
              }
            }
          }

          // reload current Event
          if (eventIdOfCurrentEvent != null && (isCurrent || isChildOfCurrent)) {
            await get().events.actions.loadCurrentEvent(eventIdOfCurrentEvent);
          }

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          const userCancelUpdate = error instanceof Error && error.message === 'CANCEL';
          const eventIsLocked = error instanceof Error && error.message === 'LOCKED';

          if (userCancelUpdate || eventIsLocked) {
            set(
              produce((s) => {
                if (currentEventState != null)
                  s.events.items.set(currentEventState.id, currentEventState);
                s.events.savingState = Tristate.NORMAL;
              }),
            );

            if (userCancelUpdate)
              toast.warn('The confirmation was declined. The changes were not saved.');
            if (eventIsLocked)
              toast.warn('The event times are locked. The changes were not saved.');
            return;
          }

          set(
            produce((s) => {
              if (currentEventState != null)
                s.events.items.set(currentEventState.id, currentEventState);
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.error(`Failed to change a event: ${eventId}`);
          console.error(error);
          toast.error(`The attempt to update the event ${eventId} failed.`);
        }
      },
      deleteEvent: async (eventId) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          await apiClient.removeEvent(eventId);

          set(
            produce((s) => {
              s.events.items.delete(eventId);
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to remove a event: ${error}`);
          toast.error(`The attempt to delete the event ${eventId} failed.`);
        }
      },
      addStarters: async (eventId, newStarters) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          await apiClient.createStarters(newStarters, eventId);

          if (get().events.currentEventId === eventId) {
            const starters = await apiClient.getEventStarters(eventId, { noFilter: true });

            set(
              produce((s) => {
                const event = s.events.items.get(eventId);

                s.events.items.set(eventId, {
                  ...event,
                  starters,
                });
              }),
            );

            get().events.actions.loadCurrentEvent(eventId);
          }

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to add a starter: ${error}`);
          toast.error(
            `The attempt to add the starter ${newStarters.map((s) => s.name).join('/')} failed.`,
          );
        }
      },
      importStartersCSVFile: async (eventId, file) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          const rawStarters = await parseStartersCSVFile(file);
          validateRawStarters(rawStarters);
          await get().events.actions.addStarters(eventId, rawStarters);

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log('Failed to add a starter');
          console.error(error);
          toast.error(`The attempt to import the starters failed: ${error}`);
        }
      },
      changeStarterPosition: async (eventId, currentPosition, newPosition) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          const events = get().events;
          const event = events.items.get(eventId);
          if (event == null) throw new Error(`Event with id ${eventId} not found!`);

          const starterIds =
            event.starters != null
              ? Array.from(event.starters.values()).map((s) => s.id)
              : await apiClient.getEventStarters(eventId, { noFilter: true });
          const newStarterIds = moveArrayEntry(starterIds, currentPosition, newPosition);
          await events.actions.updateEvent(eventId, [
            { key: 'starterIds', newValue: newStarterIds },
          ]);

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to move a starter: ${error}`);
          toast.error('The attempt to move the starter failed.');
        }
      },
      updateStarter: async (starterUpdate, eventId = null) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          const newStarter = await apiClient.updateStarter(starterUpdate.id, starterUpdate, {
            query: { force: true },
          });

          if (eventId != null && get().events.currentEventId === eventId) {
            set(
              produce((s) => {
                const event = s.events.items.get(eventId);
                event.starters.set(newStarter.id, newStarter);

                s.events.items.set(eventId, event);
              }),
            );
            get().events.actions.loadCurrentEvent(eventId);
          }

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );

          if (!(error instanceof Error)) return;
          switch (error.constructor) {
            case HTTPFetchError: {
              const fetchError = error as HTTPFetchError;

              console.error(`Failed to update a starter: ${fetchError.message}`);
              if (fetchError.serverError != null) console.error(fetchError.serverError);
              toast.error(
                `The attempt to update the starter ${
                  starterUpdate.name || starterUpdate.appId || starterUpdate.id
                } failed. Error: ${
                  fetchError.serverError != null ? fetchError.serverError : 'UNKNOWN'
                }`,
              );
              return;
            }
            default:
              console.error(error);
              toast.error(
                `The attempt to update the starter ${
                  starterUpdate.name || starterUpdate.appId || starterUpdate.id
                } failed. Error: UNKNOWN`,
              );
              return;
          }
        }
      },
      deleteStarters: async (eventId: string, starterIds: Array<string>) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          await apiClient.removeStarters(starterIds, eventId);

          if (get().events.currentEventId === eventId) {
            const starters = await apiClient.getEventStarters(eventId, { noFilter: true });

            set(
              produce((s) => {
                const event = s.events.items.get(eventId);

                s.events.items.set(eventId, {
                  ...event,
                  starters,
                });
              }),
            );

            get().events.actions.loadCurrentEvent(eventId);
          }

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to add a starter: ${error}`);
          toast.error(
            `The attempt to add the starter ${newStarters.map((s) => s.name).join('/')} failed.`,
          );
        }
      },
      updateGeoElements: async (newFeatures) => {
        for (const feature of newFeatures) {
          try {
            const { id, properties } = feature;
            const { eventId } = properties || {};
            if (id == null || properties == null || eventId == null)
              throw new Error('Only can update geo elements with an id and properties!');

            const event = get().events.items.get(eventId);
            const existingElement = event?.geo.features?.find((f) => f.id === id);
            const existingPointOnLineString = event?.geo.features?.find((f) =>
              isSamePointOnLineString(f, feature),
            );

            if (existingElement != null) {
              await get().events.actions.updateGeoElement(feature);
            } else if (existingPointOnLineString != null) {
              await get().events.actions.updateGeoElement({
                ...existingPointOnLineString,
                properties: {
                  ...existingPointOnLineString.properties,
                  ...feature.properties,
                },
              });
            } else {
              await get().events.actions.createGeoElement(feature);
            }
          } catch (error) {
            if (
              error instanceof HTTPFetchError &&
              error.serverError === 'There is already a point on this coordinate index'
            ) {
              console.warn('Skipping duplicated point on linestring');
              continue;
            }

            console.error(error);
            toast.error(`Failed to update the geo element ${feature.id}`);
          }
        }
      },
      updateGeoElement: async (newElement) => {
        const { id, properties } = newElement;
        const { eventId } = properties || {};
        const isCurrentEvent = eventId === get().events.currentEventId;
        let oldFeature = null;

        if (isCurrentEvent) {
          set(
            produce((s: DraftState) => {
              s.events.savingState = Tristate.SPINNING;

              const event = s.events.items.get(eventId);
              const features = event?.geo.features;
              const featureIndex = features?.findIndex((f) => f.id === id);

              if (Array.isArray(features) && featureIndex != null && featureIndex > -1) {
                console.log('Updating feature');
                oldFeature = features?.[featureIndex];
                features[featureIndex] = { ...oldFeature, ...newElement };
              }
            }),
          );
        }

        try {
          if (id == null || properties == null || eventId == null)
            throw new Error('Only can update geo elements with an id and properties!');

          const racemapType = properties.racemapType;

          switch (racemapType) {
            case RacemapGeoTypes.TRACK:
              await apiClient.updateGeoLineString(id, newElement as EventTrackObject);
              break;
            case RacemapGeoTypes.POI:
            case RacemapGeoTypes.SPLIT:
              await apiClient.updateGeoPoint(id, newElement as SplitObject | POIObject);
              break;
            case RacemapGeoTypes.EXTERNAL_LAYER:
              await apiClient.updateGeoLayer(id, newElement as LayerObject);
              break;
            default:
              throw new Error('Only can update geo elements of known types.');
          }

          if (isCurrentEvent) {
            await get().events.actions.loadCurrentEvent(eventId);
          }
          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          if (error instanceof Error) {
            toast.error('Failed to update a geo element: ');
            console.error('Failed to update a geo element: ', error.message);

            if (error instanceof HTTPFetchError) {
              console.error(error.serverError);
            } else {
              console.error(error);
            }
          }

          if (isCurrentEvent) {
            await get().events.actions.loadCurrentEvent(eventId);
          }
        }
      },
      createGeoElement: async (newElement) => {
        const { properties } = newElement;
        const { eventId } = properties || {};
        if (properties == null || eventId == null)
          throw new Error('Only can update geo elements with an id and properties!');

        const racemapType = properties.racemapType;
        const isCurrentEvent = eventId === get().events.currentEventId;

        switch (racemapType) {
          case RacemapGeoTypes.TRACK:
            await apiClient.createGeoLineString(newElement as EventTrackObject);
            break;
          case RacemapGeoTypes.POI:
          case RacemapGeoTypes.SPLIT:
            await apiClient.createGeoPoint(newElement as SplitObject | POIObject);
            break;
          case RacemapGeoTypes.EXTERNAL_LAYER:
            await apiClient.createGeoLayer(newElement as LayerObject);
            break;
          default:
            throw new Error('Only can update geo elements of known types.');
        }

        if (isCurrentEvent) {
          await get().events.actions.loadCurrentEvent(eventId);
        }
      },
      deleteGeoElement: async (element) => {
        const { id, properties } = element;
        const { eventId } = properties || {};
        if (id == null || properties == null || eventId == null)
          throw new Error('Only can update geo elements with an id and properties!');

        const racemapType = properties.racemapType;
        const isCurrentEvent = eventId === get().events.currentEventId;
        const features = get().events.items.get(eventId)?.geo?.features || [];

        set(
          produce((s) => {
            if (isCurrentEvent) {
              const event = s.events.items.get(eventId);
              event.geo.features = features.filter((f) => f.id !== id);
            }
          }),
        );

        try {
          switch (racemapType) {
            case RacemapGeoTypes.TRACK:
              await apiClient.removeGeoLineString(id);
              break;
            case RacemapGeoTypes.POI:
            case RacemapGeoTypes.SPLIT:
              await apiClient.removeGeoPoint(id);
              break;
            case RacemapGeoTypes.EXTERNAL_LAYER:
              await apiClient.removeGeoLayer(id);
              break;
            default:
              throw new Error('Only can remove geo elements of known types.');
          }
        } catch (error) {
          console.log(error);
          toast.error('Failed to remove the track from the map.');
          set(
            produce((s) => {
              if (isCurrentEvent) {
                const event = s.events.items.get(eventId);
                event.geo.features = features;
              }
            }),
          );
        }

        if (isCurrentEvent) {
          await get().events.actions.loadCurrentEvent(eventId);
        }
      },
      generateStarterKeys: async (eventId, count) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          const keys = await apiClient.createEventStarterKeys(eventId, count);

          if (get().events.currentEventId === eventId) {
            set(
              produce((s) => {
                const event = s.events.items.get(eventId);

                s.events.items.set(eventId, {
                  ...event,
                  keys,
                });
              }),
            );

            get().events.actions.loadCurrentEvent(eventId);
          }

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to create keys for starter: ${error}`);
          toast.error('Failed to create keys for starter.');
        }
      },

      changeShadowtrack: async (eventId, shadowtrackId) => {
        const event = get().events.items.get(eventId);
        const isCurrentEvent = eventId === get().events.currentEventId;
        if (event == null) return;

        if (event.geo == null)
          throw new Error('Only can update the shadowtrack of the current event.');

        get().events.actions.updateEvent(eventId, [
          {
            key: 'geo',
            newValue: { ...event.geo, shadowtrackId },
          },
        ]);

        if (isCurrentEvent) {
          await get().events.actions.loadCurrentEvent(eventId);
        }
      },

      createBillableItem: async (eventId, item) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          await apiClientV2.createBillableItem(item);

          if (get().events.currentEventId === eventId) {
            const billableItems = await apiClient.getBillableItems(eventId);

            set(
              produce((s) => {
                const event = s.events.items.get(eventId);

                s.events.items.set(eventId, {
                  ...event,
                  billableItems: prepareFrontendBillableItems(billableItems, event),
                });
              }),
            );

            get().events.actions.loadCurrentEvent(eventId);
          }

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to add the billable item: ${error}`);
          toast.error('Failed to add the billable item.');
        }
      },

      removeBillableItem: async (eventId, itemId) => {
        try {
          set(
            produce((s) => {
              s.events.savingState = Tristate.SPINNING;
            }),
          );
          await apiClientV2.removeBillableItem(undefined, { params: { billableItemId: itemId } });

          if (get().events.currentEventId === eventId) {
            const billableItems = await apiClient.getBillableItems(eventId);

            set(
              produce((s) => {
                const event = s.events.items.get(eventId);

                s.events.items.set(eventId, {
                  ...event,
                  billableItems: prepareFrontendBillableItems(billableItems, event),
                });
              }),
            );

            get().events.actions.loadCurrentEvent(eventId);
          }

          set(
            produce((s) => {
              s.events.savingState = Tristate.NORMAL;
            }),
          );
        } catch (error) {
          set(
            produce((s) => {
              s.events.savingState = Tristate.FAILURE;
            }),
          );
          console.log(`Failed to remove the billable item: ${error}`);
          toast.error('Failed to remove the billable item.');
        }
      },

      openPaymentModal: () => {
        set(
          produce((s: DraftState) => {
            s.events.showPaymentModal = true;
          }),
        );
      },
      closePaymentModal: () => {
        set(
          produce((s: DraftState) => {
            s.events.showPaymentModal = false;
          }),
        );
      },
    },
  },
});

type StarterCSV = Array<string>;

function parseStartersCSVFile(file: File): Promise<Array<Partial<RacemapStarter>>> {
  return new Promise((resolve, reject) => {
    Papa.parse<StarterCSV>(file, {
      skipEmptyLines: true,
      complete(results) {
        // Here we have an csv with at least one data-row and 2 data-columns
        // Format has to be:
        //  startnumber;name;imei;device_type;device_class;marker_color
        //  12;Neppi;4106012717;LK106;Tracker;#fff
        //  24;Peter;4106012718;LK106;Tracker;#fff

        if (results.errors.length > 0) {
          reject(results.errors);
          return;
        }

        if (results.data.length <= 1) {
          reject(new Error('Too few rows'));
          return;
        }

        if (results.data[0].length < 2) {
          reject(new Error('Too few columns'));
          return;
        }

        const headerRow = results.data[0].map((h) => h.trim());

        const columnMapping: Record<string, number> = {};
        const headers = [
          'starter_id',
          'startnumber',
          'name',
          'imei',
          'device_type',
          'device_class',
          'device_id',
          'marker_color',
          'tags', // legacy, use the nested object collumns format instead
          'startTime',
          'endTime',
          'manualResult',
          'key',
        ];
        for (const header of headers) {
          const index = headerRow.indexOf(header);
          if (index < 0) continue;

          columnMapping[header] = index;
        }

        const nestedObjectsMapping: Record<string, Record<string, number>> = {};
        const nestedObjectHeadersPrefix = ['times', 'tags'];
        for (const headerPrefix of nestedObjectHeadersPrefix) {
          for (const csvHeader of headerRow) {
            if (
              csvHeader.startsWith(`${headerPrefix}_`) ||
              csvHeader.startsWith(`${headerPrefix}.`)
            ) {
              if (nestedObjectsMapping[headerPrefix] == null)
                nestedObjectsMapping[headerPrefix] = {};
              const index = headerRow.indexOf(csvHeader);

              nestedObjectsMapping[headerPrefix][csvHeader] = index;
            }
          }
        }

        for (const col of ['startnumber', 'name']) {
          if (columnMapping[col] == null) {
            reject(new Error(`Column ${col} missing.`));
            return;
          }
        }

        if (columnMapping.tags != null && nestedObjectsMapping.tags != null) {
          throw new Error(
            'You cant use the old and the new format to store the tags in the csv. Remove the legacy format and try again.',
          );
        }

        resolve(
          results.data.slice(1).map((row) => ({
            id: getValueOfRow(row, columnMapping.starter_id),
            startNumber: getValueOfRow(row, columnMapping.startnumber),
            name: getValueOfRow(row, columnMapping.name),
            appId: getValueOfRow(row, columnMapping.imei),
            deviceType: getValueOfRow(row, columnMapping.device_type),
            deviceClass:
              (getValueOfRow(row, columnMapping.device_class) as DeviceClasses) || undefined,
            markerColor: getValueOfRow(row, columnMapping.marker_color),
            tags: {
              ...parseTagObjectLegacy(getValueOfRow(row, columnMapping.tags) || ''),
              ...parseNestedObject(row, nestedObjectsMapping.tags || {}),
            },
            startTime: getValueOfRow(row, columnMapping.startTime),
            endTime: getValueOfRow(row, columnMapping.endTime),
            manualFinishDuration:
              getValueOfRow(row, columnMapping.manualResult) != null
                ? timeDurationToMilliseconds(getValueOfRow(row, columnMapping.manualResult) || '')
                : undefined,
            key: getValueOfRow(row, columnMapping.key),
            deviceId: getValueOfRow(row, columnMapping.device_id) || null,
            times: parseNestedObject(row, nestedObjectsMapping.times || {}),
          })),
        );
      },
    });
  });
}

function getValueOfRow(row: StarterCSV, index: number): string | undefined {
  const value = row[index];

  if (value == null) return undefined;
  return value.trim();
}

export function buildStartersCSVFile(
  starters: Array<RacemapStarter>,
  user: User,
  event: Immutable<RacemapEvent>,
) {
  let csvString =
    'startnumber;name;imei;device_type;device_class;marker_color;startTime;endTime;manualResult;key;starter_id;device_id;device_name';
  const possibleTags = Array.from(
    new Set(
      starters.reduce<Array<string>>((prev, s) => {
        return produce(prev, (draft) => {
          draft.push(...Array.from(Object.keys(s.tags)));
        });
      }, []),
    ),
  );
  if (possibleTags.length > 0) {
    csvString += `;tags.${possibleTags.join(';tags.')}`;
  }

  const possibleTimes = Array.from(
    new Set(
      starters.reduce<Array<string>>((prev, s) => {
        return produce(prev, (draft) => {
          draft.push(...Array.from(Object.keys(s.times)));
        });
      }, []),
    ),
  );
  if (possibleTimes.length > 0) {
    csvString += `;times.${possibleTimes.join(';times.')}`;
  }

  if (user.admin) {
    csvString += ';player_link';
  }

  for (const starter of starters) {
    let row = [
      starter.startNumber,
      starter.name,
      starter.appId,
      starter.deviceType,
      starter.deviceClass,
      starter.markerColor,
      starter.startTime,
      starter.endTime,
      formatTimeDuration(starter.manualFinishDuration),
      starter.key,
      starter.id,
      starter.deviceId,
      starter.deviceName,
    ];

    if (possibleTags.length > 0) {
      row.push(...formatNestedObject(starter.tags, possibleTags));
    }
    if (possibleTimes.length > 0) {
      row.push(...formatNestedObject(starter.times, possibleTimes));
    }

    if (user.admin) {
      row.push(
        `${window.location.protocol}//${window.location.host}/player/${event.slug}#selected=${starter.id}`,
      );
    }

    row = row.map((i) => (i == null ? '' : i));
    if (row.some((i) => i !== '')) {
      csvString += `\r\n${row.join(';').replace(/(\r\n|\n|\r)/gm, '')}`;
    }
  }

  return csvString;
}

function validateRawStarters(starters: Array<Partial<RacemapStarter>>): void {
  for (const starter of starters) {
    if (
      starter.deviceClass != null &&
      !Object.values(DeviceClasses).includes(starter.deviceClass)
    ) {
      throw new Error(
        `Participant ${
          starter.name || starter.appId || starter.id
        } has invalid device class. Possible devices classes are ${Object.values(
          DeviceClasses,
        ).join(',')}.`,
      );
    }

    if (starter.appId != null && !validateStarterAppId(starter.appId)) {
      throw new Error(
        `Participant ${
          starter.name || starter.appId || starter.id
        } has invalid app id. The app id has to be a valid IMEI, UUID or transponder id.`,
      );
    }
  }
}
