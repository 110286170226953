import { User } from '@racemap/sdk/schema/user';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { isNotEmptyString } from '@racemap/utilities/functions/utils';
import { RacemapEvent, StripeInvoiceInfo } from '@racemap/utilities/types/events';
import { CURRENT_TOS_VERSION, User_Legacy } from '@racemap/utilities/types/users';
import humanizeDuration from 'humanize-duration';
import { Immutable } from 'immer';
import { Duration } from 'luxon';
import { CurrentEvent } from '../../../store/events/events_reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();

export const isTOSAccepted = (user: Immutable<User_Legacy> | Immutable<User>): boolean => {
  return user.acceptedTOSVersion === CURRENT_TOS_VERSION;
};

export const isAddressValid = (user: Immutable<User_Legacy> | Immutable<User> | null): boolean => {
  if (user == null) return false;
  const checkout = user.checkout;
  if (checkout == null) return false;

  return (
    isNotEmptyString(checkout.address.line1) &&
    isNotEmptyString(checkout.address.city) &&
    isNotEmptyString(checkout.address.country)
    // postal codes exist in some countries but not everywhere
    // isNotEmptyString(checkout.address.postal_code)
  );
};

export enum UserRole {
  ADMIN = 'admin',
  RESELLER = 'reseller',
  CHILD = 'child',
  REGULAR = 'regular',
}

export enum ViewStates {
  ADDRESS = 'ADDRESS',
  OVERVIEW = 'OVERVIEW',
  STRIPE = 'STRIPE',
  DONE = 'DONE',
}

export const getUserRole = (
  user: Immutable<User_Legacy>,
  event: Immutable<CurrentEvent> | Immutable<RacemapEvent>,
): UserRole => {
  if (user.admin) return UserRole.ADMIN;
  if (user.isReseller) return UserRole.RESELLER;
  if (user.parentId === event.creatorId) return UserRole.CHILD;

  return UserRole.REGULAR;
};

export const checkPaymentStatus = async (eventId: string): Promise<StripeInvoiceInfo> => {
  return await apiClient.checkEventPayment(eventId);
};

interface PaymentOptions {
  autoCollect: boolean;
}

export const initPayment = async (
  event: Immutable<CurrentEvent>,
  { autoCollect }: PaymentOptions,
): Promise<StripeInvoiceInfo> => {
  return apiClient.initiateEventPayment(event.id, {
    autoCollect,
  });
};

export const getHumanReadableDuration = (duration: Duration): string => {
  return humanizeDuration(duration.toMillis(), {
    largest: 2,
    units: ['y', 'mo', 'd', 'h', 'm'],
    round: true,
    language: 'en',
  });
};
