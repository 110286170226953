import { Settings } from 'luxon';
import { createRoot } from 'react-dom/client';
import '../css/admin_css.scss';
import App from './app';

// Set the default locale to en-GB
Settings.defaultLocale = 'en-GB';

const container = document.getElementById('root');
if (container == null) throw new Error('Could not find root element');

const root = createRoot(container);
root.render(<App />);
