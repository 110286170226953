import { Document } from 'mongoose';
import { z } from 'zod';
import { DateSchema, ObjectId, ObjectIdHexStringSchema, ObjectIdSchema } from './base';
import { BillableItemTypes, UserSchema } from './user';

export const TimeKeyRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
export const TimeKeySchema = z.string().regex(TimeKeyRegex);

export enum EseyeInvoiceItemRefs {
  Service = 'Service',
  Data = 'Data',
  Activation = 'Activation',
  PartialService = 'partial_service',
  SMS = 'SMS',
  MTSMS = 'MTSMS',
  APISMSMT = 'APISMSMT',
  APISMSMO = 'APISMSMO',
  SMSDR = 'SMSDR',
}

export const EseyeInvoiceEntrySchema = z.object({
  itemRef: z.union([z.nativeEnum(EseyeInvoiceItemRefs), z.string()]),
  iccid: z.string(),
  packageId: z.string(),
  packageItemId: z.string(),
  startDate: DateSchema.optional(),
  endDate: DateSchema.optional(),
  quantity: z.number().optional(),
  currency: z.string().optional(),
  rate: z.number().optional(),
  amount: z.number().optional(),
  groupName: z.string(),
});

export enum ESEYE_PACKAGE_ITEM_IDS {
  ZONE_1_LEGACY = '38131',
  ZONE_1 = '215407',
  ZONE_2_LEGACY = '57032',
  ZONE_2 = '215410',
  ZONE_3_LEGACY = '45379',
  ZONE_3 = '215413',
  ZONE_4_LEGACY = '81692',
  MO_SMS_ZONE_1 = '85427',
  MT_SMS = '85430',
  API_MT_SMS = '85545',
  SMS_DR = '201942',
}

export type EventDayBasedBillableItemType =
  | BillableItemTypes.DATA_FEED
  | BillableItemTypes.EVENT_DAY
  | BillableItemTypes.SPONSOR
  | BillableItemTypes.MONITOR
  | BillableItemTypes.TIMING;

export type EseyeInvoiceEntry = z.infer<typeof EseyeInvoiceEntrySchema>;

export const EseyeInvoiceSchema = z.array(EseyeInvoiceEntrySchema);

export type EseyeInvoice = z.infer<typeof EseyeInvoiceSchema>;

export const DataUsageSchemaPerZone = z.record(z.nativeEnum(ESEYE_PACKAGE_ITEM_IDS), z.number());
export const DataUsagePerMonthSchema = z.record(TimeKeySchema, DataUsageSchemaPerZone);

export const EseyeReportSchema = z.object({
  users: z.array(UserSchema),
  dataUsages: z.record(ObjectIdHexStringSchema, DataUsagePerMonthSchema),
  errors: z.array(z.string()),
  groupMapping: z.record(z.string(), z.string()),
});

export type EseyeReport = z.infer<typeof EseyeReportSchema>;

export const BillableItemSchema = z.object({
  id: ObjectIdSchema,
  type: z.nativeEnum(BillableItemTypes),
  time: DateSchema,
  timeKey: TimeKeySchema.nullable().default(null),
  itemId: ObjectIdSchema.nullable(),
  quantity: z.number().optional(),
  userId: ObjectIdSchema.nullable(),
  eventId: ObjectIdSchema.nullable(),
  createdAt: DateSchema.optional(),
  updatedAt: DateSchema.optional(),
  activatedAddons: z.array(z.string()).optional(),
  createdByTest: z.boolean().optional(),
  metadata: z.record(z.string(), z.any()).optional(),
});

export type BillableItem = z.infer<typeof BillableItemSchema>;

export const BillableItemPrototypeSchema = BillableItemSchema.omit({
  id: true,
  updatedAt: true,
  createdAt: true,
});

export type BillableItemPrototype = z.infer<typeof BillableItemPrototypeSchema>;

export const BillableItemDocumentSchema = BillableItemSchema.omit({ id: true }).extend({
  _id: ObjectIdSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
});

export type BillableItemDocument = z.infer<typeof BillableItemDocumentSchema> & Document<ObjectId>;

export const DataUsageBillableItemSchema = BillableItemSchema.extend({
  type: z.union([
    z.literal(BillableItemTypes.DATA_USAGE_ZONE_1),
    z.literal(BillableItemTypes.DATA_USAGE_ZONE_2),
    z.literal(BillableItemTypes.DATA_USAGE_ZONE_3),
  ]),
  timeKey: TimeKeySchema,
  quantity: z.number(),
});

export type DataUsageBillableItem = z.infer<typeof DataUsageBillableItemSchema>;

export enum AddOns {
  TIMING = 'timing',
  MONITOR = 'monitor',
  SPONSOR = 'sponsor',
  API = 'dataFeed',
  PREDICTIVE = 'predictive',
  KEYS = 'passcode_keys',
  PARTICIPANTS = 'participants',
}

export const EventDayBasedBillableItemSchema = BillableItemSchema.extend({
  type: z.literal(BillableItemTypes.EVENT_DAY),
  timeKey: TimeKeySchema,
  eventId: ObjectIdSchema,
  activatedAddons: z.array(z.nativeEnum(AddOns)),
});

export type EventDayBasedBillableItem = z.infer<typeof EventDayBasedBillableItemSchema>;

export const FreeBillableItemSchema = BillableItemSchema.extend({
  type: z.union([
    z.literal(BillableItemTypes.FREE_TIMING),
    z.literal(BillableItemTypes.FREE_DATA_FEED),
    z.literal(BillableItemTypes.FREE_MONITOR),
    z.literal(BillableItemTypes.FREE_SPONSOR),
    z.literal(BillableItemTypes.FREE_EVENT_CYCLE),
  ]),
  eventId: ObjectIdSchema,
  quantity: z.number(),
});

export type FreeBillableItem = z.infer<typeof FreeBillableItemSchema>;

export type SubscriptionBillableItemTypes = Exclude<
  BillableItemTypes,
  | BillableItemTypes.ACTIVATE_EVENT
  | BillableItemTypes.BASE_PRICE
  | BillableItemTypes.EVENT_DAY
  | BillableItemTypes.FREE_DATA_FEED
  | BillableItemTypes.FREE_EVENT_CYCLE
  | BillableItemTypes.FREE_MONITOR
  | BillableItemTypes.FREE_SPONSOR
  | BillableItemTypes.FREE_TIMING
  | BillableItemTypes.EXTEND_EVENT
>;

export const subscriptionBillableItemTypes = Object.values(BillableItemTypes).filter(
  (i) => i !== BillableItemTypes.ACTIVATE_EVENT && i !== BillableItemTypes.BASE_PRICE,
) as Array<SubscriptionBillableItemTypes>;

export enum StripeProducts {
  // one usage product
  BASE_PRICE = 'base_price',

  // Event Products
  MAP = 'map',
  MAP_OR_MONITOR_LOAD = 'map_or_monitor_load',
  API_OR_LEADERBOARD_LOAD = 'api_or_leaderboard_load',
  GPS_DEVICE = 'gps_device',
  TRANSPONDER = 'transponder',
  KEY = 'key',
  SPONSOR_ADDON = 'sponsor_addon',
  TIMING_ADDON = 'timing_addon',
  MONITOR_ADDON = 'monitor_addon',
  API_ADDON = 'api_addon',

  // SIM Card Products
  DATA_USAGE = 'data_usage',
  SIM_CARD_ACTIVATION = 'sim_card_activation',

  // Trackermanagment Products
  TRACKER_MANAGEMENT = 'tracker_management',
  SMS = 'sms',

  // Custom Products
  CUSTOM_APP = 'custom_app',

  // legacy products
  DATA_USAGE_ZONE_1 = 'data_usage_zone_1',
  DATA_USAGE_ZONE_2 = 'data_usage_zone_2',
  DATA_USAGE_ZONE_3 = 'data_usage_zone_3',
  PAGE_VIEW = 'page_view',
  STARTER = 'starter',
}

export type TotalUsages = Partial<Record<StripeProducts, number>>;

export interface InvoiceSummary {
  total: number;
  vat: number | null;
  items: Array<{
    lineId: string;
    label: string | null;
    quantity: number | null;
    amount: number;
    unitPrice: number;
    lookupKey?: string | null;
    priceId?: string;
  }>;
  invoiceId: string;
  subscriptionId?: string;
  paid: boolean;
  urlPdf: string | null;
  urlInvoice: string | null;
  periodStart: string;
  periodEnd: string;
}

enum StripePaymentIntentStatus {
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCEEDED = 'succeeded',
}

export const AdminBillingReportItemSchema = z.object({
  invoiceId: z.string().optional(),
  userId: ObjectIdSchema.optional(),
  customerId: z.string().optional(),
  paymentId: z.string().optional(),
  paymentIntendStatus: z.nativeEnum(StripePaymentIntentStatus).optional(),
  priceId: z.string().optional(),
  priceLookupKey: z.string().optional(),
  productId: z.string().optional(),
  totalNet: z.number(),
  totalVat: z.number(),
  totalAmount: z.number(),
  invoiceAmount: z.number(),
  customerName: z.string(),
  customerEmail: z.string(),
  customerCompany: z.string().optional(),
  invoiceNumber: z.string(),
  createdAt: DateSchema,
  paidFees: z.number(),
  paidAmount: z.number().optional(),
  paidAt: DateSchema.nullable(),
  lineItemId: z.string().nullish(),
  itemProductName: z.string().optional(),
  itemPriceName: z.string().optional(),
  itemDescription: z.string(),
  itemQuantity: z.number(),
  eventId: z.union([ObjectIdSchema, z.array(ObjectIdSchema)]).optional(),
  eventDate: z.tuple([DateSchema, DateSchema]).optional(),
  eventName: z.string().optional(),
  wasRefunded: z.boolean(),
  wasPaidExternal: z.boolean(),
  gotDiscount: z.boolean(),
  pdfUrl: z.string().nullable(),
});

export type AdminBillingReportItem = z.infer<typeof AdminBillingReportItemSchema>;

export const AdminBillingReportSchema = z.array(AdminBillingReportItemSchema);

export type AdminBillingReport = z.infer<typeof AdminBillingReportSchema>;
