import { z } from 'zod';

export const QuestionSchema = z.string();
export type Question = z.infer<typeof QuestionSchema>;

export const AnswerSchema = z.object({
  answer: z
    .object({
      text: z.string(),
      sources: z.array(
        z.object({
          link: z.string(),
          title: z.string(),
          description: z.string(),
          type: z.literal('page'),
          id: z.string(),
        }),
      ),
      followupQuestions: z.array(z.string()),
    })
    .nullable(),
});
export type Answer = z.infer<typeof AnswerSchema>;

export const SearchResultsSchema = z.object({
  items: z.array(
    z.object({
      type: z.literal('page'),
      title: z.string(),
      description: z.string(),
      link: z.string(),
    }),
  ),
});
export type SearchResults = z.infer<typeof SearchResultsSchema>;
