const dbName = 'racemapDB';
const storeName = 'store';
let db: IDBDatabase | null = null;

export function openDB(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const database = (event.target as IDBOpenDBRequest).result;
      if (!database.objectStoreNames.contains(storeName)) {
        database.createObjectStore(storeName);
      }
    };

    request.onsuccess = (event: Event) => {
      db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.onerror = (event: Event) => {
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
}

export async function getItem<T>(key: string): Promise<T | null> {
  if (!db) {
    throw new Error('Database not initialized');
  }
  return new Promise((resolve, reject) => {
    const transaction = db?.transaction([storeName], 'readonly');
    const store = transaction?.objectStore(storeName);
    const request = store?.get(key);

    if (request) {
      request.onsuccess = () => {
        resolve(request.result as T);
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    }
  });
}

export async function setItem<T>(key: string, value: T): Promise<void> {
  if (!db) {
    throw new Error('Database not initialized');
  }
  return new Promise((resolve, reject) => {
    const transaction = db?.transaction([storeName], 'readwrite');
    const store = transaction?.objectStore(storeName);
    const request = store?.put(value, key);

    if (request) {
      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    }
  });
}

export async function deleteItem<T>(key: string): Promise<T | null> {
  if (!db) {
    throw new Error('Database not initialized');
  }
  return new Promise((resolve, reject) => {
    const transaction = db?.transaction([storeName], 'readwrite');
    const store = transaction?.objectStore(storeName);
    const request = store?.delete(key);

    if (request) {
      request.onsuccess = () => {
        resolve(request.result as T);
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    }
  });
}
