import TestSelectors from '@racemap/utilities/consts/test_selectors';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Alert } from 'react-bootstrap';

export interface Message {
  success?: string;
  error?: string;
}
interface Props {
  message: Message | null;
}

export const StateMessage: FC<Props> = ({ message }) => {
  if (message == null) return <></>;

  if (message.success) {
    return (
      <Alert
        variant="success"
        className={classNames(TestSelectors.utils.modals.alertSuccess.toString())}
      >
        {message.success}
      </Alert>
    );
  } else if (message.error) {
    return (
      <Alert
        variant="danger"
        className={classNames(TestSelectors.utils.modals.alertDanger.toString())}
      >
        {message.error}
      </Alert>
    );
  }

  return <></>;
};
