const selectorMap = new Map();
class Selector {
  _selector: string;
  _used: boolean;
  constructor(selector: string) {
    const prefixed = `test-${selector.toString().trim()}`;
    if (selectorMap.has(prefixed)) {
      throw new Error(`Selector "${prefixed}" already exist!`);
    } else {
      selectorMap.set(prefixed, this);
      this._selector = prefixed;
    }
    this._used = false;
  }

  asClassName() {
    this._used = true;
    return `.${this._selector}`;
  }

  asCSSId() {
    this._used = true;
    return `#${this._selector}`;
  }

  toString() {
    this._used = true;
    return this._selector;
  }
}

const TestSelectors = {
  login: {
    form: {
      email: new Selector('login-form-email-input'),
      password: new Selector('login-form-password-input'),
    },
    button: new Selector('login-action-button'),
  },
  register: {
    form: {
      email: new Selector('register-form-email-input'),
      name: new Selector('register-form-name-input'),
      password: new Selector('register-form-password-input'),
      repeatPassword: new Selector('register-form-repeat-password-input'),
      alertSuccess: new Selector('register-form-alert-success'),
    },
    terms: new Selector('register-terms-checkbox'),
    button: new Selector('register-action-button'),
  },
  admin: {
    auth: {
      buttons: {
        login: new Selector('auth-login-button'),
        register: new Selector('auth-register-button'),
      },
      inputs: {
        email: new Selector('auth-email-input'),
        password: new Selector('auth-password-input'),
      },
    },
    nav: {
      sections: {
        users: new Selector('nav-section-users-button'),
        events: new Selector('nav-section-events-button'),
        scripts: new Selector('nav-section-scripts-button'),
        billing: new Selector('nav-section-billing-button'),
        predictiveEmulation: new Selector('nav-section-predictiveEmulation-button'),
        statistics: new Selector('nav-section-statistics-button'),
        maintenance: new Selector('nav-section-maintenance-button'),
        monitoring: new Selector('nav-section-monitoring-button'),
        analysis: new Selector('nav-section-analysis-button'),
        messageTemplates: new Selector('nav-section-messageTemplates-button'),
        trackers: new Selector('nav-section-trackers-button'),
        sims: new Selector('nav-section-sims-button'),
      },
      dropdowns: {
        admin: new Selector('nav-dropdown-admin'),
        trackers: new Selector('nav-dropdown-trackers'),
      },
      buttons: {
        newEvent: new Selector('nav-new-event-button'),
      },
    },
    users: {
      heading: new Selector('admin-users-table-heading'),
    },
    adminEvents: {
      areas: {
        table: new Selector('events-area-table'),
        container: new Selector('events-area-container'),
      },
      table: {
        cols: {
          position: new Selector('events-table-col-position'),
          type: new Selector('events-table-col-type'),
          name: new Selector('events-table-col-name'),
          editors: new Selector('events-table-col-editors'),
          times: new Selector('events-table-col-times'),
          start: new Selector('events-table-col-start'),
          end: new Selector('events-table-col-end'),
          visibility: new Selector('events-table-col-visibility'),
          authorization: new Selector('events-table-col-authorization'),
        },
      },
    },
    trackers: {
      container: new Selector('trackers-container'),
      addTrackerModal: {
        viewButton: new Selector('trackers-addTrackerModal-viewButton'),
        typeSelect: new Selector('trackers-addTrackerModal-typeSelect'),
        nameInput: new Selector('trackers-addTrackerModal-nameInput'),
        idInput: new Selector('trackers-addTrackerModal-idInput'),
        addTrackerButton: new Selector('trackers-addTrackerModal-addTrackerButton'),
      },
    },
    scripts: {
      headline: new Selector('scripts-headline'),
    },
    predictiveEmulation: {
      areas: {
        events: new Selector('emulation-area-events'),
        map: new Selector('emulation-area-map'),
        emulation: new Selector('emulation-area-emulation'),
      },
    },
    eventEditor: {
      titleBar: {
        container: new Selector('eventEditor-titleBar-container'),
        previewButton: new Selector('eventEditor-titleBar-previewButton'),
      },
      sidebar: {
        container: new Selector('eventEditor-sidebar-container'),
      },
    },
  },
  utils: {
    modals: {
      alertSuccess: new Selector('utils-modals-alert-success'),
      alertDanger: new Selector('utils-modals-alert-danger'),
    },
  },
};

export default TestSelectors;
