import TestSelectors from '@racemap/utilities/consts/test_selectors';
import { FC } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCurrentUser, useIsMobile } from '../../lib/customHooks';
import { LinkEntry } from './LinkEntry';
import { SectionHeader } from './SectionHeader';
import { useIsNavLinkActive } from './utils';

export const TrackersLinks: FC = () => {
  const currentUser = useCurrentUser();
  const isMobile = useIsMobile();
  const { isActive } = useIsNavLinkActive();

  if (currentUser == null) {
    return null;
  }

  const content = (
    <>
      <LinkEntry
        key="trackers"
        as={Link}
        to="/admin/trackers"
        id={TestSelectors.admin.nav.sections.trackers.toString()}
        active={isActive('/admin/trackers')}
      >
        Management
      </LinkEntry>
      <LinkEntry
        key="message-templates"
        as={Link}
        to="/admin/message-templates"
        id={TestSelectors.admin.nav.sections.messageTemplates.toString()}
        active={isActive('/admin/message-templates')}
      >
        Message Templates
      </LinkEntry>
      <LinkEntry
        key="sim-cards"
        as={Link}
        to="/admin/sim-cards"
        id={TestSelectors.admin.nav.sections.sims.toString()}
        active={isActive('/admin/sim-cards')}
      >
        SIM Cards
      </LinkEntry>
    </>
  );

  if (isMobile) {
    return (
      <>
        <SectionHeader>Trackers</SectionHeader>
        <div>{content}</div>
      </>
    );
  }

  return (
    <div className="admin-links-not-collapsed">
      <NavDropdown
        id={TestSelectors.admin.nav.dropdowns.trackers.toString()}
        title="Trackers"
        className={TestSelectors.admin.nav.dropdowns.trackers.toString()}
        active={
          isActive('/admin/trackers') ||
          isActive('/admin/message-templates') ||
          isActive('/admin/sim-cards')
        }
      >
        {content}
      </NavDropdown>
    </div>
  );
};
