import { bsonSerialize, ejsonStringify } from '@racemap/sdk/schema/base';
import { HTTPFetchError } from '../api-client';
import fetch from '../fetch';

export function downloadBlob(blob: Blob, filename: string): void {
  if (document == null || window == null)
    throw new Error('Function works only in browser context.');

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(link.href);
}

export function downloadAsJson(exportObj: unknown, exportName: string) {
  const blob = new Blob([JSON.stringify(exportObj, undefined, 2)], { type: 'application/json' });
  downloadBlob(blob, `${exportName}.json`);
}

export function downloadAsBson(exportObj: Document, exportName: string) {
  const blob = new Blob([bsonSerialize(exportObj)], { type: 'application/bson' });
  downloadBlob(blob, `${exportName}.bson`);
}

export function downloadAsEJson(exportObj: unknown, exportName: string) {
  const blob = new Blob([ejsonStringify(exportObj, undefined, 2)], { type: 'application/json' });
  downloadBlob(blob, `${exportName}.json`);
}

export async function downloadFile(url: string, filename?: string) {
  const response = await fetch(url);

  if (!response.ok) {
    const error = await HTTPFetchError.from(response.status, response, url, 'GET');
    throw error;
  }

  const contentDisposition = response.headers.get('Content-Disposition');
  const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
  const blob = await response.blob(); // Get the file as a Blob
  const urlBlob = URL.createObjectURL(blob); // Create a URL for the Blob

  const a = document.createElement('a');
  a.href = urlBlob;
  a.download = filenameMatch ? filenameMatch[1] : filename || 'download';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(urlBlob);
}
