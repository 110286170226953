import { makeApi, makeEndpoint, prefixApi } from '@zodios/core';
import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from '../schema/base';
import {
  BillableItemPrototypeSchema,
  BillableItemSchema,
  DataUsageBillableItemSchema,
  ESEYE_PACKAGE_ITEM_IDS,
  EseyeInvoiceSchema,
  EseyeReportSchema,
  TimeKeySchema,
} from '../schema/billing';

const prefix = '/api/v1/billing';

const prepareEseyeBillingReport = makeEndpoint({
  method: 'post',
  path: '/actions/prepareEseyeReport',
  parameters: [
    {
      name: 'Invoice List',
      type: 'Body',
      description: 'List of invoice items to analyze.',
      schema: EseyeInvoiceSchema,
    },
  ],
  response: EseyeReportSchema,
  alias: 'prepareEseyeBillingReport',
  description: 'Get a prepared billing report for all user, that generate cost with eseye sims.',
  tag: 'billing',
});

const updateDataUsage = makeEndpoint({
  method: 'post',
  path: '/actions/updateDataUsage',
  parameters: [
    {
      name: 'Data Usage',
      type: 'Body',
      description: 'The data usage to update.',
      schema: z.object({
        zone: z.enum([
          ESEYE_PACKAGE_ITEM_IDS.ZONE_1,
          ESEYE_PACKAGE_ITEM_IDS.ZONE_2,
          ESEYE_PACKAGE_ITEM_IDS.ZONE_3,
        ]),
        customerId: ObjectIdSchema,
        chargingDate: DateSchema.optional(),
        month: TimeKeySchema,
        dataUsage: z.number(),
      }),
    },
  ],
  response: DataUsageBillableItemSchema,
  alias: 'updateDataUsage',
  description: 'Update the data usage of a billing report.',
  tag: 'billing',
});

const createBillableItem = makeEndpoint({
  method: 'post',
  path: '/items',
  parameters: [
    {
      name: 'Billable Item',
      type: 'Body',
      description: 'The billable item to create.',
      schema: BillableItemPrototypeSchema,
    },
  ],
  response: BillableItemSchema,
  alias: 'createBillableItem',
  description: 'Create a new billable item in the billing report.',
  tag: 'billing',
});

const removeBillableItem = makeEndpoint({
  method: 'delete',
  path: '/items/:billableItemId',
  parameters: [
    {
      name: 'billableItemId',
      type: 'Path',
      description: 'The id of the billable item to remove.',
      schema: ObjectIdSchema,
    },
  ],
  response: z.object({
    status: z.string(),
  }),
  alias: 'removeBillableItem',
  description: 'Remove a billable item from the billing report.',
  tag: 'billing',
});

export const endpoints = makeApi([
  prepareEseyeBillingReport,
  updateDataUsage,
  removeBillableItem,
  createBillableItem,
]);
export const billingApi = prefixApi(prefix, endpoints);
