import styled from '@emotion/styled';
import { isBillableItem } from '@racemap/utilities/functions/billing';
import { isLegacyChild } from '@racemap/utilities/functions/utils';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { Immutable } from 'immer';
import { FC } from 'react';
import { useCurrentUser } from '../../../lib/customHooks';
import { isCurrentEvent } from '../../../store/events/events_reducer_helper';
import { CurrentEvent } from '../../../store/events/events_reducers';
import {
  AnalyticsTabLink,
  BillingLink,
  CostCalculatorLink,
  PricingLink,
} from '../../BasicComponents/Links';
import { AlreadyPayedList } from './AlreadyPayedList';
import { PayLaterList } from './PayLaterList';

interface Props {
  event: Immutable<CurrentEvent>;
  patch: Partial<RacemapEvent>;
}

export const ConfirmationModal: FC<Props> = (props) => {
  const billableItems = props.event.billableItems.filter(isBillableItem);
  if (billableItems.length === 0) return <ConfirmationModalEventInFuture {...props} />;

  return <ConfirmationModalAlradyBilled {...props} />;
};

const ConfirmationModalEventInFuture: FC<Props> = ({ event, patch }) => {
  const currentUser = useCurrentUser();

  if (currentUser == null) return null;
  const updatedEvent = { ...event, ...patch };
  const isNoChild = !isLegacyChild(currentUser);

  if (!isCurrentEvent(updatedEvent)) return null;

  return (
    <Body>
      <section>
        You are going to modify event times respectively Add-Ons for extended use. The adjustments
        may incur charges.
      </section>
      {isNoChild && <EventUsageInformations userId={currentUser.id} eventId={event.id} />}
      <ArchiveTrackerInformations />

      {isNoChild && <PayLaterList event={updatedEvent} />}
    </Body>
  );
};

const ConfirmationModalAlradyBilled: FC<Props> = ({ event, patch }) => {
  const currentUser = useCurrentUser();

  if (currentUser == null) return null;
  const updatedEvent = { ...event, ...patch };
  const isNoChild = !isLegacyChild(currentUser);

  if (!isCurrentEvent(updatedEvent)) return null;

  return (
    <Body>
      <section>
        The event is ongoing. You are going to modify event times respectively Add-Ons for extended
        use. The adjustments may incur charges.
      </section>
      {isNoChild && <EventUsageInformations userId={currentUser.id} eventId={event.id} />}
      <ArchiveTrackerInformations />
      {isNoChild && (
        <>
          <AlreadyPayedList event={event} />
          <PayLaterList event={updatedEvent} />
        </>
      )}
    </Body>
  );
};

const EventUsageInformations: FC<{ userId: string; eventId: string }> = ({ userId, eventId }) => {
  return (
    <section>
      Event usage is tracked in <AnalyticsTabLink eventId={eventId} label="analytics" />, and the
      resulting costs are billed monthly. Review your monthly costs for all your events in the
      billing dashboard <BillingLink label="billing dashboard" userId={userId} />. For detailed
      pricing information, visit our <PricingLink label="pricing page" /> and use the{' '}
      <CostCalculatorLink label="cost calculator" />.
    </section>
  );
};

const ArchiveTrackerInformations: FC = () => {
  return (
    <section>
      You can archive an active event to halt additional charges. Archived events do not load the
      Map, Add-Ons, or provide any information. You can reactivate an archived event at any time.
    </section>
  );
};

const Body = styled.div`
    padding: 5px;
    section {
        margin-bottom: 1rem;
    }

    .ant-card:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;
