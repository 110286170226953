import { Feature, LineString, Position } from 'geojson';
import { LatLngElevPoint } from './types/engineTypes';
import { RacemapShadowtrackFeatureCollection } from './types/geos';
/* eslint-disable camelcase */
import { LatLng } from './types/trackping';
import { LatLngPoint } from './types/types';

// using turf earth radius
// https://github.com/Turfjs/turf/blob/9f151a503c9679c25b516137034f3ebfc6271d9f/packages/turf-helpers/index.ts#L39
const Degrees2radians = Math.PI / 180;
const EarthRadiusInMeter = 6371008.8; // 6378137 // 6373000
const EarthDiameterInMeter = 2 * EarthRadiusInMeter;

/*
 * This function calculates distance of two points on a sphere
 */
export function calculateDistanceInMeter(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number {
  const dLat = 0.5 * (lat2 - lat1) * Degrees2radians;
  const dLon = 0.5 * (lon2 - lon1) * Degrees2radians;
  const sinDLat = Math.sin(dLat);
  const sinDLon = Math.sin(dLon);
  const a =
    sinDLat * sinDLat +
    Math.cos(lat1 * Degrees2radians) * Math.cos(lat2 * Degrees2radians) * sinDLon * sinDLon;
  return EarthDiameterInMeter * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

/**
 * calculates the delta Latitude in degree by a given distance in meters
 * Keep in mind Latitude does not depend on Longitude
 * Lat
 *  ^
 *  |
 *  |
 *  |
 *  |----------> Lng
 *
 * @param distance in meters
 */
export function distToDeltaLat(distance: number): number {
  return distance / calculateDistanceInMeter(0, 0, 1, 0);
}

// chunks an array of points
// Position = [lng, lat, elv]
// raw:   * *  * *   *              *  *        *
// chunk: |       |       |       |       |      |
export function chunkLineString(
  lineString: Feature<LineString>,
  chunkLength = 10,
): Array<Position> {
  const coords = lineString.geometry.coordinates;
  const result: Array<Position> = [];
  if (coords.length > 0) {
    let currentLength = 0;
    let chunkCount = 1;
    result.push(coords[0]);
    for (let a = 1; a < coords.length; a++) {
      const p0 = coords[a - 1];
      const p1 = coords[a];
      const distance = calculateDistanceInMeter(p0[1], p0[0], p1[1], p1[0]);
      // we are always multiplying preventing sum up errors
      while (currentLength + distance > chunkCount * chunkLength) {
        // we are to far and have to calculate points behind us to match the chunkLength pattern until chunkedTracklength + chunkLength > currentLength
        const scale = (chunkCount * chunkLength - currentLength) / distance;
        result.push([p0[0] + scale * (p1[0] - p0[0]), p0[1] + scale * (p1[1] - p0[1])]);
        chunkCount += 1;
      }
      currentLength += distance;
    }
  }
  return result;
}

/**
 * calculates the delta Longitude in degree by a given distance in meters at a given point of Latitude
 * Keep in mind Longitude strongly depends your current Latitude
 * Lat
 *  ^
 *  |
 *  |
 *  |
 *  |----------> Lng
 *
 * @param distance in meters
 */
export function distToDeltaLng(distance: number, lat: number): number {
  return distance / calculateDistanceInMeter(lat, 0, lat, 1);
}

/*
 * This function interpolates between two real numbers (a, b) by an interpolation factor t (a, b, t € R)
 * Test exists
 */
export function interpolate(a: number, b: number, t: number): number {
  return a + t * (b - a);
}

export function interpolateBetweenTwoLatLngElevPoints(
  pA: LatLngElevPoint,
  pB: LatLngElevPoint,
  t: number,
): LatLngElevPoint {
  return {
    lng: interpolate(pA.lng, pB.lng, t),
    lat: interpolate(pA.lat, pB.lat, t),
    elv: interpolate(pA.elv, pB.elv, t),
  };
}

export function interpolateBetweenTwoLatLngPoints(
  pA: LatLngPoint,
  pB: LatLngPoint,
  t: number,
): LatLngPoint {
  return {
    lng: interpolate(pA.lng, pB.lng, t),
    lat: interpolate(pA.lat, pB.lat, t),
  };
}

export function addRandomLatDeviation(lat: number, deviationLimit: number): number {
  return lat + distToDeltaLat(deviationLimit * 2 * (Math.random() - 1));
}

export function addRandomLngDeviation(lng: number, latRef: number, deviationLimit: number): number {
  return lng + distToDeltaLng(deviationLimit * 2 * (Math.random() - 1), latRef);
}

export function addRandomLocationDeviation(location: LatLng, deviationLimit: number): LatLng {
  return [
    addRandomLatDeviation(location[1], deviationLimit),
    addRandomLngDeviation(location[0], location[1], deviationLimit),
  ];
}

export type TrackWithLimits = {
  length: number;
  lowerLeft: {
    lat: number;
    lng: number;
    latLng: LatLng;
    lngLat: LatLng;
  };
  upperRight: {
    lat: number;
    lng: number;
    latLng: LatLng;
    lngLat: LatLng;
  };
  center: {
    lat: number;
    lng: number;
    latLng: LatLng;
    lngLat: LatLng;
  };
  points: Array<LatLngElevPoint>;
};

export function getTrackCenterAndLength(aLatLngElevArray: Array<LatLngElevPoint>): TrackWithLimits {
  let minLat = 1000;
  let maxLat = -1000;
  let minLng = 1000;
  let maxLng = -1000;
  let length = 0;
  for (let a = 0; a < aLatLngElevArray.length; a += 1) {
    minLng = Math.min(minLng, aLatLngElevArray[a].lng);
    maxLng = Math.max(maxLng, aLatLngElevArray[a].lng);
    minLat = Math.min(minLat, aLatLngElevArray[a].lat);
    maxLat = Math.max(maxLat, aLatLngElevArray[a].lat);
    if (a > 0) {
      length += calculateDistanceInMeter(
        aLatLngElevArray[a].lat,
        aLatLngElevArray[a].lng,
        aLatLngElevArray[a - 1].lat,
        aLatLngElevArray[a - 1].lng,
      );
    }
    // We add distance to every point in points
    // New Shadow Tracks have Hight informations
    // aLatLngArray[a].push(length); => Fails on tracks with height information
    aLatLngElevArray[a].offset = length;
    // calculating the normalized direction for each trackpoint
    if (a < aLatLngElevArray.length - 1) {
      const dLat = aLatLngElevArray[a + 1].lat - aLatLngElevArray[a].lat;
      const dLng = aLatLngElevArray[a + 1].lng - aLatLngElevArray[a].lng;
      aLatLngElevArray[a].direction = {
        dLat: dLat !== 0 ? dLat / Math.sqrt(dLat * dLat + dLng * dLng) : 0,
        dLng: dLng !== 0 ? dLng / Math.sqrt(dLat * dLat + dLng * dLng) : 0,
      };
    } else {
      aLatLngElevArray[a].direction =
        a > 0 ? aLatLngElevArray[a - 1].direction : { dLat: 0, dLng: 0 };
    }
    aLatLngElevArray[a].perpendicular = {
      dLat: -(aLatLngElevArray[a]?.direction?.dLng || 0),
      dLng: aLatLngElevArray[a]?.direction?.dLat || 0,
    };
  }
  return {
    length,
    lowerLeft: {
      lat: minLat,
      lng: minLng,
      latLng: [minLat, minLng],
      lngLat: [minLng, minLat],
    },
    upperRight: {
      lat: maxLat,
      lng: maxLng,
      latLng: [maxLat, maxLng],
      lngLat: [maxLng, maxLat],
    },
    center: {
      lat: 0.5 * (maxLat + minLat),
      lng: 0.5 * (maxLng + minLng),
      latLng: [0.5 * (maxLat + minLat), 0.5 * (maxLng + minLng)],
      lngLat: [0.5 * (maxLng + minLng), 0.5 * (maxLat + minLat)],
    },
    points: aLatLngElevArray,
  };
}

/*
 * This function find tha point on the track, that has a target offset in meter. If the offset lay between two points,
 * it give the interpolated point back.
 */
export function findPositionOnTrackByOffset(
  offsetInMeter: number,
  unchunkedTrack: Array<Position>,
): { lat?: number; lng?: number; elv?: number; pointIndex: number } | void {
  let pointIndex: number;
  let lastOffset = 0;
  let lengthTotal = 0;

  for (pointIndex = 1; pointIndex < unchunkedTrack.length; pointIndex++) {
    const distanceToPrevPoint = calculateDistanceInMeter(
      unchunkedTrack[pointIndex][1],
      unchunkedTrack[pointIndex][0],
      unchunkedTrack[pointIndex - 1][1],
      unchunkedTrack[pointIndex - 1][0],
    );
    lengthTotal = lengthTotal + distanceToPrevPoint;

    if (offsetInMeter === lengthTotal) return { pointIndex };
    if (offsetInMeter < lengthTotal) {
      const point = unchunkedTrack[pointIndex];
      const lastPoint = unchunkedTrack[pointIndex - 1];

      const pA: LatLngElevPoint = { lng: lastPoint[0], lat: lastPoint[1], elv: lastPoint[2] };
      const pB: LatLngElevPoint = { lng: point[0], lat: point[1], elv: point[2] };
      const t = (offsetInMeter - lastOffset) / (lengthTotal - lastOffset);

      const interpolatedPoint = interpolateBetweenTwoLatLngElevPoints(pA, pB, t);
      return {
        lat: interpolatedPoint.lat,
        lng: interpolatedPoint.lng,
        elv: interpolatedPoint.elv,
        pointIndex,
      };
    }
    lastOffset = lengthTotal;
  }

  return;
}

/*
 * This function find tha point on the track, that has a target offset in meter. If the offset lay between two points,
 * it give the interpolated point back.
 */

type PositionOnTrackByOffset = { coordinates: LatLng; coordinateIndex: number; offset: number };

export function findNearestPointAndIndexOnShadowTrack(
  shadowTrack: RacemapShadowtrackFeatureCollection,
  offsetInMeter: number,
): PositionOnTrackByOffset {
  const result: PositionOnTrackByOffset = {
    coordinates: [0.0, 0.0],
    coordinateIndex: -1,
    offset: 0,
  };

  if (
    shadowTrack.features.length > 0 &&
    shadowTrack.features[0]?.geometry != null &&
    Array.isArray(shadowTrack.features[0]?.geometry?.coordinates)
  ) {
    const unchunkedTrack: Array<LatLng> = shadowTrack.features[0].geometry.coordinates.map(
      (position: Array<number>) => {
        return [position[0], position[1]];
      },
    );

    let coordinateIndex: number;
    let lastOffset = 0;
    let lengthTotal = 0;

    for (coordinateIndex = 1; coordinateIndex < unchunkedTrack.length; coordinateIndex++) {
      const distanceToPrevPoint = calculateDistanceInMeter(
        unchunkedTrack[coordinateIndex][1],
        unchunkedTrack[coordinateIndex][0],
        unchunkedTrack[coordinateIndex - 1][1],
        unchunkedTrack[coordinateIndex - 1][0],
      );
      lengthTotal = lengthTotal + distanceToPrevPoint;

      if (offsetInMeter === lengthTotal)
        return {
          coordinateIndex,
          coordinates: unchunkedTrack[coordinateIndex],
          offset: lengthTotal,
        };
      if (offsetInMeter < lengthTotal) {
        const point = unchunkedTrack[coordinateIndex];
        const lastPoint = unchunkedTrack[coordinateIndex - 1];

        const t = (offsetInMeter - lastOffset) / (lengthTotal - lastOffset);

        if (t < 0.5) {
          return {
            coordinates: lastPoint,
            coordinateIndex: coordinateIndex - 1,
            offset: lengthTotal - distanceToPrevPoint,
          };
        } else {
          return {
            coordinates: point,
            coordinateIndex: coordinateIndex,
            offset: lengthTotal,
          };
        }
      }
      lastOffset = lengthTotal;
    }
  }

  return result;
}
