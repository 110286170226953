import styled from '@emotion/styled';
import { PopoverHint } from '../../BasicComponents';

export const PairingOptionsTooltip = () => {
  return (
    <PopoverHint>
      <OptionTitle>Add new</OptionTitle>
      <OptionDescription>
        Each tracker you add will create a new participant for the event.
      </OptionDescription>
      <OptionTitle>Pair & Add</OptionTitle>
      <OptionDescription>
        Trackers will be assigned to participants without a device ID (e.g., transponder ID, app ID,
        IMEI). If there are more trackers than participants without device ID, new participants will
        be created to match the extra trackers.
      </OptionDescription>
      <OptionTitle>Pair Only</OptionTitle>
      <OptionDescription>
        Trackers will be assigned to participants who do not have a device ID. No new participants
        will be created.
      </OptionDescription>
    </PopoverHint>
  );
};

const OptionTitle = styled.p`
  font-weight: bold;
  margin: 5px 0;
`;

const OptionDescription = styled.span`
  margin-left: 5px;
  display: flex;
`;
