import { makeApi, makeEndpoint, prefixApi } from '@zodios/core';
import { z } from 'zod';
import { DateSchema, ObjectIdHexStringSchema, ObjectIdSchema } from '../../schema/base';

export const prefix = '/services/tracker-history';

const addEntry = makeEndpoint({
  method: 'post',
  path: '/',
  alias: 'addTrackerHistoryEntry',
  description: 'Write a entry to the tracker history',
  parameters: [
    {
      name: 'Payload',
      type: 'Body',
      description: 'The Entry to store',
      schema: z.array(
        z.object({
          trackerId: ObjectIdSchema,
          timestamp: DateSchema,
          battery: z.number().optional(),
          net: z.number().optional(),
          mcc: z.number().optional(),
          cell: z.number().optional(),
          area: z.number().optional(),
          radio: z.string().optional(),
        }),
      ),
    },
  ],
  status: 200,
  response: z.any(),
});

const getEntries = makeEndpoint({
  method: 'get',
  path: '/:trackerId',
  alias: 'getTrackerHistoryEntries',
  description: 'Get entries for a tracker id with optional a time frame',
  parameters: [
    { name: 'trackerId', type: 'Path', description: 'Tracker ID', schema: ObjectIdHexStringSchema },
    {
      name: 'startTime',
      required: false,
      type: 'Query',
      description: 'Start of the timeframe',
      schema: DateSchema,
    },
    {
      name: 'endTime',
      required: false,
      type: 'Query',
      description: 'End of the timeframe',
      schema: DateSchema,
    },
  ],
  response: z.array(z.object({ timestamp: DateSchema.optional(), battery: z.number().optional() })),
});

export const endpoints = makeApi([getEntries, addEntry]);
export const trackerHistoryServiceApi = prefixApi(prefix, endpoints);
