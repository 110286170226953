import { StripeProducts } from '@racemap/sdk/schema/billing';
import Stripe from 'stripe';

export const getProductPriceFactory = (
  priceList?: Record<StripeProducts, Stripe.Price>,
): ((product: StripeProducts, quantity: number, tier?: number | null) => number) | undefined => {
  if (priceList == null) return undefined;

  return (product: StripeProducts, quantity: number, tierIndex = null): number => {
    const productPrice = priceList[product];
    if (productPrice == null) throw new Error(`Price for product ${product} not found`);
    const packageSize = parseInt(productPrice.metadata.packageSize, 10) || 1;
    const quantityFactor = 1 / packageSize;
    const calculationQuantity = Math.ceil(quantity * quantityFactor);

    if (productPrice.billing_scheme === 'per_unit' && productPrice.unit_amount != null) {
      return productPrice.unit_amount * calculationQuantity;
    }

    // the tier index is defined, so the unit amount for that special tier is relevant
    if (productPrice.billing_scheme === 'tiered' && tierIndex != null) {
      if (productPrice.tiers == null) throw new Error(`No tiers found for product ${product}`);
      const tier = productPrice.tiers[tierIndex];
      if (tier == null)
        throw new Error(`No tier found for product ${product} at index ${tierIndex}`);
      if (tier.unit_amount == null)
        throw new Error(`No unit amount found for product ${product} at index ${tierIndex}`);

      return tier.unit_amount * calculationQuantity;
    }

    // the tier index is not defined, so we need to calculate the price based on the quantity for
    // the volume pricing scheme
    if (productPrice.billing_scheme === 'tiered' && productPrice.tiers_mode === 'volume') {
      if (productPrice.tiers == null) throw new Error(`No tiers found for product ${product}`);
      const tier = productPrice.tiers.find(
        (t) => t.up_to != null && calculationQuantity <= t.up_to,
      );
      if (tier == null)
        throw new Error(`No tier found for product ${product} at quantity ${calculationQuantity}`);
      if (tier.unit_amount == null)
        throw new Error(
          `No unit amount found for product ${product} at quantity ${calculationQuantity}`,
        );

      return tier.unit_amount * calculationQuantity;
    }

    // the tier index is not defined, so we need to calculate the price based on the quantity for
    // the graduated pricing scheme
    if (productPrice.billing_scheme === 'tiered' && productPrice.tiers_mode === 'graduated') {
      if (productPrice.tiers == null) throw new Error(`No tiers found for product ${product}`);
      let totalPrice = 0;
      let remainingQuantity = calculationQuantity;
      let chargedQuantity = 0;

      for (const tier of productPrice.tiers) {
        if (tier.unit_amount == null)
          throw new Error(
            `No unit amount found for product ${product} at quantity ${remainingQuantity}`,
          );

        const tierQuantity = Math.min(
          remainingQuantity,
          (tier.up_to || Infinity) - chargedQuantity,
        );
        totalPrice += tier.unit_amount * tierQuantity;
        chargedQuantity += tierQuantity;
        remainingQuantity -= tierQuantity;
      }

      return totalPrice;
    }

    throw new Error(
      `Unknown billing scheme for product ${product}: ${productPrice.billing_scheme}.`,
    );
  };
};
