import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { ToastContentProps, ToastOptions, toast } from 'react-toastify';

// removed cause not running on the server
// import type { ID } from '@racemap/utilities/types/utils';

export function getJSON(url: string, options: any = {}): Promise<any> {
  return fetch(url, options).then((res) => {
    if (200 <= res.status && res.status < 300) {
      return res.json();
    }
    return Promise.reject(new Error('The resource rejected the request.'));
  });
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

const asString = (entity: unknown): string => {
  if (typeof entity === 'string') {
    return entity;
  }
  if (entity instanceof Error || entity instanceof TypeError) {
    return entity.toString();
  }
  return JSON.stringify(entity);
};

type MessageType = string | { title: string; message: string };

export const toastSuccess = (message: MessageType): void => {
  if (typeof message === 'object') {
    if (message.title != null && message.message != null) {
      toast.success(
        <span>
          <strong>{message.title} </strong>
          {asString(message.message)}
        </span>,
        { autoClose: 5000 },
      );
    } else {
      toast.success(`${asString(message)}`, { autoClose: 5000 });
    }
  } else {
    toast.success(message, { autoClose: 5000 });
  }
};

export const openInNewTab = (url: string): void => {
  const win = window.open(url, '_blank');
  win.focus();
};

export const copyIntoClipBoard = (value: string): void => {
  navigator.clipboard.writeText(value);
  toastSuccess('Copied to clipboard');
};

export const toastInfo = (message: MessageType): void => {
  if (typeof message === 'object') {
    if (message.title != null && message.message != null) {
      toast.info(
        <span>
          <strong>{message.title} </strong>
          {asString(message.message)}
        </span>,
        { autoClose: 5000 },
      );
    } else {
      toast.info(`${asString(message)}`, { autoClose: 5000 });
    }
  } else {
    toast.info(message, { autoClose: 5000 });
  }
};

export const toastWarning = (message: MessageType): void => {
  console.warn(message);
  if (typeof message === 'object') {
    if (message.title != null && message.message != null) {
      toast.warn(
        <span>
          <strong>{message.title} </strong>
          {asString(message.message)}
        </span>,
        { autoClose: 5000 },
      );
    } else {
      toast.warn(`${asString(message)}`, { autoClose: 5000 });
    }
  } else {
    toast.warn(message, { autoClose: 5000 });
  }
};

export const toastError = (message: MessageType): void => {
  console.error(message);
  if (typeof message === 'object') {
    if (message.title != null && message.message != null) {
      toast.error(
        <span>
          <strong>{message.title} </strong>
          {asString(message.message)}
        </span>,
        { autoClose: 5000 },
      );
    } else {
      toast.error(`${asString(message)}`, { autoClose: 5000 });
    }
  } else {
    toast.error(message, { autoClose: 5000 });
  }
};

interface ToastContentData {
  message: string;
  buttonText?: string;
  onButtonClick?: (value: unknown) => void;
}

const ToastContentWithButton: FC<ToastContentProps<ToastContentData>> = ({
  closeToast,
  toastProps,
  data,
}) => {
  if (data == null) return <></>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
      <strong>{data.message}</strong>
      <div style={{ alignSelf: 'flex-end', marginTop: 10 }}>
        <Button
          onClick={data.onButtonClick != null ? data.onButtonClick : closeToast}
          variant={toastProps.theme === 'dark' ? `outline-${toastProps.type}` : toastProps.type}
        >
          {data.buttonText || 'OK'}
        </Button>
      </div>
    </div>
  );
};

// TODO: replace with new confirm toast
// gears-frontend/src/components/BasicComponents/Toasts/ToastConfirm.tsx
export const toastConfirm = async ({
  message,
  buttonText,
  options = {},
}: {
  message: string;
  buttonText?: string;
  options?: ToastOptions;
}) => {
  return new Promise((resolve, reject) => {
    toast<ToastContentData>(<ToastContentWithButton />, {
      data: { message, buttonText, onButtonClick: resolve },
      onClose: reject,
      ...options,
    });
  });
};
