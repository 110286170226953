import LogoWithTextUrl from '@assets/logo-with-text.svg';
import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const NavbarWithoutHeader = () => {
  return (
    <StyledNavbarWithoutHeader fixed="top" role="navigation" expand="md">
      <Link to="/admin/events">
        <img src={LogoWithTextUrl} alt="» Racemap" />
      </Link>
    </StyledNavbarWithoutHeader>
  );
};

const StyledNavbarWithoutHeader = styled(Navbar)`
  align-items: flex-start;
  width: 100%;
  height: 50px;
  background-color: ${RacemapColors.CloudBlue};

  img {
    margin-left: 15px;
    height: 50px;
    opacity: 0.6;
  }
`;
