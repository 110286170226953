import { InvoiceSummary, StripeProducts } from '@racemap/sdk/schema/billing';
import { User, UserOverview, UserOverviewSchema, UserSchema } from '@racemap/sdk/schema/user';
import { HTTPFetchError, RacemapAPIClient } from '@racemap/utilities/api-client';
import { MaxMobileWindowWidth } from '@racemap/utilities/consts/common';
import { UnitType } from '@racemap/utilities/consts/events';
import { OneMinuteInMillis, OneSecondInMillis } from '@racemap/utilities/consts/time';
import { currentEventTimes } from '@racemap/utilities/functions/event';
import { isAtomicEvent, isEmptyString, shortIdBuilder } from '@racemap/utilities/functions/utils';
import { isDefined } from '@racemap/utilities/functions/validation';
import { Alert } from '@racemap/utilities/types/alert';
import { Brand } from '@racemap/utilities/types/brand';
import {
  GroupEvent,
  RacemapEvent,
  RacemapStarter,
  Shadowtrack,
} from '@racemap/utilities/types/events';
import { SplitObject } from '@racemap/utilities/types/geos';
import { BillingInfo, User_Legacy } from '@racemap/utilities/types/users';
import { ObjectId, PathInto, TypeOfPath } from '@racemap/utilities/types/utils';
import { Immutable } from 'immer';
import { DateTime } from 'luxon';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import Stripe from 'stripe';
import useSWR, { SWRConfiguration } from 'swr';
import { useEventListener, useWindowSize } from 'usehooks-ts';
import { z } from 'zod';
import { getProductPriceFactory } from '../../components/CostCalculator/getProductPriceFactory';
import { CurrentEvent } from '../../store/events/events_reducers';
import { MapContext } from '../../store/maps/maps_reducers';
import { useStore } from '../../store/reducers';
import { ManagedSampleTrackers, ManagedTracker } from '../../store/trackers/trackers_reducers';
import { makeGetter } from '../FormUtils';

export { useControlled } from './useControlled';
export { useImageUpload } from './useImageUpload';
export { useDocumentTitle } from './useDocumentTitle';
export { useSimCardsPaginated } from './useSimCardsPaginated';
export { useSimCard } from './useSimCard';
export { useUsersPaginated } from './useUsersPaginated';
export { useEventLoads } from './useEventLoads';

export const apiClient = RacemapAPIClient.fromWindowLocation();

/**
 * Calls the given function in a interval. The time between two call is given with the delay.
 * The callback function have to be a Async function.
 * @param callback Function that should called
 * @param delay Time between two call in ms.
 */
export function useInterval(callback: () => Promise<void>, delay = 1000): void {
  const savedCallback = useRef<() => Promise<void> | null>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let isCancelled = false;
    // Function to be executed on each animation frame
    async function onFrame() {
      if (isCancelled) return;
      try {
        const callback = savedCallback.current;
        if (callback != null) {
          await callback();
        }
      } finally {
        timeoutId = setTimeout(onFrame, delay);
      }
    }

    // Start the frame
    onFrame();

    // Clean things up
    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [delay]);
}

export function useCurrentDateTime(resolutionInMs = OneSecondInMillis): DateTime {
  const [dateTime, setDateTime] = useState(DateTime.now());
  useInterval(async () => {
    setDateTime(DateTime.now());
  }, resolutionInMs);

  return dateTime;
}

export function useIsAdmin() {
  const isAdmin = useStore((s) => s.users.getter.isAdmin());

  return isAdmin;
}

export function useIsChildAccount() {
  const user = useStore((s) => s.users.getter.currentUser());
  return user?.parentId != null;
}

export function useIsReseller() {
  const user = useStore((s) => s.users.getter.currentUser());
  return user?.isReseller;
}

export const useIsMobile = () => {
  const { width: windowWith } = useWindowSize();

  // shortly after load windowWith is often 0
  return windowWith > 0 && windowWith <= MaxMobileWindowWidth;
};

export function useURLSearchParams() {
  const { search } = useLocation();
  return new URLSearchParams(search);
}

export function useURLSearchParamUpdater<T extends Array<string> | string>() {
  const { search, pathname } = useLocation();
  const history = useHistory();

  return (key: string, value: T | null | undefined) => {
    const params = new URLSearchParams(search);

    if (value == null || isEmptyString(value) || (Array.isArray(value) && value.length === 0)) {
      params.delete(key);
    } else if (Array.isArray(value)) {
      params.set(key, value.join(','));
    } else {
      params.set(key, value);
    }
    history.push({ pathname, search: params.toString() });
  };
}

const getParamValue = (params: URLSearchParams, key: string): string | Array<string> | null => {
  const value = params.get(key);
  if (value == null) return null;
  if (value?.includes(',')) {
    return value.split(',');
  }
  return value != null ? value : null;
};

export function useURLState<T extends Array<string> | string>(
  key: string,
  isMultiple?: boolean,
  defaultValue?: T,
): [T, (value: T) => void] {
  const searchParams = useURLSearchParams();
  const updateSearchParam = useURLSearchParamUpdater();
  let value = getParamValue(searchParams, key) || defaultValue || '';
  if (isMultiple && typeof value === 'string') {
    value = [value];
  }

  const setValue = (newValue: T) => updateSearchParam(key, newValue);

  return [value as T, setValue];
}

export function useElementDimensions(myRef: React.RefObject<HTMLElement | null>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, visible: false });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current?.offsetWidth || 0,
      height: myRef.current?.offsetHeight || 0,
      visible: myRef && myRef.current?.offsetHeight !== 0 && myRef.current?.offsetWidth !== 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    const resizeObserver = new ResizeObserver((_) => {
      handleResize();
    });

    if (myRef.current) {
      resizeObserver.observe(myRef.current);
      setDimensions(getDimensions());
    }

    return () => {
      if (myRef.current) {
        resizeObserver.unobserve(myRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [myRef]);

  return dimensions;
}

export type FieldGenerator<
  OI extends { id: string | ObjectId },
  PI extends PathInto<OI> = PathInto<OI>,
> = <O extends object = OI, P extends PI = PI>(
  key: P,
) => {
  value: TypeOfPath<O, P>;
  onChange: (newValue: TypeOfPath<O, P>) => Promise<void>;
};

export function useEventFieldGenerator<
  O extends RacemapEvent | CurrentEvent | Immutable<RacemapEvent> | Immutable<CurrentEvent>,
>(event: O | null): FieldGenerator<O, PathInto<O>> | null {
  if (event == null) return null;
  const { updateEvent } = useStore((s) => s.events.actions);

  return getFieldGenerator(event, updateEvent as UpdaterFunction<O>);
}

export function useUserFieldGenerator<O extends User_Legacy | Immutable<User_Legacy>>(
  user: O | null,
): FieldGenerator<O> | null {
  if (user == null) return null;
  const { updateUser } = useStore((s) => s.users.actions);

  return getFieldGenerator(user, updateUser as UpdaterFunction<O>);
}

type UpdaterFunction<T extends { id: string | ObjectId }> = (
  obj: T['id'],
  update: Array<{ key: PathInto<T>; newValue: any }>,
) => Promise<void>;

export function getFieldGenerator<T extends { id: string | ObjectId }, P extends PathInto<T>>(
  obj: T,
  updateFunction: UpdaterFunction<T>,
): FieldGenerator<T, P> {
  const makeChanger = function (key: P) {
    return (newValue: TypeOfPath<T, P>) => updateFunction(obj.id, [{ key, newValue }]);
  };
  const fieldGenerator = function (key: P) {
    return {
      value: makeGetter(obj)(key),
      onChange: makeChanger(key),
    };
  } as FieldGenerator<T, P>;

  return fieldGenerator as FieldGenerator<T, P>;
}

export function useCurrentEvent(): Immutable<CurrentEvent> | null {
  return useStore((s) => s.events.getter.currentEvent());
}

export function useCurrentUser(): Immutable<User_Legacy> | null {
  return useStore((s) => s.users.getter.currentUser());
}

export function useCurrentMapContext(): Immutable<MapContext> | null {
  return useStore((s) => s.maps.getter.currentMapContext());
}

export function useChildEvents(eventId: string | null | undefined): Immutable<Array<RacemapEvent>> {
  const events = useStore((s) => s.events.items);
  const childEvents = useMemo(() => {
    if (eventId == null) return [];

    return Array.from(events.values())
      .filter((e) => e.parent === eventId)
      .filter(isAtomicEvent)
      .sort((a, b) => Date.parse(a.startTime) - Date.parse(b.startTime));
  }, [events, eventId]);

  return childEvents;
}

export function useUserBillingInfo({
  userId,
  month,
}: {
  userId?: ObjectId;
  month: string;
}): {
  billingInfo?: BillingInfo | null;
  isLoading: boolean;
  error: Error;
} {
  const {
    data: billingInfo,
    isLoading,
    error,
  } = useSWR(
    userId != null ? ['GET', 'USER_BILLING_INFO', userId.toHexString(), month] : null,
    async ([, , userId, month]) => {
      const date = DateTime.fromISO(month, { zone: 'utc' });
      const startTime = date.startOf('month').toJSDate();
      const endTime = date.endOf('month').toJSDate();

      return apiClient.getUserBillingInfo(userId, startTime, endTime);
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 10,
    },
  );

  return { billingInfo, isLoading, error };
}

const UseUserResponseSchema = z.union([UserSchema, UserOverviewSchema]);
export function useUser({
  userId,
  options,
}: { userId?: string | ObjectId | null; options?: SWRConfiguration }): {
  user?: User | UserOverview | null;
  isLoading: boolean;
  error: Error;
} {
  const { data, isLoading, error } = useSWR(
    userId != null ? ['GET', 'USER', userId?.toString()] : null,
    async ([, , userId]) => apiClient.getUser(userId),
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 5,
      ...options,
    },
  );
  const user = data != null ? UseUserResponseSchema.parse(data) : data;
  return { user, isLoading, error };
}

export function useUserInfo(
  userId: string | ObjectId | null,
  email?: string,
  options: SWRConfiguration = {},
): {
  user?: UserOverview | null;
  error?: Error;
  isLoading: boolean;
  isUnknownUser: boolean;
} {
  const res = useSWR(
    userId != null || email != null ? ['GET', 'USER_INFO', userId?.toString(), email] : null,
    async ([, , userId, email]) => {
      try {
        return UserOverviewSchema.parse(await apiClient.userLookup({ email, userId }));
      } catch (error) {
        if (error instanceof HTTPFetchError && error.status === 404) {
          return null;
        }
        throw error;
      }
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 5,
      keepPreviousData: true,
      ...options,
    },
  );

  return {
    user: res.data,
    error: res.error,
    isLoading: res.isLoading,
    isUnknownUser: res.data === null,
  };
}

export function useUserSearch(
  searchValue: string | null,
  limit = 5,
  options: SWRConfiguration = {},
): {
  users?: Array<UserOverview> | null;
  error?: Error;
  isLoading: boolean;
} {
  const res = useSWR(
    searchValue != null ? ['LOOKUP', 'USER_INFO', searchValue] : null,
    async ([, , searchValue]) => {
      try {
        const result = await apiClient.getUsers({
          search: searchValue,
          limit,
        });
        return result.users.map((u) => UserOverviewSchema.parse(u));
      } catch (error) {
        if (error instanceof HTTPFetchError && error.status === 404) {
          return null;
        }
      }
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 5,
      keepPreviousData: true,
      ...options,
    },
  );

  return {
    users: res.data,
    error: res.error,
    isLoading: res.isLoading,
  };
}

export function useUnit(): UnitType {
  const currentEvent = useCurrentEvent();
  return currentEvent?.playerOptions.unitType || UnitType.METRIC;
}

export function useCurrentParentEvent(): Immutable<GroupEvent> | null {
  return useStore((s) => s.events.getter.parentEvent());
}

export function useTrackers(): Immutable<Array<ManagedTracker>> {
  return Array.from(useStore((s) => s.trackers.items).values());
}

export function useFilteredTrackers(): Immutable<Array<ManagedTracker>> {
  return Array.from(useStore((s) => s.trackers.getter.filteredTrackers()));
}

export function useSampleTrackers(): Immutable<ManagedSampleTrackers> {
  const items = useStore((s) => s.trackers.items);
  const sampleTrackerIds = useStore((s) => s.trackers.sampleTrackerIds);

  return Array.from(sampleTrackerIds)
    .map((id) => items.get(id))
    .filter(isDefined)
    .map((sT, index) => ({ ...sT, index }));
}

export function useSelectedTrackers(): Immutable<Array<ManagedTracker>> {
  const items = useStore((s) => s.trackers.items);
  const totalTrackerIds = useStore((s) => s.trackers.selectedTrackerIds.total);

  return Array.from(totalTrackerIds)
    .map((id) => items.get(id))
    .filter(isDefined);
}

export function useHighlightedTrackers(): Immutable<Array<ManagedTracker>> {
  const items = useStore((s) => s.trackers.items);
  const { total, sample } = useStore((s) => s.trackers.selectedTrackerIds);
  const highlightedTrackerIds = Array.from(new Set([...total, ...sample]));

  return highlightedTrackerIds.map((id) => items.get(id)).filter(isDefined);
}

export function useInspectedTracker(): Immutable<ManagedTracker> | null {
  return useStore((s) => s.trackers.getter.inspectedTracker());
}

export function useCountSearchedTrackers(): number {
  return useStore((s) => s.trackers.getter.countSearchedTrackers());
}

export function useTrackersForAction(): Immutable<Array<ManagedTracker>> | null {
  const isMobile = useIsMobile();
  const selectedTrackers = useSelectedTrackers();
  const sampleTrackers = useSampleTrackers();

  return isMobile ? selectedTrackers : sampleTrackers;
}

export function useOutsideEvent(
  ref: RefObject<any>,
  onOutsideClick: (event: MouseEvent) => void,
  protectedElements: Array<RefObject<any>> = [],
  dependencies: Array<any> = [],
) {
  useEffect(() => {
    /**
     * Trigger event, if you click outside of the element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !protectedElements.find((e) => e.current.contains(event.target))
      ) {
        onOutsideClick(event);
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, ...dependencies]);
}

export function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// TODO: replace it with native useId hook in react@18
export function useId(): string {
  const [id] = useState(shortIdBuilder());

  return id;
}

export function useTabVisibility(): boolean {
  const [isVisible, setIsVisible] = useState(document.visibilityState === 'visible');

  const onVisibilityChange = () => {
    setIsVisible(document.visibilityState === 'visible');
  };

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  return isVisible;
}

/**
 * Returns the current scroll position of the window.
 *
 * @returns {number} The current scroll position of the window.
 */
export function useOnScroll(): number {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPosition;
}

/**
 * Returns the position of a given element relative to the viewport.
 *
 * @param {RefObject<HTMLElement>} ref - A reference to the element to track.
 * @returns {DOMRect} The position of the element relative to the viewport.
 */
export function useElementPosition(ref: RefObject<HTMLElement>): DOMRect {
  const [elementPosition, setElementPosition] = useState<DOMRect>(() => new DOMRect());
  const { visible } = useElementDimensions(ref);

  const handlePositionChange = () => {
    if (ref.current) {
      setElementPosition(ref.current.getBoundingClientRect());
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handlePositionChange, true);

    return () => {
      window.removeEventListener('scroll', handlePositionChange);
    };
  }, []);

  useEffect(handlePositionChange, [visible]);

  return elementPosition;
}

/**
 * Reactive document.activeElement, returns a reference to current active element
 *
 * @returns current active element (DOM node)
 **/
export function useActiveElement() {
  const [activeElement, setActiveElement] = useState(() => document?.activeElement);
  const documentRef = useRef<Document>(document);

  useEventListener('focus', () => setActiveElement(document?.activeElement), documentRef, true);
  useEventListener('blur', () => setActiveElement(null), documentRef, true);

  return { activeElement };
}

export enum Platforms {
  ANDROID = 'Android',
  IOS = 'IOS',
  UNKNOWN = 'UNKNOWN',
  MAC = 'Mac',
  WINDOWS = 'Windows',
}

/**
 * Return the platform of the current device
 *
 * @returns Android | IOS | UNKNOWN | Mac | Windows
 **/
export function usePlatform(): Platforms {
  return useMemo(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Android/i)) {
      return Platforms.ANDROID;
    }
    if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return Platforms.IOS;
    }
    if (userAgent.match(/Mac/i)) {
      return Platforms.MAC;
    }
    if (userAgent.match(/Windows/i)) {
      return Platforms.WINDOWS;
    }
    return Platforms.UNKNOWN;
  }, []);
}

export function usePrice(productKey: StripeProducts): {
  price: number | undefined;
  isLoading: boolean;
  error: Error;
  priceId: string | undefined;
  productId: string | undefined;
} {
  const {
    data: price,
    isLoading,
    error,
  } = useSWR(
    ['GET', 'PRICE', productKey],
    async ([, , productKey]) => {
      return await apiClient.getPrice(productKey);
    },
    { revalidateIfStale: false, refreshInterval: OneMinuteInMillis * 30 },
  );
  const productId = typeof price?.product === 'string' ? price.product : undefined;

  return {
    price: price?.unit_amount != null ? price.unit_amount : undefined,
    isLoading,
    error,
    priceId: price?.id,
    productId,
  };
}

export function useEventBasePrice(): number | undefined {
  const getPrice = useGetProductPrice();
  const getEventPrice = useCallback(() => getPrice?.(StripeProducts.BASE_PRICE, 1), [getPrice]);

  return getEventPrice();
}

export function usePriceList({ isReseller }: { isReseller?: boolean } = {}): {
  priceList: Record<StripeProducts, Stripe.Price> | undefined;
  isReseller: boolean | undefined;
  isLoading: boolean;
  error: Error;
} {
  const { data, isLoading, error } = useSWR(
    `get_price_list${isReseller ? '_reseller' : ''}`,
    async () => {
      return await apiClient.getPriceList({ isReseller });
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      refreshInterval: OneMinuteInMillis * 30,
      keepPreviousData: true,
    },
  );
  const priceList = data?.list;

  return {
    priceList,
    isReseller: data?.isReseller,
    isLoading,
    error,
  };
}

export function useGetProductPrice({
  isReseller,
}: { isReseller?: boolean } = {}):
  | ((product: StripeProducts, quantity: number, tier?: number | null) => number)
  | undefined {
  const { priceList } = usePriceList({ isReseller });
  return useMemo(() => getProductPriceFactory(priceList), [priceList]);
}

export function useGetProductPricePerUnit({
  isReseller,
}: { isReseller?: boolean } = {}): (
  product: StripeProducts,
  tierIndex?: number,
) => number | undefined {
  const getProductPrice = useGetProductPrice({ isReseller });

  return useCallback(
    (product: StripeProducts, tierIndex?: number) => getProductPrice?.(product, 1, tierIndex),
    [getProductPrice],
  );
}

export function useMonthlyInvoice({ month, userId }: { month: string; userId?: ObjectId }): {
  invoice?: InvoiceSummary | null;
  isLoading: boolean;
  error: Error;
} {
  const {
    data: invoice,
    isLoading,
    error,
  } = useSWR(
    ['GET', 'MONTHLY_INVOICE', month, userId],
    async ([, , month, userId]) => {
      try {
        return await apiClient.getMonthlyInvoice({ month, userId: userId?.toHexString() });
      } catch (error) {
        if (error instanceof HTTPFetchError && error.status === 404) {
          return null;
        }
        throw error;
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      refreshInterval: OneMinuteInMillis * 10,
    },
  );

  return { invoice, isLoading, error };
}

/**
 * Returns the brands/custom apps of a user
 *
 * @returns {brands: Array<Brand> | undefined, isLoading: boolean, error: Error}
 */
export function useBrands(): {
  brands: Array<Brand> | undefined;
  isLoading: boolean;
  error: Error;
} {
  const user = useCurrentUser();
  const { data, isLoading, error } = useSWR(
    user != null ? ['GET', 'BRANDS', user.id] : null,
    async () => {
      return await apiClient.listBrands();
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 5,
      keepPreviousData: true,
    },
  );

  return { brands: data, isLoading, error };
}

/**
 * Returns the shadowtrack of an event
 *
 * @returns {alerts: Array<Alert> | undefined, isLoading: boolean, error: Error}
 */
export function useShadowtrack({ event }: { event?: RacemapEvent | null }): {
  shadowtrack: Shadowtrack | undefined;
  isLoading: boolean;
  error: Error;
} {
  const { data, isLoading, error } = useSWR(
    event?.geo.shadowtrackId != null
      ? ['GET', 'SHADOWTRACK', event.geo.shadowtrackId?.toString()]
      : null,
    async ([, , shadowtrackId]) => {
      return await apiClient.getGeoLineString(shadowtrackId);
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 5,
      keepPreviousData: true,
    },
  );

  return { shadowtrack: data, isLoading, error };
}

/**
 * Returns the alerts of an event and parent event
 *
 * @returns {alerts: Array<Alert> | undefined, isLoading: boolean, error: Error}
 */
export function useAlerts({ event }: { event?: RacemapEvent | null }): {
  alerts: Array<Alert> | undefined;
  isLoading: boolean;
  error: Error;
} {
  const [startTime, endTime] = currentEventTimes(event);
  const { data, isLoading, error } = useSWR(
    event != null && startTime != null && endTime != null
      ? ['GET', 'ALERTS', event.id.toString(), event.parent, startTime, endTime]
      : null,
    async ([, , eventId, parentEventId, startTime, endTime]) => {
      const startAlertFrame = DateTime.fromMillis(startTime).minus({ hours: 8 }).toJSDate();
      const endAlertFrame = DateTime.fromMillis(endTime).plus({ hours: 8 }).toJSDate();

      const [childAlerts, parentAlerts] = await Promise.all([
        apiClient.listAlertsByEvent(eventId, startAlertFrame, endAlertFrame),
        parentEventId != null
          ? apiClient.listAlertsByEvent(parentEventId, startAlertFrame, endAlertFrame)
          : [],
      ]);

      return [...childAlerts, ...parentAlerts];
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneSecondInMillis * 10,
      keepPreviousData: true,
    },
  );

  return { alerts: data, isLoading, error };
}

/**
 * Returns the event for a slug or an eventId
 *
 * @returns {event: RacemapEvent | undefined, isLoading: boolean, error: Error}
 */
export function useEvent(
  { slug, eventId }: { slug?: string; eventId?: string | ObjectId },
  options?: SWRConfiguration,
): {
  event: RacemapEvent | null | undefined;
  isLoading: boolean;
  error: Error;
} {
  const { data, isLoading, error } = useSWR(
    eventId != null || slug != null ? ['GET', 'EVENT', eventId || slug] : null,
    async ([, , eventIdOrSlug]) => {
      if (eventIdOrSlug == null) return null;
      const event = await apiClient.getEvent(eventIdOrSlug?.toString());

      return event;
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 5,
      keepPreviousData: true,
      ...options,
    },
  );

  return { event: data, isLoading, error };
}

/**
 * Returns the starters of an event
 *
 * @returns {starters: Array<RacemapStarter> | null | undefined, isLoading: boolean, error: Error}
 */
export function useEventStarters(
  { eventId, filter }: { eventId?: string | ObjectId; filter?: Array<string> },
  options?: SWRConfiguration,
): {
  starters: Array<RacemapStarter> | null | undefined;
  isLoading: boolean;
  error: Error;
} {
  const { data, isLoading, error } = useSWR(
    eventId != null ? ['GET', 'STARTERS', eventId, filter?.join(',') || ''] : null,
    async ([, , eventId, filter]) => {
      const event = await apiClient.getEventStarters(eventId.toString(), {
        filter: filter.split(','),
      });

      return event;
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 1,
      keepPreviousData: true,
      ...options,
    },
  );

  return { starters: data, isLoading, error };
}

/**
 * Returns the splits of an event
 *
 * @returns {event: Array<SplitObject> | undefined, isLoading: boolean, error: Error}
 */
export function useEventSplits(
  { eventId }: { eventId?: string | ObjectId },
  options?: SWRConfiguration,
): {
  splits: Array<SplitObject> | null | undefined;
  isLoading: boolean;
  error: Error;
} {
  const { data, isLoading, error } = useSWR(
    eventId != null ? ['GET', 'SPLITS', eventId] : null,
    async ([, , eventId]) => {
      const event = await apiClient.getEventSplits(eventId.toString());

      return event;
    },
    {
      revalidateIfStale: false,
      refreshInterval: OneMinuteInMillis * 5,
      keepPreviousData: true,
      ...options,
    },
  );

  return { splits: data, isLoading, error };
}
