import { WebMercatorViewport } from '@math.gl/web-mercator';
import { downloadBlob } from '@racemap/utilities/functions/download';

import { isNotNull } from '@racemap/utilities/functions/validation';
import {
  LineStringObject,
  PointObject,
  RacemapFeatureCollection,
  RacemapGeoObject,
} from '@racemap/utilities/types/geos';
import { LatLngPoint } from '@racemap/utilities/types/types';
import {
  Feature,
  FeatureCollection,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';
import { Immutable, produce } from 'immer';
import { ViewState } from 'react-map-gl';

export enum EDIT_TYPE {
  ADD_FEATURE = 'addFeature',
  ADD_POSITION = 'addPosition',
  REMOVE_POSITION = 'removePosition',
  ADD_TENTATIVE_POSITION = 'addTentativePosition',
  MOVE_POSITION = 'movePosition',
  FINISH_MOVE_POSITION = 'finishMovePosition',
}

export type Bounds = {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
};

function getMaxBounds(): Bounds {
  return {
    minLat: 90,
    maxLat: -89.99,
    minLng: 180,
    maxLng: -179.99,
  };
}

// TODO: Move everything to lib/GeoUtils.js

export function fitViewStateToBounds({
  baseViewState = getDefaultViewState(),
  bounds,
  viewportHeight = 1000,
  viewportWidth = 1000,
}: {
  baseViewState?: ViewState;
  viewportHeight?: number;
  viewportWidth?: number;
  bounds?: Bounds | null;
}): ViewState {
  bounds = bounds == null ? getMaxBounds() : bounds;
  const oldZoom = baseViewState != null ? baseViewState.zoom : 7;
  const newViewport = {
    ...baseViewState,
    width: viewportWidth,
    height: viewportHeight,
  };
  const oldPadding = baseViewState.padding || { top: 0, bottom: 0, left: 0, right: 0 };

  // the padding cant be smaller than half of the viewport
  const padding = {
    top: viewportHeight - oldPadding.top - oldPadding.bottom < 0 ? 0 : oldPadding.top,
    bottom: viewportHeight - oldPadding.top - oldPadding.bottom < 0 ? 0 : oldPadding.bottom,
    left: viewportWidth - oldPadding.left - oldPadding.right < 0 ? 0 : oldPadding.left,
    right: viewportWidth - oldPadding.left - oldPadding.right < 0 ? 0 : oldPadding.right,
  };

  let { longitude, latitude, zoom } = new WebMercatorViewport(newViewport).fitBounds(
    [
      [bounds.minLng, bounds.minLat],
      [bounds.maxLng, bounds.maxLat],
    ],
    {
      padding,
    },
  );

  if (zoom === Infinity) zoom = oldZoom;
  if (zoom <= 0) zoom = oldZoom;
  if (zoom >= 24) zoom = oldZoom;

  return {
    ...newViewport,
    latitude,
    longitude,
    zoom,
  };
}

export function getBounds(
  features: Immutable<
    Array<Feature<LineString | MultiLineString | Point | MultiPoint | Polygon | MultiPolygon>>
  >,
): Bounds | null {
  const points = features
    .filter(isNotNull)
    .map((f) => {
      const { geometry } = f;
      if (geometry == null) {
        return [];
      }
      if (geometry.type === 'Point') {
        return [geometry.coordinates];
      } else if (geometry.type === 'LineString') {
        return geometry.coordinates;
      }
      return [];
    })
    .reduce((r, a = []) => r.concat(a), [])
    .filter((r) => r.length > 1);

  if (points.length === 0) return null;

  const bounds = getMaxBounds();
  for (let i = 0, len = points.length; i < len; i++) {
    const [lng, lat] = points[i];
    bounds.minLat = Math.min(lat, bounds.minLat);
    bounds.maxLat = Math.max(lat, bounds.maxLat);
    bounds.minLng = Math.min(lng, bounds.minLng);
    bounds.maxLng = Math.max(lng, bounds.maxLng);
  }
  return bounds;
}

export function mergeBounds(boundsObjects: Array<Bounds>): Bounds {
  const mergeBounds = {
    minLng: Infinity,
    minLat: Infinity,
    maxLng: -Infinity,
    maxLat: -Infinity,
  };

  for (const bounds of boundsObjects) {
    if (bounds == null) continue;

    if (bounds.minLat < mergeBounds.minLat) mergeBounds.minLat = bounds.minLat;
    if (bounds.minLng < mergeBounds.minLng) mergeBounds.minLng = bounds.minLng;

    if (bounds.maxLng > mergeBounds.maxLng) mergeBounds.maxLng = bounds.maxLng;
    if (bounds.maxLat > mergeBounds.maxLat) mergeBounds.maxLat = bounds.maxLat;
  }

  return mergeBounds;
}

export function isLocationInBounds(
  location: LatLngPoint,
  bounds: Bounds,
  tolerancInPercent: number,
): boolean {
  return isPointInBounds(
    {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [location.lng, location.lat] },
      properties: null,
    },
    bounds,
    tolerancInPercent,
  );
}

export function isPointInBounds(
  point: Feature<Point, any>,
  bounds: Bounds,
  tolerancInPercent: number,
) {
  const lng = point.geometry.coordinates[0];
  const lat = point.geometry.coordinates[1];
  const toleranceLng = (bounds.maxLng - bounds.minLng) * tolerancInPercent;
  const toleranceLat = (bounds.maxLat - bounds.minLat) * tolerancInPercent;

  if (
    lng < bounds.minLng - toleranceLng ||
    lng > bounds.maxLng + toleranceLng ||
    lat < bounds.minLat - toleranceLat ||
    lat > bounds.maxLat + toleranceLat
  )
    return false;
  return true;
}

export function removeElevation(
  geoJson: Immutable<RacemapFeatureCollection>,
): Immutable<RacemapFeatureCollection> {
  return produce(geoJson, (draft) => {
    for (const feature of draft.features) {
      if (feature.geometry == null) continue;
      const {
        geometry: { type },
      } = feature;

      switch (type) {
        case 'Point':
          (feature as PointObject).geometry.coordinates.splice(2, 1);
          break;
        case 'LineString':
          (feature as LineStringObject).geometry.coordinates.forEach((c) => {
            c.splice(2, 1);
          });
          break;
      }
    }
  });
}

export function getFeatureByIndex(
  index: number | null,
  geojson: Immutable<RacemapFeatureCollection> | FeatureCollection,
): Feature | null | undefined {
  if (geojson == null || geojson.features == null || index == null) return null;

  return geojson.features.find((feature) => feature.featureIndex === index);
}

export function getFeatureById<T extends RacemapGeoObject>(
  id: string | null,
  features: Immutable<Array<T>>,
): T | null | undefined {
  if (id == null) return null;

  return features.find((f) => f.id === id) as T | undefined;
}

export const exportFeature = (feature: Feature) => {
  const filename = feature.properties.name || 'feature';
  const blob = new Blob([JSON.stringify(feature)], {
    type: 'application/vnd.geo+json',
  });
  downloadBlob(blob, `${filename}_track.geojson`);
};

export function testForFeatures(geojsons: Array<Immutable<RacemapFeatureCollection>>) {
  for (const geojson of geojsons) {
    if (geojson != null && geojson.features != null && geojson.features.length > 0) return true;
  }

  return false;
}

export function getDefaultViewState(withElevationChart = false): ViewState {
  return {
    longitude: 1.0,
    latitude: 1.0,
    zoom: 0.44,
    pitch: 0,
    bearing: 0,
    padding: {
      top: 100,
      bottom: withElevationChart ? 200 : 100,
      left: 100,
      right: 100,
    },
  };
}
