import { isValidIMEI } from './isValidIMEI';
import { isEmptyString } from './utils';

/**
 * Validates whether the provided `appId` is a valid starter application ID.
 *
 * The function checks the following conditions:
 * - If `appId` is `null`, it returns `true`.
 * - If `appId` is not a string, it returns `false`.
 * - If `appId` is a 15-digit number or a valid ICCID, it returns `true`.
 * - If `appId` is an empty string, it returns `true`.
 * - If `appId` is a 14-digit number and with leading zero a valid IMEI, it throws error.
 * - If `appId` is a valid UUID, it returns `true`.
 * - If `appId` starts with any of the following prefixes: 'RRPing_', 'RRBox_', 'FeibotPing_', or 'TPTest_', it returns `true`.
 *
 * @param appId - The application ID to validate. It can be of any type.
 * @returns A boolean indicating whether the `appId` is a valid starter application ID.
 * @throws An error if the `appId` is a 14-digit number and with leading zero a valid IMEI.
 */
export function validateStarterAppId(appId?: unknown): appId is string | null {
  if (appId === undefined) return false;
  if (appId === null || isEmptyString(appId)) return true;
  if (typeof appId !== 'string') return false;
  if (/^[0-9]{15}$/.test(appId)) return isValidIMEI(appId);
  // corrupted imei because leading zero was removed
  if (/^[0-9]{14}$/.test(appId) && isValidIMEI(`0${appId}`))
    throw new Error('Corrupted IMEI. Leading zero was removed.');

  // only allowed characters are 0-9, a-f, A-F, -, and _
  if (/[^a-zA-Z0-9_-]/.test(appId)) return false;

  return true;
}
