export function clamp(min: number, value: number, max: number): number {
  return Math.min(max, Math.max(min, value));
}

export function mean(values: Iterable<number>): number {
  let sum = 0;
  let count = 0;
  for (const value of values) {
    sum += value;
    count += 1;
  }
  if (count === 0) return 0;
  return sum / count;
}

// Move a entry in array from one position to another
export function moveArrayEntry(arr: Array<unknown>, oldIndex: number, newIndex: number) {
  if (oldIndex === newIndex) return [...arr];

  if (oldIndex < 0 || newIndex < 0) throw new RangeError('You have to give positive indexes.');

  let finalIndex = newIndex;
  if (newIndex >= arr.length) {
    finalIndex = arr.length - 1;
  }
  const element = arr[oldIndex];
  const partBeforeElement = arr.slice(0, oldIndex);
  const partAfterElement = arr.slice(oldIndex + 1);

  if (newIndex > oldIndex) {
    return [
      ...partBeforeElement,
      ...partAfterElement.slice(0, finalIndex - oldIndex),
      element,
      ...partAfterElement.slice(finalIndex - oldIndex),
    ];
  } else {
    return [
      ...partBeforeElement.slice(0, finalIndex),
      element,
      ...partBeforeElement.slice(finalIndex),
      ...partAfterElement,
    ];
  }
}

export function countIf<T>(array: Iterable<T>, predicate: (arg0: T) => boolean) {
  let output = 0;
  for (const a of array) {
    if (predicate(a)) {
      output += 1;
    }
  }
  return output;
}

export function sum<T>(array: Iterable<T>, func?: (arg0: T) => number) {
  let output = 0;
  for (const a of array) {
    if (func != null) {
      output += func(a);
    } else {
      if (typeof a !== 'number')
        throw new Error(
          'Sum is only possible with an Array of Numbers, or with an aggregate function!',
        );
      output += a;
    }
  }
  return output;
}
