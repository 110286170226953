// @ts-nocheck
'use strict';
// code generated by pbf v3.2.1

// Point ========================================

export const Point = {};

Point.read = function (pbf, end) {
  return pbf.readFields(Point._readField, { lng: 0, lat: 0, time: 0 }, end);
};
Point._readField = function (tag, obj, pbf) {
  if (tag === 1) obj.lng = pbf.readDouble();
  else if (tag === 2) obj.lat = pbf.readDouble();
  else if (tag === 3) obj.time = pbf.readDouble();
};
Point.write = function (obj, pbf) {
  if (obj.lng) pbf.writeDoubleField(1, obj.lng);
  if (obj.lat) pbf.writeDoubleField(2, obj.lat);
  if (obj.time) pbf.writeDoubleField(3, obj.time);
};

// TracksData ========================================

export const TracksData: {read?: (Pbf, number) => Buffer, write?: (obj: unknown, Pbf) => void } = {};

TracksData.read = function (pbf, end) {
  return pbf.readFields(TracksData._readField, { tracks: [] }, end);
};
TracksData._readField = function (tag, obj, pbf) {
  if (tag === 1) obj.tracks.push(TracksData.TrackData.read(pbf, pbf.readVarint() + pbf.pos));
};
TracksData.write = function (obj, pbf) {
  if (obj.tracks)
    for (var i = 0; i < obj.tracks.length; i++)
      pbf.writeMessage(1, TracksData.TrackData.write, obj.tracks[i]);
};

// TracksData.TrackData ========================================

TracksData.TrackData = {};

TracksData.TrackData.read = function (pbf, end) {
  return pbf.readFields(
    TracksData.TrackData._readField,
    { trackId: '', appId: '', points: [] },
    end,
  );
};
TracksData.TrackData._readField = function (tag, obj, pbf) {
  if (tag === 1) obj.trackId = pbf.readString();
  else if (tag === 2) obj.appId = pbf.readString();
  else if (tag === 3) obj.points.push(Point.read(pbf, pbf.readVarint() + pbf.pos));
};
TracksData.TrackData.write = function (obj, pbf) {
  if (obj.trackId) pbf.writeStringField(1, obj.trackId);
  if (obj.appId) pbf.writeStringField(2, obj.appId);
  if (obj.points)
    for (var i = 0; i < obj.points.length; i++) pbf.writeMessage(3, Point.write, obj.points[i]);
};
