import { ObjectId } from '@racemap/sdk/schema/base';
import {
  AdminBillingReport,
  AdminBillingReportSchema,
  InvoiceSummary,
  StripeProducts,
} from '@racemap/sdk/schema/billing';
import { EventOverviewList, EventOverviewListSchema } from '@racemap/sdk/schema/events';
import { SimCard, SimCardSchema, SimCardStates } from '@racemap/sdk/schema/simCard';
import {
  TRACKER_MESSAGE_HISTORY_RECORD_STATE,
  TRACKER_MESSAGE_TRANSPORT,
  TRACKER_PARTICIPANT_PAIRING_OPTION,
  TRACKER_TYPES,
  Tracker,
  TrackerFull,
  TrackerMessage,
  TrackerMessagePrototype,
} from '@racemap/sdk/schema/trackers';
import { UserOverview, UserOverviewSchema } from '@racemap/sdk/schema/user';
import { Headers } from 'cross-fetch';
import { Immutable } from 'immer';
import Pbf from 'pbf';
import Stripe from 'stripe';
import { EventTypes } from './consts/events';
import { USER_ROLE } from './consts/trackers';
import fetch from './fetch';
import { RanksPackage, RanksQueryFrom } from './functions/timing';
import { binariesMessagePayload, binariesTrackerMessages } from './functions/trackers';
import { TrackerMessagePrototypeMock } from './mocks/trackers';
import { TracksData } from './proto/tracks';
import { Alert, AlertRaw } from './types/alert';
import { Brand, BrandDocument, BrandPublicVersion } from './types/brand';
import {
  BasicRacemapStarter,
  BillableItemObject,
  EventLoadTypes,
  Images,
  KeyData,
  Load,
  PredictionParams,
  RacemapContestEvent,
  RacemapEvent,
  RacemapKey,
  RacemapStageEvent,
  RacemapStageGroup,
  RacemapStarter,
  RacemapTrack,
  StripeInvoiceInfo,
  TokenObject,
} from './types/events';
import {
  Geos,
  LayerObject,
  LayerPrototypeObject,
  LineStringObject,
  LineStringPropotypeObject,
  PointObject,
  PointPrototypeObject,
  RacemapFeatureCollection,
  RacemapShadowtrackFeatureCollection,
  SplitObject,
} from './types/geos';
import { RRDeviceStatus } from './types/raceResult';
import { Probe, ProbeBasic, RaceResultPingQuery } from './types/tpom-probe';
import { TrackerLegacy, TrackerMessageObject } from './types/trackers';
import {
  CountResult,
  FeibotDevice,
  JSONTimePing,
  LatLng,
  LocatedTimePing,
  NonLocatedTimePing,
  PredictionDebugData,
  PredictionDebugEventData,
  TimePing,
} from './types/trackping';
import {
  LatLngPoint,
  Ping,
  Point,
  Reader,
  SalesEmailPayload,
  TracksData as TracksDataType,
} from './types/types';
import { BillingInfo, BillingInvoice, User, User_Legacy } from './types/users';
import { DeepPartial, ID } from './types/utils';

type ISOString = string;
type QueryValue = boolean | string | number | Date | ObjectId | Record<string, any>;
type QueryObject = {
  [key: string]: QueryValue | Array<QueryValue> | undefined;
};

function convertToString(value: QueryValue): string {
  if (value instanceof Date) {
    return value.toISOString();
  }

  if (value instanceof ObjectId) {
    return value.toHexString();
  }

  if (value instanceof Object) {
    return JSON.stringify(value);
  }

  return value.toString();
}

function queryize(obj: QueryObject) {
  const out = [];
  for (const key of Object.keys(obj)) {
    const value = obj[key];

    if (
      (value != null &&
        ((typeof value === 'string' && value !== '') ||
          (typeof value === 'object' && Object.keys(value).length > 0))) ||
      (Array.isArray(value) && value.length > 0) ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      value instanceof Date ||
      value instanceof ObjectId
    ) {
      if (Array.isArray(value)) {
        out.push(`${key}=${encodeURIComponent(value.map(convertToString).join(','))}`);
      } else {
        out.push(`${key}=${encodeURIComponent(convertToString(value))}`);
      }
    }
  }
  return out.join('&');
}

export function withQuery(url: string, obj: QueryObject | null | undefined) {
  if (obj == null || Object.keys(obj).length === 0) return url;
  const query = queryize(obj);
  if (query === '') return url;
  return `${url}?${query}`;
}

type ClientProps = {
  basicAuth?: {
    email: string;
    password: string;
  };
  includeCredentials?: boolean;
};

type TimezoneObject = {
  name: string;
  id: string;
  offset: number;
};

export class HTTPFetchError extends Error {
  status: number;
  path: string;
  serverResponse: Response;
  method: Request['method'];
  serverError?: Error['message'];
  serverStack?: Error['stack'];
  serverResponseText?: string;

  constructor(
    status: number,
    serverResponse: Response,
    path: string,
    method: Request['method'],
    {
      serverError,
      serverStack,
      serverResponseText,
    }: {
      serverError?: Error['message'];
      serverStack?: Error['stack'];
      serverResponseText?: string;
    },
  ) {
    let message = `Failed to load recource | ${method.toUpperCase()} ${path} -> Status: ${status}`;

    if (serverError != null) {
      message += `\nServer Side Error: ${serverError}`;
    }

    if (serverStack != null) {
      message += `\nServer${serverStack}`;
    }
    if (serverResponseText != null) {
      message += `\n${serverResponseText}`;
    }

    super(message);
    this.status = status;
    this.path = path;
    this.serverResponse = serverResponse;
    this.serverError = serverError;
    this.serverStack = serverStack;
    this.method = method;
    this.name = 'HTTPFetchError';

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HTTPFetchError.prototype);
  }

  static async from(
    status: number,
    res: Response,
    path: string,
    method: Request['method'],
  ): Promise<HTTPFetchError> {
    const isJSON = res.headers.get('content-type')?.includes('application/json') ?? false;

    if (isJSON) {
      const errorOnServer = await res.json();
      return new HTTPFetchError(res.status, res, path, method, {
        serverError: errorOnServer.status || errorOnServer.error,
        serverStack: errorOnServer.stack,
      });
    }

    const text = await res.text();
    return new HTTPFetchError(status, res, path, method, {
      serverResponseText: text,
    });
  }
}

export enum DateFilter {
  RUNNING_EVENT = 'RUNNING_EVENT',
  START_TIME = 'START_TIME',
  END_TIME = 'END_TIME',
  UPDATED_AT = 'UPDATED_AT',
  CREATED_AT = 'CREATED_AT',
  PAID_AT = 'PAID_AT',
}

export type BaseQuery = {
  order?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
};

export type ShowValues =
  | 'hidden'
  | 'atomiceventsonly'
  | 'parentsonly'
  | 'trackpingeventsonly'
  | 'raceresultevents'
  | 'feibotevents'
  | 'metaimport';

export type EventsQuery = BaseQuery & {
  show?: Array<ShowValues>;
  sort?: 'asc' | 'desc';
  limit?: string;
  filter?: string;
  endingAfter?: Date;
  startingAfter?: Date;
  endingBefore?: Date;
  startingBefore?: Date;
  findByPartOfName?: string;
  findByPartOfNameOrLocation?: string;
  findByEventType?: string;
  findByParentId?: string;
  findByDeviceId?: string;
  findByCreator?: string;
  findByAppId?: string;
  findByEditor?: string;
  paged?: string;
  brandId?: string;
  appId?: string;
  attachRenewedAt?: string;
  overview?: string;

  // filter data with a time frame
  dateFilter?: Array<DateFilter>;
  fromDate?: Date;
  toDate?: Date;
};

export enum DBSortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum DBTimestampType {
  ReceivedAt = 'ReceivedAt',
  ReadAt = 'ReadAt',
}

export type TrackersQuery = BaseQuery & {
  show?: string;
  filter?: string;
  userId?: string;
  findByPartOfName?: string;
  tags?: Array<string>;
};

export type AllPingsCountQuery = BaseQuery & {
  eventId: string;
  startTime?: Date;
  endTime?: Date;
  timestampType?: DBTimestampType;
  dummy?: boolean;
};

export type AllPingsQuery = AllPingsCountQuery & {
  limit?: number;
  offset?: number;
  orderBy?: DBSortOrder;
};

export type TrackPingsQuery = BaseQuery & {
  transponderId?: string;
  boxId?: string;
  dummy?: boolean;
  startTime?: Date;
  endTime?: Date;
  customerIds?: Array<string>;
  onlyValidPos?: string;
  pingType?: 'BOX_PING' | 'TIMING_PING' | 'TRANSPONDER_PING';
  timestampType?: DBTimestampType;
  rssi?: string;
};

type TrackPingsManyQuery = BaseQuery & {
  transponderIds: Array<string>;
  startTime: Date;
  endTime: Date;
  dummy?: boolean;
};

type PointQuery = {
  chunkSize?: number;
  starterIds?: Array<ID>;
  range?: string;
  strict?: boolean;
  offsetMode?: 'none' | 'binarized';
};

export type ReadsQuery = {
  event_id: string;
  transponder_id: string;
};

type UserListQuery = {
  search?: string;
  limit?: number;
  skip?: number;
  sort?: Record<keyof UserOverview, 'asc' | 'desc'>;
  ids?: Array<string>;
  withEventStats?: boolean;
};

interface UserCreateQuery extends QueryObject {
  skipExternalServices?: boolean | Array<'stripe' | 'mailchimp'>;
}

export interface TrackerMessageListQuery extends QueryObject {
  trackerId?: string;
  transport?: TRACKER_MESSAGE_TRANSPORT;
  newerThen?: string;
  filter?: Array<
    'not-sent' | 'not-revoked' | 'without-response' | 'after-planned-at' | 'before-close-after'
  >;
}

type RequestOptions = RequestInit & {
  disabledHeaders?: Array<string> | null;
};

interface UpdateStarterOptions {
  query?: {
    setIfUnset?: boolean;
    appId?: string;
    force?: boolean; // overwrite app id of app registered starter if true
  };
}

type CreateStarterOptions = UpdateStarterOptions;

const DATE_FIELD_KEYS = ['lastImportAt'];

export class APIClient {
  _host: string;
  _props: ClientProps;
  _isIntegrationTest: boolean;

  constructor(host: string, props: ClientProps = {}) {
    this._host = host;
    this._props = props;
    this._isIntegrationTest = process.env.TEST_MODE === 'integration';
  }

  _headers(originalHeaders: HeadersInit | null, disabledHeaders: Array<string> | null): Headers {
    const headers = originalHeaders != null ? new Headers(originalHeaders) : new Headers();
    const auth = this._props.basicAuth;

    if (auth != null && auth.email != null && auth.password != null) {
      // check if server, because Buffer is not present in Browser
      const isServer = !(typeof window !== 'undefined' && window.document);
      const basicToken = isServer
        ? Buffer.from(`${auth.email}:${auth.password}`).toString('base64')
        : btoa(`${auth.email}:${auth.password}`);

      headers.set('Authorization', `Basic ${basicToken}`);
    }

    if (this._isIntegrationTest) {
      headers.set('x-integration-test', 'true');
    }

    if (disabledHeaders != null && disabledHeaders instanceof Array) {
      disabledHeaders.forEach((headerName) => {
        headers.delete(headerName);
      });
    }

    return headers;
  }

  _standard_reviver(this: any, key: string, value: any): any {
    if (typeof value === 'string' && DATE_FIELD_KEYS.includes(key)) {
      return new Date(value);
    }

    return value;
  }

  async _fetch(
    path: string,
    options: RequestOptions = {},
  ): Promise<
    Omit<Response, 'json'> & {
      json: (reviver?: <I, O>(key: string, value: I) => O) => Promise<any>;
    }
  > {
    const globalOptions: RequestInit = this._props.includeCredentials
      ? { credentials: 'include' }
      : {};
    const res = await fetch(`${this._host}${path}`, {
      ...globalOptions,
      ...options,
      headers: this._headers(options.headers || null, options.disabledHeaders || []),
    });

    if (!res.ok) {
      const error = await HTTPFetchError.from(res.status, res, path, options.method || 'GET');
      throw error;
    }

    // use a default reviver to parse the jsons. replace date strings with date objects for example
    // can disable the behavior, by setting reviver null
    const originalJSONParser = res.json;
    res.json = async (reviver?: <I, O>(this: any, key: string, value: I) => O | null) => {
      if (reviver === null) return originalJSONParser();
      const contentType = res.headers.get('content-type');
      if (contentType == null || contentType.indexOf('application/json') === -1) {
        console.warn(
          `Route ${path} answers. But the content-type in the response header is not a correct json type.`,
        );
        console.warn(`Setted content-type is: ${contentType}!`);
      }
      const text = await res.text();

      try {
        return JSON.parse(text, reviver || this._standard_reviver);
      } catch (err) {
        if (err instanceof SyntaxError) {
          console.log('Failed to parse json response:');
          console.log(text);
        }
        throw err;
      }
    };
    return res;
  }

  async _getJSON(path: string, options: RequestOptions = {}): Promise<any> {
    const res = await this._fetch(path, options);
    return res.json();
  }

  async _get(path: string, options = {}): Promise<Response> {
    return await this._fetch(path, options);
  }

  async _getArrayBuffer(path: string, options = {}): Promise<ArrayBuffer> {
    const res = await this._fetch(path, options);
    return res.arrayBuffer();
  }

  async _postJSON(path: string, data: Record<string, any> = {}, headers = {}): Promise<Response> {
    return await this._fetch(path, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(data),
    });
  }

  async _postXML(path: string, xml = '', headers = {}): Promise<Response> {
    return await this._fetch(path, {
      method: 'POST',
      headers: {
        Accept: 'application/xml',
        'Content-Type': 'application/xml',
        ...headers,
      },
      body: xml,
    });
  }

  async _postJSONReceiveJSON(path: string, data: Record<string, any> = {}): Promise<any> {
    const res = await this._fetch(path, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return res.json();
  }

  async _patchJSON(path: string, data: Record<string, any> = {}): Promise<any> {
    const res = await this._fetch(path, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return res.json();
  }

  async _delete(path: string): Promise<Response> {
    return this._fetch(path, {
      method: 'DELETE',
    });
  }

  async _deleteJSON(path: string, data: Record<string, any> = {}): Promise<any> {
    const res = await this._fetch(path, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return res.json();
  }

  async _postFormData(path: string, data?: string): Promise<Response> {
    return await this._fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    });
  }

  async _uploadFormData(path: string, data: Record<string, any>): Promise<any> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    const response = await this._fetch(path, {
      method: 'POST',
      redirect: 'follow',
      body: formData,
    });
    return response.json();
  }

  async _putJSON(path: string, data: Record<string, any> = {}): Promise<any> {
    const res = await this._fetch(path, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return res.json();
  }

  async _postProtobuf(path: string, data: Uint8Array): Promise<any> {
    const res = await this._fetch(path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-protobuf' },
      body: data,
    });
    return res.json();
  }

  get host(): string {
    return this._host;
  }

  get props(): ClientProps {
    return this._props;
  }
}

export class TZServiceAPICLient {
  _host: string;
  _props: ClientProps;
  _apiClient: APIClient;

  constructor(host = 'https://tz.racemap.com', props: ClientProps = {}) {
    this._host = host;
    this._props = props;
    this._apiClient = new APIClient(this._host, this._props);
  }

  async getTimezoneForLocation(lat: number, lng: number): Promise<TimezoneObject> {
    return this._apiClient._getJSON(`/api?lat=${lat}&lng=${lng}`);
  }

  async getTimezoneForLocationSafe(lat?: number, lng?: number): Promise<TimezoneObject | null> {
    if (lat == null || lng == null) return null;
    try {
      return this.getTimezoneForLocation(lat, lng);
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}

export class TrackpingAPIClient {
  _host: string;
  _props: ClientProps;
  _apiClient: APIClient;

  constructor(host: string, props: ClientProps = {}) {
    this._host = host;
    this._props = props;
    this._apiClient = new APIClient(this._host, this._props);
  }

  async getTrackpings(query: TrackPingsQuery): Promise<Array<Ping>> {
    return this._apiClient._getJSON(withQuery('/api/v2/trackping_input/raw_pings', query));
  }

  async getTrackPingsMany(query: TrackPingsManyQuery): Promise<Record<string, Array<Ping>>> {
    const result = await this._apiClient._getJSON(
      withQuery('/api/v2/trackping_input/raw_trackpings_many', query),
    );
    return result;
  }

  async getAllReadsForEvent(query: AllPingsQuery): Promise<Array<Ping>> {
    return this._apiClient._getJSON(withQuery('/api/v2/trackping_input/all_event_reads', query));
  }

  async getAllReadersForEvent(query: AllPingsQuery): Promise<Array<Reader>> {
    return this._apiClient._getJSON(withQuery('/api/v2/trackping_input/all_event_readers', query));
  }

  async getAllReadsForEventCount(query: AllPingsCountQuery): Promise<CountResult> {
    return this._apiClient._getJSON(
      withQuery('/api/v2/trackping_input/all_event_reads_count', query),
    );
  }

  async getDebug(
    eventId: string,
    starterId: string,
    predictionParamsOverride?: PredictionParams,
  ): Promise<PredictionDebugData> {
    const result = await this._apiClient._getJSON(
      withQuery('/api/v2/trackping_input/debug_pings', {
        eventId,
        starterId,
        predictionParamsOverride: predictionParamsOverride
          ? JSON.stringify(predictionParamsOverride)
          : undefined,
      }),
    );
    return result;
  }

  async getDebugEvent(
    eventId: string,
    predictionParamsOverride?: PredictionParams,
  ): Promise<PredictionDebugEventData> {
    const result = await this._apiClient._getJSON(
      withQuery('/api/v2/trackping_input/debug_event', {
        eventId,
        predictionParamsOverride: predictionParamsOverride
          ? JSON.stringify(predictionParamsOverride)
          : undefined,
      }),
    );
    return result;
  }

  // http://trackping.racemap.com/api/v2/trackping_input/pings/?v=2&custId=000000&boxId=T-20062&boxType=ATrack&boxName=Test&boxTime=171020T182452Z&boxPos=S,-37.95961,145.03355,6&count=5
  async sendTrackPings(
    query: Partial<RaceResultPingQuery> = {},
    payload?: string,
    altPath: string | null = null,
  ): Promise<Response> {
    return this._apiClient._postFormData(
      withQuery(altPath || '/api/v2/trackping_input/pings', query),
      payload,
    );
  }

  async checkTrackPingsRoute(): Promise<Response> {
    return this._apiClient._get('/api/v2/trackping_input/pings');
  }

  async sendTrackPingsNative(pings: Array<Ping>): Promise<Response> {
    return this._apiClient._postJSON('/api/v2/trackping_input/json_pings', pings);
  }

  async getConnectedFeibotDevices(feibotEventId: string): Promise<Array<FeibotDevice>> {
    return this._apiClient._getJSON(
      withQuery('/api/v1/feibot_input/connections', { eventId: feibotEventId }),
    );
  }

  async sendTimePingsAsJSON(
    timePings: Array<LocatedTimePing | NonLocatedTimePing>,
    options = { headers: {} },
    withReceivedAtOverrides = false,
  ): Promise<Response> {
    return this._apiClient._postJSON(
      withQuery('/api/v1/timing_input/pings', { withReceivedAtOverrides }),
      timePings,
      options.headers,
    );
  }

  async sendTimePingsAsXML(
    timePingsInXML: string,
    options = { headers: {} },
    withReceivedAtOverrides = false,
  ): Promise<Response> {
    return this._apiClient._postXML(
      withQuery('/api/v1/timing_input/pings', { withReceivedAtOverrides }),
      timePingsInXML,
      options.headers,
    );
  }

  async getTimePings(query: {
    timingIds: Array<string>;
    transponderIds?: Array<string>;
    startTime?: ISOString;
    endTime?: ISOString;
    firstReceive?: ISOString;
    lastReceive?: ISOString;
  }): Promise<Array<JSONTimePing>> {
    return this._apiClient._getJSON(withQuery('/api/v1/timing_output/pings', query));
  }

  async getLastTimePings(): Promise<Array<TimePing>> {
    return this._apiClient._getJSON('/api/v1/timing_output/last_pings');
  }
}

export class RacemapAPIClient {
  _host: string;
  _props: ClientProps;
  _apiClient: APIClient;

  constructor(host: string, props: ClientProps = {}) {
    this._host = host;
    this._props = props;
    this._apiClient = new APIClient(this._host, this._props);
  }

  static fromWindowLocation(includeCredentials = true): RacemapAPIClient {
    return new RacemapAPIClient(window.location.origin, {
      includeCredentials,
    });
  }

  static asSuperUser(host: string, password?: string): RacemapAPIClient {
    const superUserPassword = process.env.SUPER_USER_PASSWORD || password;
    if (superUserPassword == null || superUserPassword === '') {
      throw new Error('Super User Password is invalid');
    }
    return new RacemapAPIClient(host, {
      basicAuth: {
        email: 'super-user@racemap.com',
        password: superUserPassword,
      },
    });
  }

  static fromEnvVars(): RacemapAPIClient {
    const host = process.env.API_HOST || process.env.HOST;
    if (host == null || host === '') {
      throw new Error('API_HOST is invalid or missing');
    }
    const superUserPassword = process.env.SUPER_USER_PASSWORD;
    if (superUserPassword == null || superUserPassword === '') {
      throw new Error('SUPER_USER_PASSWORD is invalid or missing');
    }
    return new RacemapAPIClient(host, {
      basicAuth: {
        email: 'super-user@racemap.com',
        password: superUserPassword,
      },
    });
  }

  asAnonymous(): RacemapAPIClient {
    return new RacemapAPIClient(this._host);
  }

  trackping(): TrackpingAPIClient {
    return new TrackpingAPIClient(`${this._host}/services/trackping`, this._props);
  }

  async getJSON(path: string, options = {}): Promise<any> {
    return this._apiClient._getJSON(path, options);
  }

  // TODO: define the basic user type
  async createUser(
    newUser: Partial<User_Legacy>,
    query: UserCreateQuery = {},
  ): Promise<User_Legacy> {
    return this._apiClient._postJSONReceiveJSON(withQuery('/api/users', query), newUser);
  }

  async getUser(userId: ID): Promise<User_Legacy> {
    return this._apiClient._getJSON(`/api/users/${userId}`);
  }

  async getUsers(query?: UserListQuery): Promise<{ users: Array<User_Legacy>; total: number }> {
    return this._apiClient._getJSON(withQuery('/api/users', query));
  }

  async userLookup(query: { email?: string; userId?: string }): Promise<UserOverview | null> {
    const res = await this._apiClient._getJSON(withQuery('/api/users/lookup', query));
    if (res == null) return null;

    return UserOverviewSchema.parse(res);
  }

  async getCurrentUser(): Promise<User_Legacy> {
    return this._apiClient._getJSON('/api/users/current');
  }

  async updateUser(userId: ID, patchObject: Partial<User_Legacy>): Promise<User> {
    return this._apiClient._patchJSON(`/api/users/${userId}`, patchObject);
  }

  async removeUser(userId: ID): Promise<Response> {
    return this._apiClient._delete(`/api/users/${userId}`);
  }

  async purgeUsers({
    testUsers,
    unconfirmedUsers,
  }: { testUsers?: boolean; unconfirmedUsers?: boolean }): Promise<{ removed: number }> {
    return this._apiClient._deleteJSON('/api/users', { testUsers, unconfirmedUsers });
  }

  async login(email?: string, password?: string): Promise<User> {
    if ((email == null || password == null) && this._props.basicAuth != null) {
      return this._apiClient._postJSONReceiveJSON('/api/login', this._props.basicAuth);
    }
    if (email != null || password != null) {
      return this._apiClient._postJSONReceiveJSON('/api/login', {
        email,
        password,
      });
    }
    throw new Error('email and password are required for login');
  }

  async logout(): Promise<any> {
    await this._apiClient._get('/api/logout');
  }

  async register(name: string, email: string, password: string): Promise<any> {
    return this._apiClient._postJSONReceiveJSON('/api/users', {
      name,
      email,
      password,
    });
  }

  async resetPassword(password: string, userId: string, token: string): Promise<User> {
    return this._apiClient._postJSONReceiveJSON('/api/users/actions/reset-password', {
      password,
      userId,
      token,
    });
  }

  async requestPasswordReset(email: string): Promise<void> {
    await this._apiClient._postJSON('/api/users/actions/forgot-password', {
      email,
    });
  }

  async getTrackers(options: TrackersQuery = {}): Promise<Array<TrackerLegacy>> {
    const url = '/api/trackers';
    return (await this._apiClient._getJSON(withQuery(url, options))).map(binariesTrackerMessages);
  }

  async getTracker(
    trackerId: ID,
    options: { searchBy?: 'tracker-id' | 'rfid-uid' } = {},
  ): Promise<TrackerLegacy> {
    return binariesTrackerMessages(
      await this._apiClient._getJSON(withQuery(`/api/trackers/${trackerId}`, options)),
    );
  }

  async updateTracker(
    trackerId: ID,
    trackerPatch: Partial<TrackerLegacy | Tracker>,
    options: { searchBy?: string } = {},
  ): Promise<TrackerFull> {
    return binariesTrackerMessages(
      await this._apiClient._patchJSON(
        withQuery(`/api/trackers/${trackerId}`, options),
        trackerPatch,
      ),
    );
  }

  async updateTrackers(
    trackerIds: Array<ID>,
    patchObj: DeepPartial<TrackerLegacy | Tracker>,
  ): Promise<Array<TrackerFull>> {
    const payload = {
      trackerIds,
      patchObj,
    };

    return (await this._apiClient._patchJSON('/api/trackers/action/bulkUpdate', payload)).map(
      binariesTrackerMessages,
    );
  }

  async addTrackers(
    newTrackers: Array<
      Partial<TrackerLegacy> & {
        trackerId: string;
        trackerName: string;
        trackerType: string;
      }
    >,
  ): Promise<Array<TrackerLegacy>> {
    return (await this._apiClient._postJSONReceiveJSON('/api/trackers', newTrackers)).map(
      binariesTrackerMessages,
    );
  }

  async deleteTracker(trackerId: ID): Promise<Response> {
    return this._apiClient._deleteJSON(`/api/trackers/${trackerId}`);
  }

  async deleteTrackers(trackerIds: Array<ID>): Promise<void> {
    await this._apiClient._deleteJSON('/api/trackers', { trackerIds });
  }

  async addUserToTrackers(
    trackerIds: Array<ID>,
    userId: string,
    role: USER_ROLE,
    startTime?: string,
    endTime?: string,
  ): Promise<Array<TrackerLegacy>> {
    return (
      await this._apiClient._postJSONReceiveJSON('/api/trackers/actions/addUser', {
        trackerIds,
        userId,
        role,
        startTime,
        endTime,
      })
    ).map(binariesTrackerMessages);
  }

  async confirmEmail(userId: ID, token?: string): Promise<void> {
    await this._apiClient._get(
      withQuery('/api/users/actions/confirm-email', { user: userId, token }),
    );
  }

  async getEvents(query?: EventsQuery): Promise<Array<RacemapEvent>> {
    if (query != null) return this._apiClient._getJSON(`/api/events?${queryize(query)}`);
    return this._apiClient._getJSON('/api/events');
  }

  async getEventsOverview(): Promise<EventOverviewList> {
    const events = await this._apiClient._getJSON('/api/events/overview');
    return EventOverviewListSchema.parseAsync(events);
  }

  async getEventsPaged(
    query: EventsQuery,
    pageSize: number,
    lastKey?: Date,
  ): Promise<{ data: Array<RacemapEvent>; lastKey: Date }> {
    return this._apiClient._getJSON(
      `/api/events?${queryize({
        ...query,
        paged: [pageSize, lastKey].join(','),
      })}`,
    );
  }

  async createStageGroup(newStageGroup: Partial<RacemapEvent> = {}): Promise<RacemapStageGroup> {
    return this._apiClient._postJSONReceiveJSON('/api/events', {
      ...newStageGroup,
      type: EventTypes.STAGE_GROUP,
    });
  }

  async addStageToStageGroup(stageGroupId: ID): Promise<RacemapStageEvent> {
    return this._apiClient._postJSONReceiveJSON(`/api/events/${stageGroupId}/stages`);
  }

  async getStageGroupStages(stageGroupId: ID): Promise<Array<RacemapStageEvent>> {
    return this._apiClient._getJSON(`/api/events/${stageGroupId}/stages`);
  }

  async getContestGroupContests(contesGroupId: ID): Promise<Array<RacemapContestEvent>> {
    return this._apiClient._getJSON(`/api/events/${contesGroupId}/contests`);
  }

  async getEvent(eventId: ID, appId?: string): Promise<RacemapEvent> {
    return this._apiClient._getJSON(
      `/api/events/${eventId}${appId != null ? `?appId=${appId}` : ''}`,
    );
  }

  async createEvent(newEvent: Partial<RacemapEvent> = {}): Promise<RacemapEvent> {
    return this._apiClient._postJSONReceiveJSON('/api/events', newEvent);
  }

  async removeEvent(eventId: ID): Promise<Response> {
    return this._apiClient._delete(`/api/events/${eventId}`);
  }

  async duplicateEvent(
    eventId: ID,
    options: {
      withStarters?: boolean;
      withActivation?: boolean;
      withGeoElements?: boolean;
    } = {},
    newEventProps: Partial<RacemapEvent> = {},
  ): Promise<RacemapEvent> {
    if (options != null)
      return this._apiClient._postJSONReceiveJSON(
        withQuery(`/api/events/${eventId}/actions/duplicate`, options),
        newEventProps,
      );
    return this._apiClient._postJSONReceiveJSON(
      `/api/events/${eventId}/actions/duplicate`,
      newEventProps,
    );
  }

  async updateEvent(
    eventId: ID,
    patchObject: Immutable<Partial<RacemapEvent>>,
  ): Promise<RacemapEvent> {
    return this._apiClient._patchJSON(`/api/events/${eventId}`, patchObject);
  }

  async getEventGeo(eventId: ID): Promise<RacemapFeatureCollection> {
    return this._apiClient._getJSON(`/api/events/${eventId}/geo`);
  }

  async getEventStarters(
    eventId: ID,
    options: {
      appId?: string;
      deviceId?: string;
      filter?: Array<string>;
      noFilter?: boolean;
    } = {},
  ): Promise<Array<RacemapStarter>> {
    const { noFilter, ...query } = options;

    return this._apiClient._getJSON(
      withQuery(`/api/events/${eventId}/starters`, {
        ...query,
        filter: noFilter ? ['no-filter'] : query.filter,
      }),
    );
  }

  async getEventGeoTrack(eventId: ID): Promise<RacemapFeatureCollection> {
    return this._apiClient._getJSON(`/api/events/${eventId}/geo/track.json`);
  }

  async getEventShadowGeo(
    eventId: ID,
    options: RequestOptions = {},
  ): Promise<RacemapShadowtrackFeatureCollection> {
    return this._apiClient._getJSON(`/api/events/${eventId}/geo/shadow.json`, {
      disabledHeaders: ['Authorization'],
      ...options,
    });
  }

  async getEventGeosLegacy(eventId: ID): Promise<Geos> {
    return this._apiClient._getJSON(`/api/events/${eventId}/geo_legacy`, {
      disabledHeaders: ['Authorization'],
    });
  }

  async getEventGeosLegacyFormat(eventId: ID): Promise<{
    eventId: string;
    id: string;
    geojson: RacemapFeatureCollection;
    shadowgeojson: RacemapShadowtrackFeatureCollection;
  }> {
    return this._apiClient._getJSON(`/api/events/${eventId}/geo_legacy_format`, {
      disabledHeaders: ['Authorization'],
    });
  }

  async getEventChunkedShadowGeo(eventId: ID): Promise<Array<LatLng>> {
    return this._apiClient._getJSON(`/api/events/${eventId}/geo/shadow.chunked.json`, {
      disabledHeaders: ['Authorization'],
      credentials: 'same-origin',
    });
  }

  async getEventShadowTrack(eventId: ID): Promise<Array<LatLngPoint> | null> {
    const buffer = await this._apiClient._getArrayBuffer(
      `/api/events/${eventId}/geo/shadow.chunked.bin`,
      {
        disabledHeaders: ['Authorization'],
      },
    );
    if (buffer == null) {
      return null;
    }
    const shadowTrack = [];
    const shadowTrackArray = new Float64Array(buffer);
    for (let i = 0; i < shadowTrackArray.length / 3; i++) {
      shadowTrack.push({
        lng: shadowTrackArray[i * 3],
        lat: shadowTrackArray[i * 3 + 1],
        elv: shadowTrackArray[i * 3 + 2],
      });
    }
    return shadowTrack;
  }

  async getEventSplits(eventId: ID): Promise<Array<SplitObject>> {
    return this._apiClient._getJSON(`/api/events/${eventId}/geo/splits`);
  }

  async listGeoLineStrings(eventId?: string): Promise<Array<LineStringObject>> {
    return this._apiClient._getJSON(withQuery('/api/geo/linestrings', { eventId }));
  }

  async createGeoLineString<
    RE extends LineStringObject = LineStringObject,
    GE extends LineStringPropotypeObject = LineStringPropotypeObject,
  >(lineString: GE, { isShadowtrack = false }: { isShadowtrack?: boolean } = {}): Promise<RE> {
    return this._apiClient._postJSONReceiveJSON(
      withQuery('/api/geo/linestrings', { shadowtrack: String(isShadowtrack) }),
      lineString,
    );
  }

  async getGeoLineString(lineStringId: string): Promise<LineStringObject> {
    return this._apiClient._getJSON(`/api/geo/linestrings/${lineStringId}`);
  }

  async updateGeoLineString(
    lineStringId: string,
    lineString: Partial<LineStringObject>,
  ): Promise<LineStringObject> {
    return this._apiClient._patchJSON(`/api/geo/linestrings/${lineStringId}`, lineString);
  }

  async removeGeoLineString(lineStringId: string): Promise<Response> {
    return this._apiClient._delete(`/api/geo/linestrings/${lineStringId}`);
  }

  async listGeoPoints(eventId?: string): Promise<Array<PointObject>> {
    return this._apiClient._getJSON(withQuery('/api/geo/points', { eventId }));
  }

  async createGeoPoint<
    RE extends PointObject = PointObject,
    GE extends PointPrototypeObject = PointPrototypeObject,
  >(point: GE): Promise<RE> {
    return this._apiClient._postJSONReceiveJSON('/api/geo/points', point);
  }

  async getGeoPoint(pointId: string): Promise<PointObject> {
    return this._apiClient._getJSON(`/api/geo/points/${pointId}`);
  }

  async updateGeoPoint(pointId: string, point: PointObject): Promise<PointObject> {
    return this._apiClient._patchJSON(`/api/geo/points/${pointId}`, point);
  }

  async removeGeoPoint(pointId: string): Promise<Response> {
    return this._apiClient._delete(`/api/geo/points/${pointId}`);
  }

  async listGeoLayers(eventId?: string): Promise<Array<LayerObject>> {
    return this._apiClient._getJSON(withQuery('/api/geo/layers', { eventId }));
  }

  async createGeoLayer<
    RE extends LayerObject = LayerObject,
    GE extends LayerPrototypeObject = LayerPrototypeObject,
  >(layer: GE): Promise<RE> {
    return this._apiClient._postJSONReceiveJSON('/api/geo/layers', layer);
  }

  async getGeoLayer(layerId: string): Promise<LayerObject> {
    return this._apiClient._getJSON(`/api/geo/layers/${layerId}`);
  }

  async updateGeoLayer(layerId: string, layer: LayerObject): Promise<LayerObject> {
    return this._apiClient._patchJSON(`/api/geo/layers/${layerId}`, layer);
  }

  async removeGeoLayer(layerId: string): Promise<Response> {
    return this._apiClient._delete(`/api/geo/layers/${layerId}`);
  }

  async clearGeos(eventId: string): Promise<void> {
    await this._apiClient._postJSON(`/api/events/${eventId}/geo/actions/clear`);
  }

  async getEventPointsRaw(eventId: ID, options: PointQuery = {}): Promise<ArrayBuffer> {
    return this._apiClient._getArrayBuffer(
      `/api/events/${eventId}/points.bin?${queryize(options)}`,
    );
  }

  async initiateEventPayment(
    eventId: ID,
    options: { autoCollect: boolean },
  ): Promise<StripeInvoiceInfo> {
    return this._apiClient._postJSONReceiveJSON(
      withQuery(`/api/events/${eventId}/actions/initiate_payment`, {
        auto_collect: options.autoCollect,
      }),
    );
  }

  async checkEventPayment(eventId: ID): Promise<StripeInvoiceInfo> {
    return this._apiClient._getJSON(`/api/events/${eventId}/actions/check_payment`);
  }

  async getEventStarterKeys(eventId: ID): Promise<Array<RacemapKey>> {
    return this._apiClient._getJSON(`/api/events/${eventId}/starter_keys`);
  }

  async createEventStarterKeys(eventId: ID, count = 10): Promise<Array<RacemapKey>> {
    return this._apiClient._getJSON(
      `/api/events/${eventId}/actions/generate_starter_keys?count=${count}`,
    );
  }

  async connectDevicesWithStarter(
    deviceIds: Array<ID>,
    eventId: ID,
    options?: { pairingOption?: TRACKER_PARTICIPANT_PAIRING_OPTION },
  ): Promise<Record<ID, ID>> {
    return await this._apiClient._postJSONReceiveJSON(
      withQuery(`/api/events/${eventId}/actions/connect_starters_with_devices`, options),
      deviceIds,
    );
  }

  async checkEventStarterKey(
    key: string,
    options: { starterId?: string; usedOk?: boolean } = {},
  ): Promise<KeyData> {
    const { starterId, usedOk } = options;
    return this._apiClient._getJSON(
      // eslint-disable-next-line camelcase
      `/api/starters/actions/check_key?${queryize({
        key,
        starterId,
        used_ok: usedOk,
      })}`,
    );
  }

  async linkEventStarterKey(
    key: string,
    appId: string,
    starter: Partial<RacemapStarter>,
    options: { noRedirect?: boolean; notMarkUsed?: boolean } = {},
  ): Promise<RacemapStarter> {
    return await this._apiClient._postJSONReceiveJSON(
      `/api/starters/actions/link_with_key?${queryize({
        key,
        appId,
        noRedirect: options.noRedirect ? 'true' : undefined,
        notMarkUsed: options.notMarkUsed ? 'true' : undefined,
      })}`,
      starter,
    );
  }

  async generateKeyQRCode(key: string, options: { errorLevel?: string } = {}): Promise<KeyData> {
    let { errorLevel } = options;
    if (errorLevel == null) errorLevel = 'L';
    return this._apiClient._getJSON(
      `/api/starters/actions/generate_key_qrcode?${queryize({
        key,
        errorLevel,
      })}`,
    );
  }

  async createStarter(
    starter: Partial<RacemapStarter>,
    options: CreateStarterOptions = {},
  ): Promise<RacemapStarter> {
    return this._apiClient._postJSONReceiveJSON(withQuery('/api/starters', options.query), starter);
  }

  async createStarters(
    starters:
      | Array<RacemapStarter>
      | RacemapStarter
      | Array<BasicRacemapStarter>
      | BasicRacemapStarter,
    eventId: ID,
  ): Promise<Array<RacemapStarter>> {
    return this._apiClient._postJSONReceiveJSON(`/api/events/${eventId}/starters`, starters);
  }

  async getStarters(appId?: string, genericImportKey?: string): Promise<Array<RacemapStarter>> {
    return this._apiClient._getJSON(withQuery('/api/starters', { appId, genericImportKey }));
  }

  async getStarter(starterId: ID, query: { key?: string } = {}): Promise<RacemapStarter> {
    const { key } = query;
    return this._apiClient._getJSON(
      `/api/starters/${starterId}${key != null ? `?key=${key}` : ''}`,
    );
  }

  async updateStarter(
    starterId: ID,
    updatedStarter: Partial<RacemapStarter>,
    options: UpdateStarterOptions = {},
  ): Promise<RacemapStarter> {
    return this._apiClient._patchJSON(
      withQuery(`/api/starters/${starterId}`, options.query),
      updatedStarter,
    );
  }

  async removeStarter(starterId: ID): Promise<Response> {
    return this._apiClient._delete(`/api/starters/${starterId}`);
  }

  async removeStarters(starterIds: Array<ID>, eventId: ID): Promise<Array<RacemapStarter>> {
    return this._apiClient._deleteJSON(`/api/events/${eventId}/starters`, starterIds);
  }

  async createTrack(appId: string): Promise<RacemapTrack> {
    return this._apiClient._postJSONReceiveJSON('/api/tracks', { appId });
  }

  async removeTrack(trackId: string): Promise<Response> {
    return this._apiClient._delete(`/api/tracks/${trackId}`);
  }

  async getTracksWithAppId(appId: string): Promise<Array<RacemapTrack>> {
    return this._apiClient._getJSON(`/api/tracks?app_id=${appId}`);
  }

  async createTPOMProbe(newProbe: ProbeBasic): Promise<Probe> {
    return this._apiClient._postJSONReceiveJSON('/api/probes', newProbe);
  }

  async getTPOMProbes(query: QueryObject): Promise<Array<Probe>> {
    return this._apiClient._getJSON(withQuery('/api/probes', query));
  }

  async addPoints(pointList: {
    appId: string;
    points: Array<Point>;
  }): Promise<void> {
    await this._apiClient._postJSON('/api/tracks/points', pointList);
  }
  async addPointsToTrack(trackId: string, points: Array<Point>): Promise<void> {
    await this._apiClient._postJSON(`/api/tracks/${trackId}/points`, points);
  }

  async getPoints(track_id: string, startTime: Date, endTime: Date): Promise<Array<Point>> {
    const rawPoints: Array<{
      lat: number;
      lng: number;
      time: string;
      receivedAt: string;
      elv?: number;
    }> = await this._apiClient._getJSON(
      withQuery(`/api/tracks/${track_id}/points`, {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      }),
    );
    return rawPoints.map((p) => ({
      lat: p.lat,
      lng: p.lng,
      elv: p.elv,
      receivedAt: new Date(p.receivedAt),
      time: new Date(p.time),
    }));
  }

  async postPointBulkByProtobuf(
    payload: TracksDataType,
  ): Promise<Array<{ status: number; message?: string }>> {
    const pbf = new Pbf();
    TracksData.write?.(payload, pbf);
    const buffer = pbf.finish();
    return this._apiClient._postProtobuf('/api/tracks/points/bulk', buffer);
  }

  async removePoints(trackId: string, startTime: Date, endTime: Date): Promise<Response> {
    return this._apiClient._delete(
      withQuery(`/api/tracks/${trackId}/points`, {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      }),
    );
  }

  async getAllApiTokens(): Promise<Array<TokenObject>> {
    return this._apiClient._getJSON('/api/users/api_tokens');
  }

  async triggerEventLoad(eventId: ID, loadType: EventLoadTypes, time?: Date): Promise<void> {
    await this._apiClient._postJSON(`/api/events/${eventId}/loads`, {
      loadType,
      time,
    });
  }

  async getEventLoads(eventId: ID, startTime?: Date, endTime?: Date): Promise<Array<Load>> {
    return this._apiClient._getJSON(
      withQuery(`/api/events/${eventId}/loads`, { startTime, endTime }),
    );
  }

  async getUserBillingInfo(userId: ID, startTime?: Date, endTime?: Date): Promise<BillingInfo> {
    return this._apiClient._getJSON(
      withQuery(`/api/users/${userId}/billing_info`, { startTime, endTime }),
    );
  }

  async getPrice(productKey: string): Promise<Stripe.Price> {
    return this._apiClient._getJSON(`/api/billing/price/${productKey}`);
  }

  async getPriceList({ isReseller }: { isReseller?: boolean } = {}): Promise<{
    list: Record<StripeProducts, Stripe.Price>;
    isReseller: boolean;
  }> {
    return this._apiClient._getJSON(withQuery('/api/billing/pricelist', { isReseller }));
  }

  async getMonthlyInvoice({
    month,
    userId,
  }: { month: string; userId?: string }): Promise<InvoiceSummary> {
    return this._apiClient._getJSON(withQuery(`/api/billing/invoice/${month}`, { userId }));
  }

  async getUserBillingInvoices(userId: ID): Promise<Array<BillingInvoice>> {
    return this._apiClient._getJSON(`/api/users/${userId}/billing_invoices`);
  }

  async flushBillingCache(): Promise<void> {
    await this._apiClient._postJSON('/services/rust-points/actions/flush_billing_cache');
  }

  async generateNewToken(userId: ID): Promise<User> {
    return this._apiClient._postJSONReceiveJSON(`/api/users/${userId}/actions/generate_api_token`);
  }

  async getBillableItems(eventId: ID): Promise<Array<BillableItemObject>> {
    return this._apiClient._getJSON(`/api/events/${eventId}/billable_items`);
  }

  async getBillableTrackers(
    userId: ID,
    startTime?: Date,
    endTime?: Date,
  ): Promise<Array<Partial<TrackerLegacy>>> {
    return this._apiClient._getJSON(
      withQuery(`/api/users/${userId}/billable_trackers`, {
        startTime,
        endTime,
      }),
    );
  }

  async getAdminBillingReport(month?: string): Promise<AdminBillingReport> {
    const items = await this._apiClient._getJSON(withQuery('/api/billing/report', { month }));
    return AdminBillingReportSchema.parseAsync(items);
  }

  async uploadEventImage(data: Record<'sponsorLogo' | 'image' | 'poiIcon', File>): Promise<Images> {
    return this._apiClient._uploadFormData('/api/events/image/', data);
  }

  async getStarterRanks(eventId: string, from?: RanksQueryFrom): Promise<RanksPackage> {
    return this._apiClient._getJSON(withQuery(`/api/data/v1/${eventId}/ranks`, { from }));
  }

  async getStarterTimes(eventId: string): Promise<any> {
    return this._apiClient._getJSON(`/api/data/v1/${eventId}/times`);
  }

  async getStarterTimesSplit(eventId: string, splitId: string): Promise<any> {
    return this._apiClient._getJSON(`/api/data/v1/${eventId}/times/${splitId}`);
  }

  // TODO: add return type
  async getStarterCurrent(eventId: string): Promise<any> {
    return this._apiClient._getJSON(`/api/data/v1/${eventId}/current`);
  }

  async getStarterGapDistance(eventId: string): Promise<any> {
    return this._apiClient._getJSON(`/api/data/v1/${eventId}/distance`);
  }

  async getRemoteContent(sourceURL = ''): Promise<Response> {
    return this._apiClient._fetch(
      withQuery('/api/cors/get_remote_content', {
        source: encodeURIComponent(sourceURL),
      }),
    );
  }

  async listBrands(): Promise<Array<Brand>> {
    return this._apiClient._getJSON('/api/brands');
  }

  async createBrand(brand: Partial<Brand>): Promise<Brand> {
    return this._apiClient._postJSONReceiveJSON('/api/brands', brand);
  }

  async getBrand(brandId: ID): Promise<Brand> {
    return this._apiClient._getJSON(`/api/brands/${brandId}`);
  }

  async getBrandByAppIdentifier(appIdentifier: string): Promise<Brand | BrandPublicVersion> {
    return this._apiClient._getJSON(`/api/brands/lookup?appIdentifier=${appIdentifier}`);
  }

  async updateBrand(brandId: ID, patch: Partial<Brand>): Promise<Brand> {
    return this._apiClient._patchJSON(`/api/brands/${brandId}`, patch);
  }

  async deleteBrand(brandId: ID): Promise<void> {
    await this._apiClient._delete(`/api/brands/${brandId}`);
  }

  async uploadBrandAsset(brandId: ID, asset: Record<string, any>): Promise<any> {
    return this._apiClient._uploadFormData(`/api/brands/${brandId}/asset`, asset);
  }

  async getBrandAsset(brandId: ID, assetName: keyof BrandDocument): Promise<any> {
    return this._apiClient._get(withQuery(`/api/brands/${brandId}/asset`, { asset: assetName }));
  }

  async listAlertsByEvent(
    eventId: string,
    startTime?: Date,
    endTime?: Date,
  ): Promise<Array<Alert>> {
    return this._apiClient._getJSON(
      withQuery(`/api/events/${eventId}/alerts`, {
        startTime,
        endTime,
      }),
    );
  }

  async getAlertbyId(alertId: string): Promise<Alert> {
    return this._apiClient._getJSON(`/api/alerts/${alertId}`);
  }

  async createAlerts(
    alert: AlertRaw & { eventId?: string; deviceId?: string; occuredAt?: Date },
  ): Promise<Array<Alert>> {
    return this._apiClient._postJSONReceiveJSON('/api/alerts', alert);
  }

  async updateAlert(alertId: string, alert: Alert): Promise<Alert> {
    return this._apiClient._patchJSON(`/api/alerts/${alertId}`, alert);
  }

  async removeAlert(alertId: string): Promise<Response> {
    return this._apiClient._delete(`/api/alerts/${alertId}`);
  }

  async solveAlerts(alertIds: Array<ObjectId | string>): Promise<Response> {
    return this._apiClient._postJSON(withQuery('/api/alerts/actions/solve', { alertIds }));
  }

  async sendSalesEmail(payload: SalesEmailPayload): Promise<void> {
    await this._apiClient._postJSON('/api/rpc/send-sales-email', payload);
  }

  async listTrackerMessages(
    query: TrackerMessageListQuery = {},
  ): Promise<Array<TrackerMessageObject>> {
    return (await this._apiClient._getJSON(withQuery('/api/trackers/messages', query))).map(
      binariesMessagePayload,
    );
  }

  async createTrackerMessage(
    trackerMessage:
      | (Omit<TrackerMessagePrototype, 'payload'> & {
          payload: { type: 'Buffer'; data: Array<number> };
        })
      | TrackerMessagePrototypeMock,
  ): Promise<TrackerMessageObject> {
    const createdTrackerMessage = await this._apiClient._postJSONReceiveJSON(
      `/api/trackers/${trackerMessage.trackerId}/messages`,
      trackerMessage,
    );
    return binariesMessagePayload(createdTrackerMessage);
  }

  async getTrackerMessage(messageId: string): Promise<TrackerMessageObject> {
    return binariesMessagePayload(
      await this._apiClient._getJSON(`/api/trackers/messages/${messageId}`),
    );
  }

  async updateTrackerMessage(
    messageId: string,
    messageUpdate: Immutable<Partial<TrackerMessageObject | TrackerMessage>>,
  ): Promise<TrackerMessageObject> {
    return binariesMessagePayload(
      await this._apiClient._patchJSON(`/api/trackers/messages/${messageId}`, messageUpdate),
    );
  }

  async removeTrackerMessage(messageId: string): Promise<void> {
    await this._apiClient._delete(`/api/trackers/messages/${messageId}`);
  }

  async revokeTrackerMessage(messageId: string): Promise<void> {
    await this._apiClient._postJSON(`/api/trackers/messages/${messageId}/actions/revoke`);
  }

  async revokeAllTrackerMessages(trackerIds: Array<ID>): Promise<void> {
    await this._apiClient._postJSON('/api/trackers/messages/actions/revoke', { trackerIds });
  }

  async clearTrackerMessage(messageId: string): Promise<void> {
    await this._apiClient._postJSON(`/api/trackers/messages/${messageId}/actions/clear`);
  }

  async clearAllTrackerMessages(trackerIds: Array<ID>): Promise<void> {
    await this._apiClient._postJSON('/api/trackers/messages/actions/clear', { trackerIds });
  }

  async addAttemptToTrackerMessage(messageId: string, sentAt: Date): Promise<TrackerMessageObject> {
    return this._apiClient._postJSONReceiveJSON(
      `/api/trackers/messages/${messageId}/actions/attempt`,
      {
        sentAt,
      },
    );
  }

  async getRRDevices(
    deviceType: TRACKER_TYPES.RRDecoder | TRACKER_TYPES.RRTrackbox | TRACKER_TYPES.RRUbidium,
  ): Promise<Array<RRDeviceStatus>> {
    return this._apiClient._getJSON(
      withQuery('/api/integrations/raceresult/devices', { deviceType }),
    );
  }

  async syncWithRaceResult(): Promise<void> {
    await this._apiClient._postJSON('/api/integrations/raceresult/actions/sync');
  }

  async addErrorToTrackerMessage(
    messageId: string,
    errorMessage: string,
    statusCode = TRACKER_MESSAGE_HISTORY_RECORD_STATE.ERROR,
  ): Promise<TrackerMessageObject> {
    return this._apiClient._postJSONReceiveJSON(
      `/api/trackers/messages/${messageId}/actions/error`,
      {
        errorMessage,
        statusCode,
      },
    );
  }

  async checkHealth(): Promise<{ status: string }> {
    return this._apiClient._getJSON('/api/health');
  }

  async acknowledgeTrackerMessage(
    trackerId: string,
    responseId: string,
    response: {
      responsePayload: { type: 'Buffer'; data: Array<number> } | undefined;
      receivedAt: Date;
      statusCode: string;
      statusMessage?: string;
    },
  ): Promise<TrackerMessageObject> {
    return binariesMessagePayload(
      await this._apiClient._postJSONReceiveJSON(
        `/api/trackers/${trackerId}/messages/actions/acknowledge`,
        {
          responseId,
          historyRecordInfo: {
            statusCode: this.responseMessageHisotryRecordMapping(response.statusCode),
            statusMessage: response.statusMessage,
          },
        },
      ),
    );
  }

  responseMessageHisotryRecordMapping = (responseStatusCode: string): string => {
    switch (responseStatusCode) {
      case 'OK':
        return 'COMPLETE';
      case 'FAILED':
        return 'ERROR';
      default:
        return responseStatusCode;
    }
  };

  async syncWithStripe({ userIds }: { userIds?: Array<string> } = {}): Promise<Response> {
    return await this._apiClient._postFormData(
      withQuery('/api/billing/actions/sync_stripe', { userIds }),
    );
  }

  async registerBillableDevices(
    starterIdTimestampMap: Record<string, [string]>,
    query: { billingDate?: Date } = {},
  ): Promise<Response> {
    return await this._apiClient._postJSON(
      withQuery('/api/starters/actions/register_billable', query),
      starterIdTimestampMap,
    );
  }

  async listSims(params: {
    search?: string;
    userId?: string;
    state?: SimCardStates;
    limit?: number;
    skip?: number;
  }): Promise<Array<SimCard>> {
    const data = await this._apiClient._getJSON(withQuery('/api/sim-cards', params));
    return data.map((sim: unknown) => SimCardSchema.parse(sim));
  }

  async getSim({ simId }: { simId: string | ObjectId }): Promise<SimCard> {
    const data = await this._apiClient._getJSON(`/api/sim-cards/${simId.toString()}`);
    return SimCardSchema.parse(data);
  }

  async createSim({ sim }: { sim: Partial<SimCard> }): Promise<SimCard> {
    const data = await this._apiClient._postJSONReceiveJSON('/api/sim-cards', sim);
    return SimCardSchema.parse(data);
  }

  async updateSim({
    simId,
    patch,
  }: { simId: string | ObjectId; patch: Partial<SimCard> }): Promise<SimCard> {
    const data = await this._apiClient._patchJSON(`/api/sim-cards/${simId}`, patch);
    return SimCardSchema.parse(data);
  }

  async removeSim({ simId }: { simId: string | ObjectId }): Promise<Response> {
    return await this._apiClient._delete(`/api/sim-cards/${simId.toString()}`);
  }

  async lookupSim({
    iccid,
    msisdn,
    friendlyName,
  }: { iccid?: string; msisdn?: string; friendlyName?: string }): Promise<SimCard> {
    const data = await this._apiClient._getJSON(
      withQuery('/api/sim-cards/lookup', { iccid, msisdn, friendlyName }),
    );
    return SimCardSchema.parse(data);
  }

  async activateSims({ simIds }: { simIds: Array<string | ObjectId> }): Promise<{
    totalNumSims: number;
    numSimsToActivate: number;
  }> {
    return await this._apiClient._postJSONReceiveJSON('/api/sim-cards/actions/activate', {
      simIds,
    });
  }

  async terminateSims({ simIds }: { simIds: Array<string | ObjectId> }): Promise<{
    totalNumSims: number;
    numSimsToTerminate: number;
  }> {
    return await this._apiClient._postJSONReceiveJSON('/api/sim-cards/actions/terminate', {
      simIds,
    });
  }

  async sendSmsToSimCards({
    simCardIds,
    message,
  }: {
    simCardIds: Array<string | ObjectId>;
    message: string;
  }): Promise<{ success: number }> {
    return await this._apiClient._postJSONReceiveJSON('/api/sim-cards/actions/send_sms', {
      simCardIds,
      message,
    });
  }
}
