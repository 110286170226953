import TestSelectors from '@racemap/utilities/consts/test_selectors';
import { Button } from 'antd';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IconPlus } from '../Icon';

export const AddTrackingMapButton: FC<{ compact?: boolean }> = ({ compact }) => {
  const history = useHistory();
  const location = useLocation();

  if (location.pathname.startsWith('/admin/events/')) {
    return null;
  }

  return (
    <Button
      onClick={() => history.push('/admin/events/new')}
      id={TestSelectors.admin.nav.buttons.newEvent.toString()}
      ghost
      type="primary"
      icon={<IconPlus />}
      className="add-tracking-map-button"
    >
      {compact ? '' : 'Tracking'} Map
    </Button>
  );
};
