import { makeApi, makeEndpoint, prefixApi } from '@zodios/core';
import { z } from 'zod';
import { AnswerSchema, SearchResultsSchema } from '../schema/support';

export const prefix = '/api/v1/support';

const askQuestion = makeEndpoint({
  method: 'post',
  path: '/ask',
  parameters: [
    {
      name: 'question',
      type: 'Body',
      description: 'Question to ask.',
      schema: z.object({ query: z.string() }),
    },
  ],
  response: AnswerSchema,
  alias: 'getAnswer',
  description: 'Ask a question and get an answer.',
  tag: 'support',
});

const getPossibleQuestions = makeEndpoint({
  method: 'get',
  path: '/questions',
  parameters: [],
  response: z.object({ questions: z.array(z.string()) }),
  alias: 'getPossibleQuestions',
  description: 'Get a list of possible questions.',
  tag: 'support',
});

const search = makeEndpoint({
  method: 'get',
  path: '/search',
  parameters: [
    { name: 'query', type: 'Query', description: 'Query to search for.', schema: z.string() },
  ],
  response: SearchResultsSchema,
  alias: 'search',
  description: 'Search for a question.',
  tag: 'support',
});

export const endpoints = makeApi([askQuestion, getPossibleQuestions, search]);
export const supportApi = prefixApi(prefix, endpoints);
