import styled from '@emotion/styled';
import { TZServiceAPICLient } from '@racemap/utilities/api-client';
import { shortIdBuilder } from '@racemap/utilities/functions/utils';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger';
import { TimeVariantInput, castDateTime } from '../../utils/timeUtils';
import { HStack } from '../MetaComponent';

type Props = {
  children: React.ReactElement | ((props: OverlayTriggerRenderProps) => React.ReactNode);
  datetime: TimeVariantInput;
  hint?: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  localizeTimeLocation?: [number, number] | null;
};

const apiClient = new TZServiceAPICLient();

export const TooltipWithUTCDatetime: FC<Props> = ({
  children,
  datetime,
  hint,
  localizeTimeLocation,
  tooltipPosition = 'top',
}) => {
  if (!datetime) return null;

  const id = shortIdBuilder();
  const [timezoneIdentifier, setTimezoneIdentifier] = useState<string | null>(null);
  const parsedDateTime = castDateTime(datetime);

  useEffect(() => {
    if (localizeTimeLocation != null) {
      async function fetch() {
        if (localizeTimeLocation == null) return;

        const timezone = await apiClient.getTimezoneForLocation(
          localizeTimeLocation[0],
          localizeTimeLocation[1],
        );

        setTimezoneIdentifier(timezone.name);
      }

      fetch();
    }
  }, [localizeTimeLocation]);

  const tooltip = (
    <Tooltip id={id}>
      <HStack>
        <table style={{ textAlign: 'right', lineHeight: '1.7' }}>
          <tbody>
            <tr>
              <td>
                <strong>UTC:</strong>
              </td>
              <td style={{ paddingLeft: '10px' }}>
                {parsedDateTime.toUTC().toLocaleString(DateTime.DATETIME_FULL)}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Your Time:</strong>
              </td>
              <td style={{ paddingLeft: '10px' }}>
                {parsedDateTime.toLocal().toLocaleString(DateTime.DATETIME_FULL)}
              </td>
            </tr>
            {hint ? (
              <tr>
                <td>
                  <strong>Note:</strong>
                </td>
                <td style={{ paddingLeft: '10px' }}>{hint}</td>
              </tr>
            ) : null}
            {timezoneIdentifier != null ? (
              <tr>
                <td>
                  <strong>Time at Event:</strong>
                </td>
                <td style={{ paddingLeft: '10px' }}>
                  {parsedDateTime
                    .setZone(timezoneIdentifier)
                    .toLocaleString(DateTime.DATETIME_FULL)}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </HStack>
    </Tooltip>
  );

  if (children == null) return <></>;

  return (
    <OverlayTrigger placement={tooltipPosition} overlay={tooltip} delay={500}>
      {children}
    </OverlayTrigger>
  );
};

export const HStackBaseline = styled(HStack)`
  align-items: baseline;
  max-width: 500px;
`;
