import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';

export const TrackerTagSchema = z.object({
  id: ObjectIdSchema,
  label: z.string().nullable(),
  trackerIds: z.array(ObjectIdSchema),
  color: z.string(),
  creatorId: ObjectIdSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
});

export type TrackerTag = z.infer<typeof TrackerTagSchema>;

export const TrackerTagPrototypeSchema = TrackerTagSchema.pick({
  label: true,
  trackerIds: true,
  color: true,
});

export type TrackerTagPrototype = z.infer<typeof TrackerTagPrototypeSchema>;
