import { TaxIdSchema } from '@racemap/sdk/schema/user';
import { Immutable } from 'immer';
import Stripe from 'stripe';
import z from 'zod';
import { EUCountries } from '../consts/countries';
import { isNotEmptyString } from './utils';

type TaxIds = Array<z.infer<typeof TaxIdSchema> | Stripe.TaxId>;

export function userNeedsVAT(
  address?: Immutable<Stripe.Address>,
  taxIds: Immutable<TaxIds> = [],
): boolean {
  // If the address is not set, we assume the user is a business and needs VAT
  if (address == null) return true;
  // If no country is set, we assume the user is a business and needs VAT
  if (address.country == null || address.country === '') return true;
  // If the user is from Germany, they need VAT
  if (address.country === 'DE') return true;

  const hasTaxId = taxIds.length > 0 && taxIds.every((tId) => isNotEmptyString(tId.value));
  if (EUCountries.includes(address.country) && !hasTaxId) return true;

  return false;
}
