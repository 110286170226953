import { openDB } from '../lib/indexed_DB/indexedDB';
import { useStore } from './reducers';

let dbInitialized = false;

export async function initializeDB() {
  if (dbInitialized) {
    console.log('Database has already been initialized.');
    return;
  }

  try {
    await openDB();
    dbInitialized = true;
    console.log('Database initialized successfully.');
    // Rehydrate the store with the data from the database
    useStore.persist.rehydrate();
  } catch (error) {
    console.error('Failed to initialize database', error);
  }
}
