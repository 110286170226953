import { makeApi, makeEndpoint, prefixApi } from '@zodios/core';
import { z } from 'zod';
import { ObjectIdHexStringSchema, ObjectIdSchema } from '../schema/base';
import { TrackerTagPrototypeSchema, TrackerTagSchema } from '../schema/trackerTags';

const prefix = '/api/v1/trackers/tags';

const listTrackerTags = makeEndpoint({
  method: 'get',
  path: '/',
  alias: 'listTrackerTags',
  description: 'List tracker tags',
  response: z.array(TrackerTagSchema),
});

const createTrackerTag = makeEndpoint({
  method: 'post',
  path: '/',
  alias: 'createTrackerTag',
  description: 'Create tracker tag',
  parameters: [
    {
      name: 'tag',
      type: 'Body',
      description: 'Tracker Tag Infos',
      schema: TrackerTagPrototypeSchema,
    },
  ],
  response: TrackerTagSchema,
});

const updateTrackerTag = makeEndpoint({
  method: 'patch',
  path: '/:tagId',
  alias: 'updateTrackerTag',
  description: 'Update tracker tag',
  parameters: [
    {
      name: 'tagId',
      type: 'Path',
      description: 'Tracker Tag ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'tagPatch',
      type: 'Body',
      description: 'Patch of the Tag Infos',
      schema: TrackerTagSchema.partial().omit({ id: true, createdAt: true, updatedAt: true }),
    },
  ],
  response: TrackerTagSchema,
});

const deleteTrackerTag = makeEndpoint({
  method: 'delete',
  path: '/:tagId',
  alias: 'deleteTrackerTag',
  description: 'Delete tracker tag',
  parameters: [
    {
      name: 'tagId',
      type: 'Path',
      description: 'Tracker Tag ID',
      schema: ObjectIdSchema,
    },
  ],
  response: z.object({
    success: z.boolean(),
  }),
});

const endpoints = makeApi([listTrackerTags, createTrackerTag, updateTrackerTag, deleteTrackerTag]);
export const trackerTagsApi = prefixApi(prefix, endpoints);
