import { ObjectId } from '@racemap/sdk/schema/base';
import { UserOverview, UserOverviewSchema } from '@racemap/sdk/schema/user';
import { DateFilter, RacemapAPIClient } from '@racemap/utilities/api-client';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { produce } from 'immer';
import { Immutable } from 'immer';
import moment from 'moment';
import { StoreApi } from 'zustand';
import { DraftState, State } from './reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();

export enum SearchByOptions {
  DATE = 'DATE',
  USER = 'USER',
}

export interface ManageEventsState {
  manageEvents: {
    events: Map<string, RacemapEvent>;
    users: Map<string, UserOverview>;
    searchBy: SearchByOptions;
    fromDate: Date;
    toDate: Date;
    dateFilter: Array<DateFilter>;
    userIdToSearchFor: ObjectId | null;

    actions: {
      loadEvents: () => Promise<void>;
      loadUsers: () => Promise<void>;
      loadData: () => Promise<void>;
      setRange: ({ from, to }: { from?: Date; to?: Date }) => Promise<void>;
      setDateFilter: (newFields: Array<DateFilter>) => Promise<void>;
      setSearchByOption: (searchByOption: SearchByOptions) => void;
      setUserToSearchFor: (userId: ObjectId | null) => void;
    };
  };
}

export const createManageEventsStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): Immutable<ManageEventsState> => ({
  manageEvents: {
    events: new Map(),
    users: new Map(),
    searchBy: SearchByOptions.DATE,
    dateFilter: [DateFilter.RUNNING_EVENT],
    userIdToSearchFor: null,
    fromDate: moment().startOf('month').toDate(),
    toDate: moment().endOf('day').toDate(),
    actions: {
      setRange: async ({ from, to }) => {
        set(
          produce((s: DraftState) => {
            if (from != null) {
              s.manageEvents.fromDate = moment(from).startOf('day').toDate();
            }
            if (to != null) {
              s.manageEvents.toDate = moment(to).endOf('day').toDate();
            }
          }),
        );
        await get().manageEvents.actions.loadEvents();
      },
      setDateFilter: async (newFilter) => {
        set(
          produce((s: DraftState) => {
            s.manageEvents.dateFilter = newFilter;
          }),
        );
        await get().manageEvents.actions.loadEvents();
      },
      setSearchByOption: async (searchByOption: SearchByOptions) => {
        set(
          produce((s: DraftState) => {
            s.manageEvents.searchBy = searchByOption;
          }),
        );

        await get().manageEvents.actions.loadEvents();
      },
      setUserToSearchFor: async (userId) => {
        set(
          produce((s: DraftState) => {
            s.manageEvents.userIdToSearchFor = userId;
          }),
        );
        await get().manageEvents.actions.loadEvents();
      },
      loadEvents: async () => {
        const state = get();
        let events: Array<RacemapEvent>;

        if (state.manageEvents.searchBy === SearchByOptions.DATE) {
          if (state.manageEvents.dateFilter.length === 0) {
            set(
              produce((s: DraftState) => {
                s.manageEvents.events = new Map();
              }),
            );
            return;
          }

          events = await apiClient.getEvents({
            dateFilter: [...state.manageEvents.dateFilter],
            toDate: state.manageEvents.toDate,
            fromDate: state.manageEvents.fromDate,
            show: ['hidden'],
            filter: 'can-edit',
            attachRenewedAt: 'true',
          });
        } else {
          if (state.manageEvents.userIdToSearchFor == null) {
            set(
              produce((s: DraftState) => {
                s.manageEvents.events = new Map();
              }),
            );
            return;
          }

          const [eventsCreator, eventsEditor] = await Promise.all([
            apiClient.getEvents({
              show: ['hidden'],
              attachRenewedAt: 'true',
              findByCreator: state.manageEvents.userIdToSearchFor.toHexString(),
            }),
            apiClient.getEvents({
              show: ['hidden'],
              attachRenewedAt: 'true',
              findByEditor: state.manageEvents.userIdToSearchFor.toHexString(),
            }),
          ]);

          events = [...eventsCreator, ...eventsEditor];
        }

        set(
          produce((s: DraftState) => {
            s.manageEvents.events = new Map(events.map((e) => [e.id, e]));
          }),
        );
      },
      loadUsers: async () => {
        const { users } = await apiClient.getUsers({ withEventStats: true });

        set(
          produce((s: DraftState) => {
            s.manageEvents.users = new Map(users.map((u) => [u.id, UserOverviewSchema.parse(u)]));
          }),
        );
      },
      loadData: async () => {
        const state = get().manageEvents;
        await Promise.all([state.actions.loadEvents(), state.actions.loadUsers()]);
      },
    },
  },
});
