import { isSimpleObject } from '@racemap/utilities/functions/utils';
import { DeepPartial } from '@racemap/utilities/types/utils';
import cloneDeep from 'lodash.clonedeep';

/**
 * Deeply updates an object with the properties from another object.
 *
 * @template T - The type of the object being updated.
 * @param obj1 - The original object to be updated.
 * @param obj2 - The object containing the properties to update.
 * @returns The updated object.
 */
export function deepUpdateRecord<T extends Record<string, unknown>>(
  obj1: T,
  obj2: DeepPartial<T>,
): T {
  const output: T = cloneDeep(obj1);
  const keys = new Set([...Object.keys(output), ...Object.keys(obj2)]);

  for (const key of Array.from(keys) as Array<keyof T>) {
    const field1 = output[key];
    const field2 = obj2[key];

    if (field2 === undefined) continue;

    if (isSimpleObject(field1) && isSimpleObject(field2)) {
      output[key] = deepUpdateRecord(field1, field2 as DeepPartial<typeof field1>);
    } else if (field1 === undefined || typeof field2 === typeof field1 || field2 === null) {
      output[key] = field2 as typeof field1;
    }
  }

  return output;
}
