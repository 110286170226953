import styled from '@emotion/styled';
import AnnounceKit from 'announcekit-react';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

export const UPDATES_WIDGET_URL = 'https://updates.racemap.com/widgets/v2/BwlKU';

export const UpdateStream: FC<PropsWithChildren> = ({ children }) => {
  const [unread, setUnread] = useState<number>();
  const ref = React.useRef<{ unread: () => Promise<number> }>();

  useEffect(() => {
    if (ref.current == null) return;

    ref.current.unread().then((unread) => {
      setUnread(unread);
    });
  }, []);

  return (
    <Container unread={!!unread}>
      <AnnounceKit
        ref={ref}
        widget={UPDATES_WIDGET_URL}
        className="announce-kit-widget-button"
        onWidgetUnread={({ unread }: { unread: number }) => setUnread(unread)}
      >
        {children}
      </AnnounceKit>
    </Container>
  );
};

const Container = styled.div<{ unread?: boolean }>`
  .announce-kit-widget-button {
    display: flex !important;
    align-items: center;
    gap: 5px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-weight: ${({ unread }) => (unread ? 'bold' : 'normal')};
  
    .announcekit-widget-badge {
      ${({ unread }) => !unread && 'display: none !important;'}
    }
  }
`;
